import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

export default function SummaryInfoItem({ Icon, text }) {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" gap={theme.spacing(3)}>
      <Icon
        size={20}
        weight="duotone"
        style={{ flexShrink: 0 }}
        color={theme.palette.action.active}
      />
      <Typography
        color="text.secondary"
        sx={{
          fontSize: "14px",
          lineHeight: "22px",
          letterSpacing: "0.28px",
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
}
