import React from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { ArrowArcRight } from "@phosphor-icons/react";

import ANGooglePlacesInput from "elements/ANGooglePlacesInput";
import PhysicianHeader from "./PhysicianHeader";
import { useIntake } from "components/SelfService/IntakeProvider";

const validationSchema = yup.object().shape({
  clinicName: yup.string().required("Name of Doctor’s Clinic is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  address: yup.object().nullable(),
});

/**
 * The PhysicianForm component is a form that manually collects information about a physician's clinic, first
 * name, last name, and optional address.
 * @category Component
 * @param {Object} props The props for this component.
 * @param {string} props.clientName The name of the client.
 * @param {boolean} props.isLoading Whether the form is in a loading state.
 * @param {string} props.label The label for the form.
 * @param {Function} props.onContinueClick The function to call when the continue button is clicked.
 * @param {Function} props.onSkip The function to call when the skip button is clicked.
 * @returns {React.Component} The `<PhysicianForm ... />` component is being returned.
 */
export default function PhysicianForm({
  clientName,
  data,
  label,
  isLoading,
  onContinueClick,
  onSkip,
}) {
  const { goBack } = useIntake();
  // Formik Configuration
  const { errors, handleBlur, handleChange, handleSubmit, touched, values, isValid } = useFormik({
    enableReinitialize: true,
    initialValues: {
      clinicName: data?.clinicName || "",
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      address: data?.address || null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => onContinueClick(values),
  });

  return (
    <form onSubmit={handleSubmit}>
      <Stack marginBottom={7}>
        <PhysicianHeader clientName={clientName} physicianType={label} showDescription={false} />
      </Stack>

      <Stack marginBottom={7}>
        <TextField
          fullWidth
          label="Name of Doctor’s Clinic"
          name="clinicName"
          value={values.clinicName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.clinicName && Boolean(errors.clinicName)}
          helperText={touched.clinicName && errors.clinicName}
        />
      </Stack>

      <Stack marginBottom={7}>
        <TextField
          fullWidth
          label="First Name"
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={touched.firstName && errors.firstName}
        />
      </Stack>

      <Stack marginBottom={7}>
        <TextField
          fullWidth
          label="Last Name"
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.lastName && Boolean(errors.lastName)}
          helperText={touched.lastName && errors.lastName}
        />
      </Stack>

      <Stack marginBottom={7}>
        <ANGooglePlacesInput
          name="address"
          value={values.address}
          onChange={handleChange}
          error={touched.address && Boolean(errors.address)}
          helperText={touched.address && errors.address}
          onBlur={handleBlur}
        />
      </Stack>

      <Stack spacing={3}>
        <LoadingButton size="large" type="submit" disabled={!isValid} loading={isLoading}>
          Continue
        </LoadingButton>

        {onSkip && (
          <Button size="large" color="secondary" endIcon={<ArrowArcRight />} onClick={onSkip}>
            Skip
          </Button>
        )}

        <Button variant="text" onClick={goBack} size="large">
          Back
        </Button>
      </Stack>
    </form>
  );
}
