import React from "react";
import ItemControlPanel from "../../elements/AnItemControlPanel";
import { Buildings } from "@phosphor-icons/react";
import AnItemLinked from "../../elements/AnItemLinked";

export const ProviderForm = ({
  classes,
  data,
  onDeleteItem,
  theme,
  isDeletingItem,
  clinics,
  onSearch,
  onClinicSelected,
  onSubmitClinics,
  isCreatingItem,
}) => {
  const mapItems = data?.physician_clinic?.map((obj) => ({
    id: obj.id,
    clinic_id: obj.clinic_id,
    text: obj.clinic.clinic_name,
  }));
  const mapClinics = clinics
    ?.filter((clinic) => !mapItems?.some((item) => item.clinic_id === clinic.id))
    .map((clinic) => ({
      id: clinic.id,
      name: clinic.clinic_name,
    }));
  return [
    {
      id: "clinics",
      label: "Clinics",
      input: (
        <ItemControlPanel
          classes={classes}
          items={mapItems}
          renderItem={(item, isOnlyItem) => (
            <AnItemLinked
              text={item?.text || ""}
              buttonIcon={<Buildings fontSize={"20px"} weight="duotone" />}
              editMode
              onDeleteItem={isOnlyItem ? null : () => onDeleteItem(item.id)}
              isDeletingItem={isDeletingItem}
            />
          )}
          buttonIcon={
            <Buildings fontSize={"20px"} weight="duotone" color={theme.palette.primary.main} />
          }
          listItemIcon={<Buildings fontSize={"20px"} weight="duotone" />}
          buttonText="Connect Clinics"
          stepsDialog={[<></>]}
          defaultValuesDialog={" Connect Clinic"}
          submitLabelDialog={" Connect Clinic"}
          titleDialog={"Connect Clinic"}
          titleSubmitDialog={"Connect Clinic"}
          descriptionDialog={"Choose a clinic below to connect with the Provider's profile."}
          onSubmitDialog={onSubmitClinics}
          onClickDialog={() => {}}
          onCancelDialog={() => {}}
          onCloseAction={() => {}}
          onCheckboxSelected={onClinicSelected}
          filteredItems={mapClinics}
          onSearch={onSearch}
          isSubmittingDialog={isCreatingItem}
          searchInputPlaceHolder={"Search Clinics"}
        />
      ),
      paddingTop: 0,
      cols: 12,
      divider: true,
    },
  ];
};
