import { searchProviders } from "api/provider";
import { SEARCH_PROVIDERS } from "constants/reactQueryKeys";
import { useQuery } from "react-query";
import { queryParamsObjIsValid } from "utils/queryParamsObjIsValid";

export const useSearchProviders = (query) => {
  const { data, isLoading, ...rest } = useQuery(
    [SEARCH_PROVIDERS, { query }],
    () => searchProviders(query),
    { enabled: query?.inputValue?.length >= 2 && queryParamsObjIsValid(query) }
  );
  return {
    providers: data?.data || null,
    providersLoading: isLoading,
    ...rest,
  };
};
