/**
 * v3/index.js
 * Autogenerated via generateMockRouteIndexes.js
 * DO NOT MODIFY THIS FILE DIRECTLY.
 */



/** @type {import('../../mockRequestHandler').EndpointMockConfig} */
export default {

};
