import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FormControl, FormControlLabel, MenuItem } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import * as yup from "yup";

import ANDatePicker from "elements/ANDatePicker";
import { COUNTRIES, STATES, RELATIONSHIPS } from "constants";
import { useGetUserById } from "hooks/api/user/useGetUserById";
import { formatToPhoneNumber } from "utils/formatFormFields";
/**  @typedef {import("api/typesDef").Member} Member */

const validationSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  dateOfBirth: yup.date().nullable(),
  gender: yup.string(),
  relationshipToClient: yup.string(),
  phone: yup.string(),
  email: yup.string().email("Invalid email"),
  address: yup.string(),
  address2: yup.string(),
  country: yup.string(),
  state: yup.string(),
  city: yup.string(),
  zipCode: yup.number().typeError("Must be a number"),
});

function initialValues(guarantor) {
  const relationshipToClient = guarantor?.relationshipToClient ?? guarantor?.relationship;
  const phone = guarantor?.phone ?? guarantor?.phoneNumber;

  return {
    firstName: guarantor?.firstName ?? "",
    lastName: guarantor?.lastName ?? "",
    dateOfBirth: guarantor?.dateOfBirth ? new Date(guarantor.dateOfBirth) : null,
    gender: guarantor?.gender ?? "",
    relationshipToClient: relationshipToClient ?? "",
    phone: phone ?? "",
    email: guarantor?.email ?? "",
    address1: guarantor?.address1 ?? "",
    address2: guarantor?.address2 ?? "",
    country: guarantor?.country ?? "US",
    state: guarantor?.state ?? "",
    city: guarantor?.city ?? "",
    zipCode: guarantor?.zipCode ?? "",
    // State for the Financial Guarantor data is the same as the Insurance Subscriber
    isPrimarySubscriber: guarantor?.isPrimarySubscriber ?? false,
    // Determines whether a guarantor must be created
    isCreating: !guarantor?.id,
  };
}

/**
 * The FinancialGuarantorForm component is a form that captures information about a Financial Guarantor and
 * allows forwading the data to the parent component.
 * @param {Object} props - The component props.
 * @param {string} props.id - The form Id to be used as a reference in the parent component.
 * @param {string} props.clientId - The client ID.
 * @param {Member} props.guarantor - The Financial Guarantor data.
 * @param {Function} props.onSubmit - The function to call when the form is submitted.
 * @param {Array<{value: string, label: string}>} props.teamMembersList - The list of team members to be used as guarantors.
 * @returns {JSX.Element} The `FinancialGuarantorForm` component is being returned. It is a form component that
 * captures information about a financial guarantor. The form  also includes validation for the input fields.
 */
const FinancialGuarantorForm = React.forwardRef(
  ({ id, clientId, guarantor, onSubmit, teamMembersList }, ref) => {
    const [teamMemberAsGuarantor, setTeamMemberAsGuarantor] = useState("");

    // Fetch the user details for the team member selected as the guarantor
    const { user, isError } = useGetUserById(teamMemberAsGuarantor);

    return (
      // Formik configuration
      <Formik
        id={id}
        innerRef={ref}
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={initialValues(guarantor)}
        onSubmit={(values) => {
          onSubmit({
            ...values,
            clientId,
          });
        }}
      >
        {({ touched, errors, handleBlur, handleChange, values, setFieldValue, setValues }) => {
          useEffect(() => {
            if (!isError && teamMemberAsGuarantor) {
              setValues(initialValues(user));
            }
          }, [user, teamMemberAsGuarantor]);

          const handleTeamMembersChange = async (event) => {
            const teamMemberValue = event.target.value;
            setTeamMemberAsGuarantor(teamMemberValue);
          };

          const handlePhoneNumberChange = (e) => {
            const phone = e.target.value.replace(/[^\d]/g, "");
            handleChange({ target: { name: "phone", value: phone } });
          };

          return (
            <Grid container columnSpacing={5} rowSpacing={7} width="100%">
              <Grid xs={12}>
                <FormControl>
                  <FormControlLabel
                    sx={{ alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        name="isPrimarySubscriber"
                        checked={values.isPrimarySubscriber}
                        onChange={handleChange}
                      />
                    }
                    label="Same as Primary Insurance Subscriber"
                  />
                </FormControl>
              </Grid>

              {!values.isPrimarySubscriber && (
                <>
                  <Grid xs={12}>
                    <TextField
                      name="teamMemberAsGuarantor"
                      label="Use Team Member as Financial Guarantor"
                      select
                      onChange={handleTeamMembersChange}
                      value={teamMemberAsGuarantor}
                      fullWidth
                    >
                      <MenuItem disabled value={""} />
                      {teamMembersList?.map((member) => (
                        <MenuItem key={member.value} value={member.value}>
                          {member.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Guarantor’s First Name"
                      name="firstName"
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                    />
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Guarantor’s Last Name"
                      name="lastName"
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                    />
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <ANDatePicker
                      name="dateOfBirth"
                      label="Guarantor's Date of Birth"
                      value={values.dateOfBirth}
                      onChange={(value) => setFieldValue("dateOfBirth", value, true)}
                      format="PP" //"MMM d, yyyy"
                      sx={{ width: "100%" }}
                      disableFuture
                    />
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <TextField
                      name="gender"
                      label="Guarantor’s Gender"
                      select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.gender}
                      fullWidth
                    >
                      <MenuItem disabled value={""} />
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="non_binary">Non-binary</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Guarantor’s Email Address"
                      name="email"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                    />
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Guarantor’s Phone Number"
                      name="phone"
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      onBlur={handleBlur}
                      onChange={handlePhoneNumberChange}
                      value={formatToPhoneNumber(values.phone)}
                    />
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <TextField
                      select
                      fullWidth
                      label="Relationship to Client"
                      name="relationshipToClient"
                      error={touched.relationshipToClient && !!errors.relationshipToClient}
                      helperText={touched.relationshipToClient && errors.relationshipToClient}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.relationshipToClient}
                    >
                      <MenuItem disabled value="">
                        relationship
                      </MenuItem>
                      {Object.entries(RELATIONSHIPS).map(([key, value]) => {
                        return (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>

                  <Grid xs={12} sm={6}>
                    <TextField
                      select
                      fullWidth
                      label="Country"
                      name="country"
                      error={touched.country && !!errors.country}
                      helperText={touched.country && errors.country}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country}
                    >
                      <MenuItem disabled value={""}>
                        Select
                      </MenuItem>
                      {Object.entries(COUNTRIES).map(([key, value]) => {
                        return (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>

                  <Grid xs={12} sm={4}>
                    <TextField
                      select
                      fullWidth
                      label="State"
                      name="state"
                      error={touched.state && !!errors.state}
                      helperText={touched.state && errors.state}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.state}
                      disabled={values.country !== "US"}
                    >
                      <MenuItem disabled value={""}>
                        Select
                      </MenuItem>
                      {Object.entries(STATES).map(([key, value]) => {
                        return (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>

                  <Grid xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="City"
                      name="city"
                      size="small"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      error={touched.city && !!errors.city}
                      helperText={touched.city && errors.city}
                    />
                  </Grid>

                  <Grid xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="ZIP Code"
                      name="zipCode"
                      size="small"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.zipCode}
                      error={touched.zipCode && !!errors.zipCode}
                      helperText={touched.zipCode && errors.zipCode}
                    />
                  </Grid>

                  <Grid xs={12}>
                    <TextField
                      fullWidth
                      label="Guarantor’s Address"
                      name="address1"
                      size="small"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address1}
                      error={touched.address1 && !!errors.address1}
                      helperText={touched.address1 && errors.address1}
                    />
                  </Grid>

                  <Grid xs={12}>
                    <TextField
                      fullWidth
                      label="Guarantor’s Address 2"
                      name="address2"
                      size="small"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address2}
                      error={touched.address2 && !!errors.address2}
                      helperText={touched.address2 && errors.address2}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          );
        }}
      </Formik>
    );
  }
);

FinancialGuarantorForm.displayName = "FinancialGuarantorForm";

export default FinancialGuarantorForm;
