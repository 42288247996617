import axios from "axios";
import cookie from "react-cookies";
import { detect } from "detect-browser";
import { Auth } from "@aws-amplify/auth";
import { POSTLogger } from "amazon-chime-sdk-js";
import { mockRequestHandler } from "../../__mocks__/mockRequestHandler"; // Import the mock request handler
const useMockedRoutes = process.env.USE_MOCKED_ROUTES === "true";
console.log({ useMockedRoutes, process: process.env.USE_MOCKED_ROUTES });
class SecureRequest {
  constructor() {
    const browser = detect();
    const user_id = cookie.load("userId");
    const client_id = cookie.load(`${user_id}-clientId`);
    let system_version = null;
    let device_type = null;
    let browser_type = null;
    if (browser) {
      system_version = browser.version;
      browser_type = browser.name;
      device_type = browser.os;
    }
    this.request = axios.create({
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        user_id,
        client_id,
        system_version,
        browser_type,
        device_type,
      },
      baseURL: process.env.BASE_URL,
    });
    this.request.interceptors.request.use((config) => {
      return Auth.currentSession()
        .then((data) => {
          config.headers.accessToken = data.accessToken.jwtToken;
          return Promise.resolve(config);
        })
        .catch(() => {
          return Promise.resolve(config);
        });
    });
  }

  /**
   * Returns the appropriate request handler based on the environment.
   * @returns {object} - Mock request handler with fallback or Axios instance.
   */
  getRequest() {
    if (useMockedRoutes) {
      return {
        get: (url, config) => this.handleRequestWithFallback(url, "GET", config),
        post: (url, data) => this.handleRequestWithFallback(url, "POST", { data }),
        put: (url, data) => this.handleRequestWithFallback(url, "PUT", { data }),
        delete: (url, config) => this.handleRequestWithFallback(url, "DELETE", config),
        patch: (url, data) => this.handleRequestWithFallback(url, "PATCH", { data }),
      };
    }
    return this.request; // Use Axios instance for live API requests
  }

  /**
   * Handles requests in debug mode with fallback to live API calls.
   * @param {string} url - The API endpoint.
   * @param {string} method - The HTTP method (GET, POST, PUT, DELETE, PATCH).
   * @param {object} options - Request options (e.g., params, data, headers).
   * @returns {Promise<{status: number, data: object}>} - The resolved response (mock or live API).
   */
  async handleRequestWithFallback(url, method, options = {}) {
    try {
      const requestData = {
        query: options?.params,
        body: options?.data,
      };
      const { status, response } = mockRequestHandler.resolve(url, method, requestData);
      return Promise.resolve({ status, data: response });
    } catch (error) {
      console.warn(`Mock not found or inactive for ${method} ${url}. Falling back to live API.`);
      // Fallback to the actual Axios instance for live API call
      return this.request[method.toLowerCase()](url, options);
    }
  }
}

export default SecureRequest;

// helpers

/** @type {(url: string, config?: import('axios').AxiosRequestConfig) => import('axios').AxiosResponse} */
export function apiGet(...args) {
  return new SecureRequest().getRequest().get(...args);
}

/** @type {(url: string, config?: import('axios').AxiosRequestConfig) => import('axios').AxiosResponse} */
export function apiDelete(...args) {
  return new SecureRequest().getRequest().delete(...args);
}

/** @type {(url: string, data?: any, config?: import('axios').AxiosRequestConfig) => import('axios').AxiosResponse} */
export function apiPut(...args) {
  return new SecureRequest().getRequest().put(...args);
}

/** @type {(url: string, data?: any, config?: import('axios').AxiosRequestConfig) => import('axios').AxiosResponse} */
export function apiPost(...args) {
  return new SecureRequest().getRequest().post(...args);
}

/** @type {(url: string, data?: any, config?: import('axios').AxiosRequestConfig) => import('axios').AxiosResponse} */
export function apiPatch(...args) {
  return new SecureRequest().getRequest().patch(...args);
}

/**
 * TODO how to handle this if token is refreshed?
 * @param {number} videoCallId
 * @param {import('amazon-chime-sdk-js').POSTLoggerOptions} loggerOptions
 */
export async function createVideoCallPostLogger(videoCallId, loggerOptions = {}) {
  if (!videoCallId) throw new Error("videoCallId must be valid");
  const secureRequest = new SecureRequest();
  const headers = secureRequest.request.defaults.headers;
  const data = await Auth.currentSession();
  const accessToken = data.getAccessToken();
  headers.accessToken = accessToken.getJwtToken();

  return new POSTLogger({
    url: `${process.env.BASE_URL}/v2/video/${videoCallId}/log`,
    intervalMs: 20000,
    ...loggerOptions,
    headers: {
      ...headers,
      ...(loggerOptions.headers || {}),
    },
  });
}
