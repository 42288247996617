/**
 * Combines all route mock data into a single object for use in the mock request handler.
 */

// Import all routes from each version folder
import v1Routes from "./v1";
import v2Routes from "./v2";
import v3Routes from "./v3";

// Initialize the mock data object
const mockData = {
  ...v1Routes, // Combine all v1 routes
  ...v2Routes, // Combine all v2 routes
  ...v3Routes, // Combine all v3 routes
};

// Log warnings for empty folders (optional)
if (Object.keys(v1Routes).length === 0) {
  console.warn("No routes found in v1.");
}
if (Object.keys(v2Routes).length === 0) {
  console.warn("No routes found in v2.");
}
if (Object.keys(v3Routes).length === 0) {
  console.warn("No routes found in v3.");
}
console.log({ mockData, v1Routes, v2Routes, v3Routes });

export default mockData;
