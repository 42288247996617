import { TextField } from "@mui/material";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { STATES } from "constants";

export const ProviderFormPrivatePractice = ({ data, errors, onChange }) => {
  return [
    {
      id: "physical_address",
      tab: 0,
      input: (
        <TextField
          id="address"
          name="physical_address"
          autoComplete="off"
          value={data.physical_address ?? ""}
          onChange={onChange("physical_address", null, data)}
          variant="outlined"
          label="Address"
          fullWidth
          inputProps={{ maxLength: 1000 }}
        />
      ),
    },
    {
      id: "physical_address_2",
      tab: 0,
      input: (
        <TextField
          id="physical_address_2"
          name="physical_address_2"
          autoComplete="off"
          value={data.physical_address_2 ?? ""}
          onChange={onChange("physical_address_2", null, data)}
          variant="outlined"
          label="Address 2 (optional)"
          fullWidth
          inputProps={{ maxLength: 1000 }}
        />
      ),
    },
    {
      id: "city",
      tab: 0,
      input: (
        <TextField
          id="city"
          label="City"
          onChange={onChange("city", null, data)}
          value={data.city ?? ""}
          fullWidth
          inputProps={{ maxLength: 255 }}
        />
      ),
      cols: 12,
    },
    {
      id: "state",
      tab: 0,
      input: (
        <TextField
          id="state"
          select
          label="State"
          onChange={onChange("state", null, data)}
          value={data.state ?? ""}
          fullWidth
          autoComplete="off"
        >
          <MenuItem disabled value={""}>
            Select
          </MenuItem>
          {Object.keys(STATES).map((key) => {
            return (
              <MenuItem key={key} value={key}>
                {STATES[key]}
              </MenuItem>
            );
          })}
        </TextField>
      ),
    },
    {
      id: "zip_code",
      tab: 0,
      input: (
        <TextField
          id="zip_code"
          label="ZIP Code"
          placeholder="23226"
          onChange={onChange("zip_code", "zipCode", data)}
          value={data.zip_code ?? ""}
          fullWidth
          inputProps={{ maxLength: 5 }}
          helperText={
            errors["zip_code"]
              ? "Please enter ZIP Code"
              : errors["zip_format_error"]
                ? "Invalid ZIP Code"
                : ""
          }
        />
      ),
      cols: 12,
    },
    {
      id: "website",
      input: (
        <TextField
          id="website"
          placeholder="health.com"
          label="Website (optional)"
          onChange={onChange("website", null, data)}
          value={data.website ?? ""}
          fullWidth
        />
      ),
      cols: 12,
      divider: true,
    },
  ];
};
