import { Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useIntake } from "components/SelfService/IntakeProvider";
import { STAGES } from "components/SelfService/utils";
import React from "react";
import ManualInsurance from "./ManualInsurance";
import AddSecondaryInsurance from "./AddSecondaryInsurance";
import InsuranceCardUpload from "./InsuranceCardUpload";

export default function InsuranceCard(props) {
  const theme = useTheme();
  const { currentSubStage, setCurrentSubStage, setCurrentStage, saveProgress } = useIntake();
  const subStages = STAGES.insuranceCard.subStages;

  const goToBackCard1 = () => setCurrentSubStage(subStages.backCard1.name);

  const subStageMap = {
    [subStages.frontCard1.name]: (
      <InsuranceCardUpload
        side="front"
        onSkip={goToBackCard1}
        onAddManualInsurance={() => setCurrentSubStage(subStages.manualInsurance1.name)}
        onContinue={(data) => saveProgress({ subStageData: data, onSuccess: goToBackCard1 })}
      />
    ),
    [subStages.backCard1.name]: (
      <InsuranceCardUpload
        side="back"
        onSkip={() => setCurrentStage(STAGES.pediatrician.name)}
        onContinue={(data) =>
          saveProgress({
            subStageData: data,
            isStageCompleted: true,
            onSuccess: () => setCurrentSubStage(subStages.haveSecondaryInsurance.name),
          })
        }
        onAddManualInsurance={() => setCurrentSubStage(subStages.manualInsurance1.name)}
      />
    ),
    [subStages.frontCard2.name]: (
      <InsuranceCardUpload
        side="front"
        isSecondary
        onSkip={() => setCurrentSubStage(subStages.backCard2.name)}
        onAddManualInsurance={() => setCurrentSubStage(subStages.manualInsurance2.name)}
        onContinue={(data) =>
          saveProgress({
            subStageData: data,
            onSuccess: () => setCurrentSubStage(subStages.backCard2.name),
          })
        }
      />
    ),
    [subStages.backCard2.name]: (
      <InsuranceCardUpload
        side="back"
        isSecondary
        onSkip={() => setCurrentSubStage(subStages.manualInsurance2.name)}
        onAddManualInsurance={() => setCurrentSubStage(subStages.manualInsurance2.name)}
        onContinue={(data) =>
          saveProgress({
            subStageData: data,
            onSuccess: () => setCurrentStage(STAGES.pediatrician.name),
          })
        }
      />
    ),
    [subStages.manualInsurance1.name]: (
      <ManualInsurance
        onSkip={() => setCurrentSubStage(subStages.haveSecondaryInsurance.name)}
        onContinue={(data) =>
          saveProgress({
            subStageData: { ...data, isPrimary: true },
            isStageCompleted: true,
            onSuccess: () => setCurrentSubStage(subStages.haveSecondaryInsurance.name),
          })
        }
      />
    ),
    [subStages.manualInsurance2.name]: (
      <ManualInsurance
        isSecondary
        onSkip={() => setCurrentStage(STAGES.pediatrician.name)}
        onContinue={(data) =>
          saveProgress({
            subStageData: data,
            onSuccess: () => setCurrentStage(STAGES.pediatrician.name),
          })
        }
      />
    ),
    [subStages.haveSecondaryInsurance.name]: <AddSecondaryInsurance />,
  };

  return (
    <Stack gap={theme.spacing(7)} {...props}>
      {subStageMap[currentSubStage] || subStageMap[subStages.frontCard1.name]}
    </Stack>
  );
}
