export default (theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    padding: "30px",
    width: "100%",
  },
  headerButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headerButtonText: {
    fontSize: 14,
    fontWeight: 400,
  },
  goBackButton: {
    width: 210,
    "& span": {
      textTransform: "none",
      fontWeight: 500,
    },
  },
  goBackIconContainer: {
    height: 18,
    width: 18,
    borderRadius: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconButton: {
    backgroundColor: "#885FD5",
    padding: 6,
    "&:hover, &.Mui-focusVisible": { backgroundColor: "rgb(104, 81, 143)" },
  },
  iconButtonEnabled: {
    backgroundColor: "#10B981",
    padding: 6,
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#10B981" },
  },
  editIcon: {
    fill: "#fff",
    fontSize: 17,
  },
  planDetailsContainer: {
    maxWidth: 800,
    margin: "auto",
    marginBottom: 30,
  },
  providerDetailsContainer: {
    maxWidth: 320,
    margin: "auto",
    marginBottom: 30,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 15,
    fontWeight: "500",
    color: "#69718F",
  },
  saveButtonContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  helperText: {
    margin: "0 5px",
    fontSize: 14,
    fontWeight: 500,
    color: "#B91C1C",
  },
  dialogButtonContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  dialogTitleContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
    paddingBottom: 10,
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 500,
  },
  snackbarContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(122, 125, 135, 0.6)",
    borderRadius: 4,
    zIndex: 3000,
    "& div": {
      border: "none",
      borderRadius: 4,
    },
  },
  snackbar: {
    top: "50%",
    backgroundColor: "#fff",
  },
  errorMessageContianer: {
    width: "100%",
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
    "& p": {
      fontSize: 15,
      fontWeight: 500,
      color: "#B91C1C",
    },
  },
  label: {
    fontSize: 14,
    fontWeight: "500",
    color: "#69718F",
    width: "100%",
  },
  info: {
    marginBottom: 5,
    fontSize: 17,
    fontWeight: "400",
    color: "#000",
    width: "100%",
  },

  searchBar: {
    "& .MuiOutlinedInput-root": {
      color: "#545a72",
      fontWeight: 500,
      "& .MuiInputAdornment-root": {
        color: "#878da5",
      },
      "& input": {
        paddingLeft: "8px",
      },
    },
  },
});
