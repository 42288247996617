import { Button, Stack, Typography } from "@mui/material";
import { useIntake } from "components/SelfService/IntakeProvider";
import { STAGES } from "components/SelfService/utils";
import React from "react";
import Image from "../../../../assets/intake_diagnosis_report.png";

export default function InitialPage(props) {
  const { setCurrentSubStage, setCurrentStage, goBack, saveProgress } = useIntake();
  return (
    <Stack gap={7} {...props}>
      <Stack gap={3}>
        <Typography variant="h2">Do you have a copy of your child’s Autism diagnosis?</Typography>
        <Typography>
          Providing a copy of the diagnosis report will help us verify your child’s insurance
          coverage for therapy services.
        </Typography>
      </Stack>

      <Stack alignItems="center">
        <img src={Image} style={{ maxWidth: "320px", width: "100%" }} />
      </Stack>
      <Stack gap={5}>
        <Button
          color="secondary"
          size="large"
          onClick={() =>
            setCurrentSubStage(STAGES.diagnosisReport.subStages.diagnosisReportUpload.name)
          }
        >
          Yes, I have a copy to upload
        </Button>
        <Button
          color="secondary"
          size="large"
          onClick={() => {
            saveProgress({
              onSuccess: () => {
                setCurrentStage(STAGES.diagnosingDoctor.name);
                setCurrentSubStage(STAGES.diagnosingDoctor.subStages.search.name);
              },
              subStage: STAGES.diagnosingDoctor.subStages.manual.name,
              subStageData: { skipped: true },
              isStageCompleted: true,
            });
          }}
        >
          No, not at this time
        </Button>
      </Stack>
      <Button variant="text" size="large" onClick={goBack}>
        Back
      </Button>
    </Stack>
  );
}
