import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";

/**
 * The `ViewRowCell` function renders a `Grid` cell component with a title and value.
 * @component
 * @param {Object} props The props for the component.
 * @param {string} props.title The title to display in the top of the cell.
 * @param {string} props.value The value to display.
 * @returns {JSX.Element} The `ViewRowCell` component is being returned. If `value` is falsy, it will display a dash
 * "-" instead.
 */
const ViewRowCell = ({ title, value, ...rest }) => {
  return (
    <Grid xs={12} {...rest}>
      <Typography component="h3" variant="caption" fontWeight={500} color="text.secondary">
        {title}
      </Typography>
      <Typography noWrap component="h4" variant="body2" paddingTop={2}>
        {value || "-"}
      </Typography>
    </Grid>
  );
};

export default ViewRowCell;
