import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ArrowArcRight } from "@phosphor-icons/react";
import { useIntake } from "components/SelfService/IntakeProvider";
import { STAGES } from "components/SelfService/utils";
import ANDatePicker from "elements/ANDatePicker";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as selectors from "selectors";
import { useDispatch } from "react-redux";
import actions from "actions";
import useGetInsurancePlans from "components/ClientDetails/Widgets/Insurance/InsuranceDetailsEdit/useGetInsurancePlans";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import ANLoadingScreen from "elements/ANLoadingScreen";

export default function ManualInsurance({ onSkip, isSecondary = false, onContinue, ...rest }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const insuranceProvidersList = useSelector(selectors.getInsuranceProvidersList);
  const providersListLoading = useSelector(selectors.getInsuranceProvidersListLoading);
  const { currentSubStage, currentStage, intakeFlowJson, isSavingLoading, goBack } = useIntake();
  const subStage = currentSubStage || Object.keys(STAGES[currentStage].subStages)[0];
  const subStageData = intakeFlowJson?.insuranceCard?.[subStage]?.data;

  const formik = useFormik({
    initialValues: {
      insuranceid: subStageData?.insuranceid || "",
      insurancePlanId: subStageData?.insurancePlanId || "",
      insuranceHolderName: subStageData?.insuranceHolderName || "",
      insuranceHolderDOB: subStageData?.insuranceHolderDOB
        ? new Date(subStageData.insuranceHolderDOB)
        : null,
      memberId: subStageData?.memberId || "",
      groupNumber: subStageData?.groupNumber || "",
      effectiveDate: subStageData?.effectiveDate ? new Date(subStageData.effectiveDate) : null,
      expirationDate: subStageData?.expirationDate ? new Date(subStageData.expirationDate) : null,
    },
    validationSchema: yup.object({
      insuranceid: yup.string().required("Required"),
      insurancePlanId: yup.string().required("Required"),
      insuranceHolderName: yup.string().required("Required"),
      insuranceHolderDOB: yup.date().required("Required"),
      memberId: yup.string().required("Required"),
      groupNumber: yup.string().nullable(),
      effectiveDate: yup.date().required("Required"),
      expirationDate: yup.date().required("Required"),
    }),
    onSubmit: (values) => onContinue(values),
  });
  const { insurancePlans } = useGetInsurancePlans({ insuranceid: formik.values.insuranceid });
  const insurancePlanOptions = insurancePlans
    ?.filter(({ insurance_id }) => parseInt(insurance_id) === parseInt(formik.values.insuranceid))
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  useEffect(() => {
    dispatch(actions.getInsuranceProvidersList());
  }, []);

  if (providersListLoading) return <ANLoadingScreen />;

  return (
    <Stack gap={theme.spacing(7)} {...rest}>
      <Stack gap={theme.spacing(3)}>
        <Typography variant="h2">Add your insurance plan{isSecondary && " (Secondary)"}</Typography>
        <Typography>Help us fill in the details of your plan.</Typography>
      </Stack>
      <TextField
        label="Insurance Company"
        name="insuranceid"
        value={formik.values.insuranceid}
        onChange={formik.handleChange}
        select
      >
        <MenuItem disabled value={""}>
          Please select
        </MenuItem>
        {insuranceProvidersList.map((item) => (
          <MenuItem key={`${item.id}`} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Insurance Plan"
        name="insurancePlanId"
        value={formik.values.insurancePlanId}
        onChange={formik.handleChange}
        select
      >
        <MenuItem disabled value={""}>
          Please select
        </MenuItem>
        {insurancePlanOptions.map((item) => (
          <MenuItem key={`${item.value}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Insurance Holder Name"
        name="insuranceHolderName"
        value={formik.values.insuranceHolderName}
        onChange={formik.handleChange}
      />
      <ANDatePicker
        label="Insurance Holder Date of Birth"
        name="insuranceHolderDOB"
        value={formik.values.insuranceHolderDOB}
        onChange={(value) => formik.setFieldValue("insuranceHolderDOB", value, true)}
        format="PP" //"MMMM d, yyyy"
      />
      <TextField
        label="Member ID"
        name="memberId"
        value={formik.values.memberId}
        onChange={formik.handleChange}
        placeholder="1122334455"
      />
      <TextField
        label="Group ID"
        name="groupNumber"
        value={formik.values.groupNumber}
        onChange={formik.handleChange}
        placeholder="72034"
      />
      <ANDatePicker
        label="Effective Date"
        name="effectiveDate"
        value={formik.values.effectiveDate}
        onChange={(value) => formik.setFieldValue("effectiveDate", value, true)}
        format="PP" //"MMMM d, yyyy"
      />
      <ANDatePicker
        label="Expiration Date"
        name="expirationDate"
        value={formik.values.expirationDate}
        onChange={(value) => formik.setFieldValue("expirationDate", value, true)}
        format="PP" //"MMMM d, yyyy"
      />
      <Stack gap={theme.spacing(3)}>
        <LoadingButton
          size="large"
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
          loading={isSavingLoading}
        >
          Continue
        </LoadingButton>
        <Button size="large" color="secondary" endIcon={<ArrowArcRight />} onClick={onSkip}>
          Skip
        </Button>
        <Button size="large" variant="text" onClick={goBack}>
          Back
        </Button>
      </Stack>
    </Stack>
  );
}
