import React, { useEffect } from "react";
import BasicInfo from "../Widgets/BasicInfo";
import Bio from "../Widgets/Bio";
import { Box, Stack } from "@mui/material";
import CalendarSync from "../Widgets/CalendarSync";
import EmploymentDetails from "../Widgets/EmploymentDetails";
import EditActionButtons from "../EditActionButtons";
import { useDispatch } from "react-redux";
import actions from "actions";
import { Formik } from "formik";
import SaveBeforeNavDialog from "components/ClientDetails/Dialogs/SaveBeforeNavDialog";
import { dateWithoutTimezone } from "utils/dateWithoutTimezone";
import { useUpdateEmploymentType } from "hooks/useUpdateEmploymentType";
import { useUpdateSupervisor } from "hooks/useUpdateSupervisor";

const ClinicianProfile = (props) => {
  const {
    userPermissions,
    isActive,
    isSupervisor,
    isDirector,
    isAdmin,
    isSuperAdmin,
    setUnsavedChanges,
    unsavedChanges,
    basicInfo,
    clinicianId,
    ...otherProps
  } = props;

  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const showEditButton = (action) => dispatch(actions.setPageDetails({ onEditButton: action }));
  const { updateEmploymentType, isLoading: isLoadingUpdateEmploymentType } =
    useUpdateEmploymentType();
  const { updateSupervisor, isLoading: isLoadingUpdateSupervisor } = useUpdateSupervisor();

  useEffect(() => {
    const canEdit =
      userPermissions?.edit_clinician_basic_info || userPermissions?.edit_clinician_bio;
    dispatch(
      actions.setPageDetails({
        onEditButton: canEdit,
        editButtonAction: () => {
          setEditMode(true);
          showEditButton(false);
        },
      })
    );
    return () => showEditButton(false);
  }, [userPermissions]);

  const initialValues = {
    appBio: basicInfo?.bio || "",
    firstName: basicInfo?.first_name || "",
    lastName: basicInfo?.last_name || "",
    fullLegalName: basicInfo?.full_legal_name || "",
    email: basicInfo?.email || "",
    phone: basicInfo?.phone || "",
    address1: basicInfo?.street_1 || "",
    address2: basicInfo?.street_2 || "",
    city: basicInfo?.city || "",
    state: basicInfo?.state || "",
    zip: basicInfo?.zip_code || "",
    dateOfBirth: basicInfo?.date_of_birth ? dateWithoutTimezone(basicInfo?.date_of_birth) : null,
    timezone: basicInfo?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    gender: basicInfo?.gender || "",
    taxNumber: basicInfo?.last_four ? `XXX-XX-${basicInfo?.last_four}` : "",
    filename: basicInfo?.image_name || "",
    licenseState: basicInfo?.dl_state || "",
    stateIdNumber: basicInfo?.state_id_number || "",
    employmentType: basicInfo?.employment_type,
    employmentStartDate: basicInfo?.start_date ? dateWithoutTimezone(basicInfo?.start_date) : null,
    employmentLastDate: basicInfo?.end_date ? dateWithoutTimezone(basicInfo?.end_date) : null,
    pronouns: basicInfo?.pronouns || "",
    supervisor: basicInfo?.supervisor_user_id || "",
  };

  function handleSave(values) {
    const { appBio, email, employmentType, taxNumber, employmentStartDate, employmentLastDate } =
      values;
    dispatch(actions.updateClinicianBio({ appBio, clinicianId }));
    updateEmploymentType(
      { clinicianId, employmentType, employmentStartDate, employmentLastDate },
      {
        onSuccess: () => dispatch(actions.getClinicianBasicInfo(clinicianId)),
      }
    );
    updateSupervisor(
      { clinicianId, supervisorUserId: values.supervisor },
      {
        onSuccess: () => dispatch(actions.getClinicianBasicInfo(clinicianId)),
      }
    );
    if (email !== initialValues.email) {
      dispatch(actions.updateClinicianEmail({ newEmail: email.toLowerCase(), clinicianId }));
    }
    const newTaxNumber =
      taxNumber !== initialValues.taxNumber && taxNumber.length === 11
        ? taxNumber.split("-").join("")
        : null;
    dispatch(
      actions.saveClinicianBasicInfo({
        ...values,
        clinicianId: clinicianId,
        taxNumber: newTaxNumber,
      })
    );
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSave}>
      {({ handleSubmit, dirty, resetForm }) => {
        const resetFormAndExitEditMode = () => {
          setEditMode(false);
          showEditButton(true);
          resetForm();
        };

        const onCancel = () => {
          if (dirty) {
            setOpenWarning(true);
            return;
          }
          if (!unsavedChanges) resetFormAndExitEditMode();
        };

        useEffect(() => {
          setUnsavedChanges(dirty);
        }, [dirty]);

        return (
          <Box display="flex" justifyContent="center">
            <Box width="80%" maxWidth="1200px" padding={"16px 0px"}>
              {userPermissions?.view_clinician_bio && (
                <Bio
                  {...props}
                  {...otherProps}
                  userPermissions={userPermissions}
                  editMode={editMode}
                />
              )}
              {userPermissions?.view_clinician_basic_info && (
                <BasicInfo
                  {...props}
                  isActive={isActive}
                  userPermissions={userPermissions}
                  editMode={editMode}
                />
              )}
              {otherProps.isClinicianProfile && <CalendarSync />}
              {(isDirector || isAdmin || isSuperAdmin || isSupervisor) && (
                <EmploymentDetails {...props} editMode={editMode} />
              )}
              {editMode && (
                <Stack alignItems="center">
                  <EditActionButtons
                    onCancel={onCancel}
                    onSave={handleSubmit}
                    loading={isLoadingUpdateEmploymentType || isLoadingUpdateSupervisor}
                  />
                </Stack>
              )}
              {/* Cancel action it is handled internally. Moving away action (going to a new page) it is still handled in the parent component */}
              <SaveBeforeNavDialog
                saveBeforeNavOpen={openWarning}
                onToggleSaveBeforeNav={() => setOpenWarning(false)}
                onContinueWithoutSaving={() => {
                  setOpenWarning(false);
                  resetFormAndExitEditMode();
                }}
                severetyAlert={"warning"}
                primaryActionColorButton={"error"}
                secondaryText={"Stay on Page"}
                isCancelClicked
              />
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};

export default ClinicianProfile;
