export const ProviderConfig = [
  {
    id: "id",
    label: "ID",
    dataKey: "id",
    cols: 12,
    hide: true,
  },
  {
    id: "first_name",
    label: "First Name",
    dataKey: "first_name",
    cols: 12,
    required: true,
    headerTitle: "Provider Details",
  },
  {
    id: "last_name",
    label: "Last Name",
    dataKey: "last_name",
    cols: 12,
    required: true,
  },
  {
    id: "npi_number",
    label: "NPI Number",
    dataKey: "npi_number",
    cols: 12,
  },
  {
    id: "specialty",
    label: "Specialty",
    dataKey: "specialty",
    cols: 12,
  },
  {
    id: "accepts_insurance",
    label: "Accepts Insurance",
    dataKey: "accepts_insurance",
    cols: 12,
    type: "yes_no",
    divider: true,
  },
  {
    id: "email",
    label: "Email Address",
    dataKey: "email",
    cols: 12,
    required: true,
  },
  {
    id: "phone",
    label: "Providers's Phone Number",
    dataKey: "phone",
    cols: 12,
    required: true,
    headerTitle: "Contact Information",
  },
  {
    id: "fax_number",
    label: "Providers's Fax Number (optional)",
    dataKey: "fax_number",
    cols: 12,
    divider: true,
  },
];
