import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/styles";

import { GET_INSURANCE_VOB, GET_FINANCIAL_GUARANTOR } from "constants/reactQueryKeys";
import actions from "actions";
import {
  useCreateInsuranceVOB,
  useGetInsuranceVOB,
  useUpdateInsuranceVOB,
} from "hooks/insuranceVOB";
import useUpdateClientInsurance from "hooks/api/client/useSaveClientInsurance";
import useUpdateGuarantor from "hooks/api/client/useUpdateFinancialGuarantor";
import * as selectors from "selectors";
import AnDetailsPanel from "elements/Forms/AnDetailsPanel";
import EditActionButtons from "components/ClinicianDetail/EditActionButtons";
import Insurance from "../Widgets/Insurance";
import InsuranceAuthorization from "../Widgets/InsuranceAuthorization";
import Providers from "../Widgets/Providers";
import { LoadingScreen } from "../../EventScheduling/EventSchedulingUtils";
import VerificationOfBenefits from "../Widgets/VerificationOfBenefits";
import { useGlobalToast } from "../../GlobalToastProvider";
import SaveBeforeNavDialog from "../Dialogs/SaveBeforeNavDialog";
import FinancialGuarantor from "./ProvidersAndInsurance/FinancialGuarantor";

const ProvidersAndInsurance = (props) => {
  const {
    classes,
    clientId,
    customerDetails,
    userInsurance,
    userPermissions,
    isClinician,
    editDisabled,
    getInsurancePlans,
    getInsuranceProvidersList,
    insuranceProvidersList,
    insuranceListProviderId,
    titlePanel,
    updateTitlePanel,
    inputsForm,
    updateInputsForm,
    isDirty,
    updateIsDirty,
    getUserInsurance,
    privatePayClient,
  } = props;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { showToast } = useGlobalToast();
  const theme = useTheme();

  // Edit Mode State
  const [editMode, setEditMode] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  // Panel State
  const [openPanel, setOpenPanel] = useState(false);
  const [dataPanel, setDataPanel] = useState({});
  const [configPanel, setConfigPanel] = React.useState([]);

  const [insurancesId, setInsurancesId] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [hasErrors, setHasErrors] = useState({});
  const userId = useSelector(selectors.getUserId);

  // Formik references
  const formRefs = useRef({
    financialGuarantor: null,
  });
  const panelRef = useRef(null);

  const showEditButton = (action) => dispatch(actions.setPageDetails({ onEditButton: action }));

  const { isLoading: isLoadingVOB, data: dataVOB = [] } = useGetInsuranceVOB(clientId, {
    status: "active",
  });

  useEffect(() => {
    dispatch(
      actions.setPageDetails({
        onEditButton: userPermissions?.view_client_insurance,
        editButtonAction: () => {
          setEditMode(true);
          showEditButton(false);
          moveToTop();
        },
      })
    );
    return () => showEditButton(false);
  }, []);

  const updateDataPanel = (key, value) => {
    setDataPanel((prevDataPanel) => ({
      ...prevDataPanel,
      [key]: value,
    }));
  };

  const resetDataPanel = () => {
    setDataPanel({});
  };

  const copyToDataPanel = (newData) => {
    setDataPanel(newData);
  };

  const updateHasChanges = (hasChanges) => {
    setHasChanges(hasChanges);
  };

  const resetFormAndExitEditMode = () => {
    setEditMode(false);
    showEditButton(true);
    resetDataPanel();
    setConfigPanel([]);
    setOpenPanel(false);
  };

  // Create Insurance VOB Mutation
  const { mutate: useCreateInsuranceVOBMutate, isLoading: isCreating } = useCreateInsuranceVOB({
    onSuccess: () => {
      resetFormAndExitEditMode();
      showToast({ message: "VOB Created Successfully!" });
      queryClient.invalidateQueries([GET_INSURANCE_VOB, clientId, { status: "active" }]);
    },
    onError: () => {
      showToast({
        message: "Error Creating VOB.",
        errorState: true,
      });
    },
  });

  // Update Insurance VOB Mutation
  const { mutate: useUpdateInsuranceVOBMutate, isLoading: isUpdating } = useUpdateInsuranceVOB({
    onSuccess: () => {
      resetFormAndExitEditMode();
      showToast({ message: "VOB Updated Successfully!" });
      queryClient.invalidateQueries([GET_INSURANCE_VOB, clientId, { status: "active" }]);
    },
    onError: () => {
      showToast({
        message: "Error Updating VOB.",
        errorState: true,
      });
    },
  });

  // Update|Create Insurance Mutation
  const { updateInsuranceMutation, isMutationInsuranceLoading } = useUpdateClientInsurance({
    onSuccess: () => {
      showToast({ message: "Updated Successfully!" });
      // Refresh the insurance data
      getUserInsurance(clientId);
      resetFormAndExitEditMode();
    },
  });

  // Update|Create Financial Guarantor Mutation
  const { updateGuarantorMutation, isMutationGuarantorLoading } = useUpdateGuarantor({
    onSuccess: () => {
      showToast({ message: "Updated Successfully!" });
      // Refresh the guarantor data
      queryClient.invalidateQueries([GET_FINANCIAL_GUARANTOR, clientId]);
      formRefs.current.financialGuarantor?.resetForm();
      resetFormAndExitEditMode();
    },
  });

  const isFormsDirty = Object.values(formRefs.current).some((form) => form?.dirty);

  // Save action for Insurance and Guarantor changes
  const onSave = async () => {
    // Get Guarantor form reference
    const guarantorFormRef = formRefs.current.financialGuarantor;
    // Get the insurance forms references
    const insuranceFormsRef = Object.keys(formRefs.current)
      .filter((key) => key.includes("insurance-") && formRefs.current[key] !== null)
      .map((key) => formRefs.current[key]);

    const firstInsuranceFormRef = insuranceFormsRef[0];
    const secondInsuranceFormRef = insuranceFormsRef[1];

    // Submit the insurance forms if they have changes
    if (firstInsuranceFormRef?.dirty || secondInsuranceFormRef?.dirty) {
      if (firstInsuranceFormRef && !firstInsuranceFormRef.isValid) {
        // Use the submitForm method from the formik reference to validate and display errors
        firstInsuranceFormRef.submitForm();
        return;
      }

      if (secondInsuranceFormRef && !secondInsuranceFormRef.isValid) {
        // Use the validateForm method from the formik reference to validate and display errors
        secondInsuranceFormRef.submitForm();
        return;
      }

      firstInsuranceFormRef?.submitForm && (await firstInsuranceFormRef?.submitForm());
      secondInsuranceFormRef?.submitForm && (await secondInsuranceFormRef?.submitForm());
    }

    // Flag to take the primary subscriber as the financial guarantor
    const isPrimarySubscriber = guarantorFormRef?.values?.isPrimarySubscriber;
    // Get the primary insurance data
    const primaryInsurance = secondInsuranceFormRef?.values.isPrimary
      ? secondInsuranceFormRef?.values
      : firstInsuranceFormRef?.values;

    if (isPrimarySubscriber && primaryInsurance) {
      await updateGuarantorMutation({
        clientId,
        isCreating: guarantorFormRef?.values.isCreating,
        isPrimarySubscriber: true,
        firstName: primaryInsurance.subscriberFirstName ?? "",
        lastName: primaryInsurance.subscriberLastName ?? "",
        dateOfBirth: primaryInsurance.subscriberDOB ?? null,
        gender: primaryInsurance.subscriberGender ?? "",
        relationshipToClient: primaryInsurance.subscriberRelationship ?? "",
        phone: primaryInsurance.subscriberPhone ?? "",
        email: primaryInsurance.subscriberEmail ?? "",
        country: primaryInsurance.subscriberCountry ?? "",
        state: primaryInsurance.subscriberState ?? "",
        city: primaryInsurance.subscriberCity ?? "",
        zipCode: primaryInsurance.subscriberZip ?? "",
        address1: primaryInsurance.subscriberAddress ?? "",
        address2: primaryInsurance.subscriberAddress2 ?? "",
      });
    }

    if (guarantorFormRef?.dirty && !isPrimarySubscriber) {
      await guarantorFormRef.submitForm();
    }
  };

  // Cancel Edit Mode
  const onCancel = () => {
    if (isFormsDirty) {
      setOpenWarning(true);
      return;
    }
    if (!hasChanges) resetFormAndExitEditMode();
  };

  const onSaveInsuranceVOB = async () => {
    if (!isDirty) {
      if (dataPanel?.id) {
        useUpdateInsuranceVOBMutate({ clientId, createdBy: userId, ...dataPanel });
      } else {
        useCreateInsuranceVOBMutate({ clientId, createdBy: userId, ...dataPanel });
      }
    }
  };

  const updateHasErrors = (key, value) => {
    setHasErrors((prevDataPanel) => ({
      ...prevDataPanel,
      [key]: value,
    }));
  };

  if (!userPermissions) {
    return <LoadingScreen />;
  }

  // Scroll to top of the panel when it opens
  const moveToTop = () => {
    if (panelRef?.current) {
      panelRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (openPanel) {
      moveToTop();
    }
  }, [openPanel]);

  return (
    <Stack
      direction="row"
      sx={{
        height: "calc(100vh - 163px)",
        [theme.breakpoints.down("md")]: {
          height: "calc(100vh - 171px)",
        },
        width: "100%",
      }}
    >
      <Stack
        flex={openPanel ? 2 : 1}
        sx={{
          transition: "all 0.3s ease",
          overflow: "auto",
          padding: "32px 50px",
          height: "100%",
        }}
      >
        {userPermissions?.view_client_insurance && (
          <>
            <Insurance
              editMode={editMode && userPermissions?.add_client_insurance}
              userInsurance={userInsurance}
              getInsuranceProvidersList={getInsuranceProvidersList}
              getInsurancePlans={getInsurancePlans}
              insuranceListProviderId={insuranceListProviderId}
              userPermissions={userPermissions}
              editDisabled={editDisabled}
              classes={classes}
              clientId={clientId}
              userId={userId}
              privatePayClient={privatePayClient}
              insuranceProvidersList={insuranceProvidersList}
              getUserInsurance={getUserInsurance}
              setInsurancesId={setInsurancesId}
              handleUpdateInsurance={updateInsuranceMutation}
              formRef={formRefs}
            />
          </>
        )}

        <VerificationOfBenefits
          {...props}
          classes={classes}
          editMode={editMode && userPermissions?.add_client_insurance}
          clientId={clientId}
          dataPanel={dataPanel}
          updateDataPanel={updateDataPanel}
          copyToDataPanel={copyToDataPanel}
          configPanel={configPanel}
          setConfigPanel={setConfigPanel}
          updateTitlePanel={updateTitlePanel}
          setIsPanelOpen={setOpenPanel}
          isPanelOpen={openPanel}
          updateInputsForm={updateInputsForm}
          onSave={onSaveInsuranceVOB}
          insurancesId={insurancesId}
          updateHasChenages={updateHasChanges}
          updateIsDirty={updateIsDirty}
          isDirty={isDirty}
          hasErrors={hasErrors}
          updateHasErrors={updateHasErrors}
          dataVOB={dataVOB}
          isLoadingVOB={isLoadingVOB}
          moveToTop={moveToTop}
        />

        {userPermissions?.view_client_insurance && (
          <FinancialGuarantor
            editMode={editMode && userPermissions?.add_client_insurance}
            clientId={clientId}
            formRef={(el) => (formRefs.current.financialGuarantor = el)}
            formId="financialGuarantor"
            handleUpdateGuarantor={updateGuarantorMutation}
          />
        )}

        {(isClinician || userPermissions?.download_phi_release_request) && (
          <>
            <Providers
              {...props}
              editMode={editMode}
              providers={customerDetails.physicians}
              customerInfo={customerDetails.details}
            />
          </>
        )}

        {userPermissions?.view_client_insurance_authorization && (
          <>
            <InsuranceAuthorization
              {...props}
              editMode={editMode && userPermissions?.add_client_insurance}
              userId={customerDetails.details.userid}
              approvedUnits={customerDetails.approvedUnits}
            />
          </>
        )}

        {editMode && (
          <Stack alignItems="center">
            <EditActionButtons
              onCancel={onCancel}
              onSave={onSave}
              loading={isMutationInsuranceLoading || isMutationGuarantorLoading}
            />
          </Stack>
        )}

        <SaveBeforeNavDialog
          saveBeforeNavOpen={openWarning}
          onToggleSaveBeforeNav={() => setOpenWarning(false)}
          onContinueWithoutSaving={() => {
            setOpenWarning(false);
            resetFormAndExitEditMode();
          }}
          severetyAlert={"warning"}
          primaryActionColorButton={"error"}
          secondaryText={"Stay on Page"}
          isCancelClicked
        />
      </Stack>

      <Stack
        flex={openPanel ? 2 : 0}
        sx={{
          borderLeft: `1px solid ${theme.palette.divider}`,
          transition: "all 0.3s ease",
          alignItems: "flex-end",
          overflow: "auto",
        }}
        maxWidth="400px"
      >
        <AnDetailsPanel
          ref={panelRef}
          classes={classes}
          open={openPanel}
          setOpen={setOpenPanel}
          editMode={editMode}
          titlePanel={titlePanel}
          data={dataPanel}
          resetData={resetDataPanel}
          config={configPanel}
          inputsForm={inputsForm}
          isLoading={isCreating || isUpdating}
          disableSaveButton={isDirty}
          onSave={onSaveInsuranceVOB}
          onCancel={() => {
            resetDataPanel();
            updateIsDirty(false);
            setConfigPanel([]);
            setOpenPanel(false);
          }}
          saveTitle={dataPanel.id ? "Save Changes" : "Create VOB"}
        />
      </Stack>
    </Stack>
  );
};

export default ProvidersAndInsurance;
