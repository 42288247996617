import React, { useState } from "react";
import { Chip, Popper, Paper, Box, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useHistory } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const AnChipPopper = ({ list = [], chipIcon, linkIcon, label, getLabel, getLink }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleChipEnter = (event) => {
    if (list.length > 1) {
      setAnchorEl(event.currentTarget);
      setIsHovered(true);
    }
  };

  const handleChipLeave = () => {
    setIsHovered(false);
  };

  const handlePopperEnter = () => {
    setIsHovered(true);
  };

  const handlePopperLeave = () => {
    setIsHovered(false);
  };

  const handleChipClick = () => {
    if (list.length === 1) {
      handleItemClick(list[0]);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item) => {
    handleClose();
    window.open(getLink(item), "_blank");
  };

  const id = open ? "an-chip-popper" : undefined;

  return (
    <>
      <Chip
        color="default"
        icon={chipIcon}
        label={label}
        size="medium"
        variant="outlined"
        onMouseEnter={handleChipEnter}
        onMouseLeave={handleChipLeave}
        onClick={handleChipClick}
        sx={{
          color: theme.palette.primary.main,
          cursor: list.length > 0 ? "pointer" : "default",
        }}
      />

      {list.length > 1 && (
        <Popper
          id={id}
          open={isHovered}
          anchorEl={anchorEl}
          placement="bottom"
          modifiers={[
            {
              name: "flip",
              enabled: true,
            },
            {
              name: "preventOverflow",
              options: {
                boundary: "viewport",
              },
            },
          ]}
          onMouseEnter={handlePopperEnter}
          onMouseLeave={handlePopperLeave}
          sx={{
            zIndex: theme.zIndex.tooltip,
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper
              sx={{
                p: "4px 8px",
                boxShadow: 3,
                backgroundColor: "#121212",
                borderRadius: "4px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                {list.map((item, index) => (
                  <Box
                    onClick={() => handleItemClick(item)}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{
                        fontSize: 12,
                        maxWidth: "157px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textAlign: "left",
                      }}
                    >
                      {getLabel(item)}
                    </Typography>
                    <Link
                      rel="noopener noreferrer"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {linkIcon}
                    </Link>
                  </Box>
                ))}
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};
export default AnChipPopper;
