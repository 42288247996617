/**
 * __mocks__/apiResponses/v2/clinician.js
 * Autogenerated via generateMockRoute.js
 * DO NOT MODIFY THIS FILE STRUCTURE DIRECTLY.
*/

/** @type {import('../../mockRequestHandler').EndpointMockConfig} */
export default {
  "v2/clinician/:userid/clients": {
    active: true,
    GET: {
      active: false,
      default: {
        responseValues: {
          status: 200,
          response: [
            {
              id: "144915",
              first_name: "noolan",
              last_name: "harris",
              email: "nolan+saveforlater10@getanswersnow.com",
              workflow_status: "ASSESSMENT_SCHEDULED",
              expired: false,
              created_date: "2021-08-17T19:07:03.496Z",
              trial_period: 0,
              subscribed: false,
              legacy: false,
              started_on: "2021-08-17T19:07:03.496Z",
              clinician_user_id: "145163",
              secondary_clinician_user_id: "145679",
              client_id: "144141",
              user_id: "144915",
              name: "Kiddo",
              age: null,
              date_of_birth: "2018-05-10T00:00:00.000Z",
              private_pay: false,
              conversation_url:
                "sendbird_group_channel_320278424_990ca2c00a2360809f8572ea74aa758799a1a023",
              insurance: "Peach State",
              insurance_plan_name: null,
              count: "1",
            },
          ],
        },
      },
      scenarios: {
        no_clients: {
          active: false,
          condition: {
            urlParams: {
              userid: "145679", // <- use your target clinician's userid
            },
          },
          responseValues: {
            status: 200,
            response: [],
          },
        },
        two_clients: {
          active: false,
          condition: {
            urlParams: {
              userid: "145679", // <- us  your target clinician's userid
            },
          },
          responseValues: {
            status: 200,
            response: [
              {
                id: "144915",
                first_name: "noolan",
                last_name: "harris",
                email: "nolan+saveforlater10@getanswersnow.com",
                workflow_status: "ASSESSMENT_SCHEDULED",
                expired: false,
                created_date: "2021-08-17T19:07:03.496Z",
                trial_period: 0,
                subscribed: false,
                legacy: false,
                started_on: "2021-08-17T19:07:03.496Z",
                clinician_user_id: "145163",
                secondary_clinician_user_id: "145679",
                client_id: "144141",
                user_id: "144915",
                name: "Kiddo",
                age: null,
                date_of_birth: "2018-05-10T00:00:00.000Z",
                private_pay: false,
                conversation_url:
                  "sendbird_group_channel_320278424_990ca2c00a2360809f8572ea74aa758799a1a023",
                insurance: "Peach State",
                insurance_plan_name: null,
                count: "1",
              },
              {
                id: "144915",
                first_name: "nooolan",
                last_name: "harris",
                email: "nolan+saveforlater10@getanswersnow.com",
                workflow_status: "ASSESSMENT_SCHEDULED",
                expired: false,
                created_date: "2021-08-17T19:07:03.496Z",
                trial_period: 0,
                subscribed: false,
                legacy: false,
                started_on: "2021-08-17T19:07:03.496Z",
                clinician_user_id: "145163",
                secondary_clinician_user_id: "145679",
                client_id: "144141",
                user_id: "144915",
                name: "Kiddo",
                age: null,
                date_of_birth: "2018-05-10T00:00:00.000Z",
                private_pay: false,
                conversation_url:
                  "sendbird_group_channel_320278424_990ca2c00a2360809f8572ea74aa758799a1a023",
                insurance: "Peach State",
                insurance_plan_name: null,
                count: "1",
              },
            ],
          },
        },
      },
    },
  },
  "v2/clinician/example": {
    active: false,
    GET: {
      active: false,
      default: {
        responseValues: {
          response: null,
          status: 204,
        },
      },
      scenarios: {},
    },
    POST: {
      active: false,
      default: {
        responseValues: {
          response: null,
          status: 204,
        },
      },
      scenarios: {},
    },
    PUT: {
      active: false,
      default: {
        responseValues: {
          response: null,
          status: 204,
        },
      },
      scenarios: {},
    },
    DELETE: {
      active: false,
      default: {
        responseValues: {
          response: null,
          status: 204,
        },
      },
      scenarios: {},
    },
  },
};
