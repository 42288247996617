import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { UserCirclePlus } from "@phosphor-icons/react";
import { useTheme } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

const AnItemLinked = ({ text, editMode, onDeleteItem, isDeletingItem }) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        padding: "16px",
        borderRadius: "6px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#F6F7F8",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <UserCirclePlus color={theme.palette.primary.main} fontSize="24px" weight="duotone" />
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {text}
        </Typography>
      </Stack>
      {editMode && onDeleteItem && (
        <LoadingButton
          variant="text"
          color="error"
          loading={isDeletingItem}
          onClick={onDeleteItem}
          sx={{ textTransform: "none", fontWeight: 600 }}
        >
          Remove
        </LoadingButton>
      )}
    </Paper>
  );
};

export default AnItemLinked;
