import { useMemo } from 'react';

const useHashParams = () => {
  return useMemo(() => {
    const hash = window.location.hash.substring(1);
    if (!hash) return new URLSearchParams();
    const queryString = hash.split('?')[1] || '';
    return new URLSearchParams(queryString);
  }, [window.location.hash]);
};

export default useHashParams;