import { useQuery } from "react-query";

import { GET_FINANCIAL_GUARANTOR, STALE_TIME_5_MINUTES } from "constants/reactQueryKeys";
import { getFinancialGuarantor } from "api/customers";

/**
 * The useGetFinancialGuarantor hook fetches the financial guarantor data for a given client.
 * @param {string|number} clientId - The client ID.
 */
const useGetFinancialGuarantor = (clientId) => {
  const { data, isLoading, refetch, ...rest } = useQuery(
    [GET_FINANCIAL_GUARANTOR, clientId],
    () => getFinancialGuarantor(clientId),
    {
      enabled: Boolean(clientId),
      staleTime: STALE_TIME_5_MINUTES,
      retry: false,
    }
  );

  return {
    guarantor: data?.data || null,
    guarantorLoading: isLoading,
    refetchGuarantorData: refetch,
    ...rest,
  };
};

export default useGetFinancialGuarantor;
