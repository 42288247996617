import React, { useState } from "react";
import { Grid, Tabs, Tab, Box, Typography, Divider } from "@mui/material";
import ANEditItem from "./ANEditItem";

const AnFormEdit = ({ inputsForm, classes }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const isTabsMode = inputsForm?.tabs;

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const renderEditItems = (items) =>
    items.map((item, index) => {
      if (item.hide) return null;
      if (isTabsMode && item.tab !== currentTab) return null;

      return (
        <ANEditItem
          key={index}
          classes={classes}
          input={item.input}
          xs={12}
          sm={item.cols}
          paddingTop={item.paddingTop}
          title={item.title}
          panelMode={false}
          divider={item.divider}
          headerTitle={item.headerTitle}
          headerDescription={item.headerDescription}
        />
      );
    });

  return (
    <>
      {isTabsMode && (
        <Box
          sx={{
            backgroundColor: "#FFF",
            width: "100%",
            padding: "16px 0px 8px 0px",
          }}
        >
          <Tabs
            indicatorColor="secondary"
            textColor="primary"
            value={currentTab}
            onChange={handleTabChange}
            aria-label="client-details-tabs"
            variant="fullWidth"
          >
            {inputsForm.tabs.map((tab) => (
              <Tab key={tab.link} value={tab.link} label={tab.label} disableRipple />
            ))}
          </Tabs>
        </Box>
      )}
      <Grid container spacing={5} style={{ display: "contents", paddingTop: "8px" }}>
        {isTabsMode ? renderEditItems(inputsForm.form) : renderEditItems(inputsForm)}
      </Grid>
    </>
  );
};

export default AnFormEdit;
