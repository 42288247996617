import React, { useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ArrowArcRight } from "@phosphor-icons/react";
import { useIntake } from "components/SelfService/IntakeProvider";
import useANUploader from "hooks/utils/useANUploader";
import { LoadingButton } from "@mui/lab";
import { STAGES } from "components/SelfService/utils";
import * as selectors from "selectors";
import { useDispatch, useSelector } from "react-redux";
import { postUploadFiles } from "hooks/postUploadFiles";
import actions from "actions";
import UploadWrapper from "../UploadWrapper";

/**
 * Renders the component for uploading insurance cards.
 *
 * @param {Object} props - The component props.
 * @param {'front' | 'back'} [props.side="front"] - The side of the insurance card to upload ("front" or "back").
 * @param {boolean} [props.isSecondary=false] - Indicates if the insurance card is secondary.
 */
function InsuranceCardUpload({
  side = "front",
  isSecondary = false,
  onSkip,
  onAddManualInsurance,
  onContinue,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customerDetails = useSelector(selectors.getCustomerDetails);
  const userId = useSelector(selectors.getUserId);
  const [isUploading, setIsUploading] = React.useState(false);
  const { details } = customerDetails;
  const { handleFileUpload, dropzoneFiles, handleFileDelete, setDropzoneFiles } = useANUploader();
  const { goBack, isSavingLoading, intakeFlowJson, currentSubStage, currentStage, clientId } =
    useIntake();
  const subStage = currentSubStage || Object.keys(STAGES[currentStage].subStages)[0];
  const subStageData = intakeFlowJson?.insuranceCard?.[subStage];

  useEffect(() => {
    setDropzoneFiles(subStageData ? [{ file: subStageData.data }] : []);
  }, [subStage]);

  const submitDocumentUpload = async () => {
    const file = dropzoneFiles[0]?.file;
    if (!subStageData?.isComplete) {
      let uploadDocType;
      if (side === "front") {
        uploadDocType = isSecondary ? "SECONDARY_INSURANCE_CARD_FRONT" : "INSURANCE_CARD_FRONT";
      } else {
        uploadDocType = isSecondary ? "SECONDARY_INSURANCE_CARD_BACK" : "INSURANCE_CARD_BACK";
      }
      setIsUploading(true);
      const { errors, success: filename } = await postUploadFiles(
        details.cognito_id,
        dropzoneFiles
      );
      setIsUploading(false);
      if (errors) {
        console.error(errors);
        return;
      }
      const document = {
        filename: filename.split("/")[1],
        display_filename: file.name,
        clientId: clientId,
        type: uploadDocType,
        uploaded_by: userId,
        file_size: file.size,
        key: filename,
      };
      dispatch(actions.uploadCustomerDocument(document));
    }
    onContinue({ name: file.name, type: file.type, size: file.size });
  };

  return (
    <>
      <Stack gap={theme.spacing(3)}>
        <Typography variant="h2">
          {side === "front" ? "Let’s talk Insurance." : "Just one more image 📸"}
          {isSecondary && " (Secondary)"}
        </Typography>
        <Typography>
          Upload a few images of your insurance cards for our team to start working with your
          insurance company.
        </Typography>
      </Stack>

      <UploadWrapper
        title={`${side === "front" ? "Front" : "Back"} of Insurance Card`}
        text={
          <>
            Upload an image of the{" "}
            <Typography variant="subtitle2" component="span">
              {side}
            </Typography>{" "}
            side of your insurance card.
          </>
        }
        textLink="Add my insurance manually"
        onClickLink={onAddManualInsurance}
        isUploaded={dropzoneFiles.length}
        ANUploaderProps={{
          handleFileUpload: handleFileUpload,
          dropzoneFiles: dropzoneFiles,
          handleFileDelete: handleFileDelete,
        }}
      />

      <Stack gap={theme.spacing(3)}>
        <LoadingButton
          disabled={!dropzoneFiles.length}
          loading={isSavingLoading || isUploading}
          size="large"
          onClick={submitDocumentUpload}
        >
          Continue
        </LoadingButton>
        <Button color="secondary" size="large" endIcon={<ArrowArcRight />} onClick={onSkip}>
          Skip
        </Button>
        <Button variant="text" size="large" onClick={goBack}>
          Back
        </Button>
      </Stack>
    </>
  );
}

export default InsuranceCardUpload;
