import React from "react";

/**
 * Custom hook that debounces a value by a specified delay.
 * Useful for input fields to prevent excessive API calls.
 *
 * @param {any} value - The value to debounce.
 * @param {number} delay - The delay in milliseconds to debounce the value.
 * @returns {any} - The debounced value.
 */
export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
