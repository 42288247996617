import React from "react";
import { dateWithoutTimezone } from "../../../utils/dateWithoutTimezone";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TimezonePicker from "../../../elements/TimezonePicker";
import FormControl from "@mui/material/FormControl";
import { STATES } from "../../../constants";
import MenuItem from "@mui/material/MenuItem";
import ANDatePicker from "elements/ANDatePicker";
import SectionBox from "../../../elements/SectionBox";
import ANFormView from "../../../elements/Forms/ANFormView";
import basicInfoConfig from "./BasicInfoConfig";
import { useFormikContext } from "formik";
import { formatToPhoneNumber } from "utils/formatFormFields";

const BasicInfo = ({ editMode, userPermissions, basicInfo }) => {
  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext();

  const onChangeTaxNumber = (e) => {
    const { value } = e.target;
    let ssn = value.replace(/[^0-9]/g, "");
    let number = "";
    number += ssn;
    if (number.length > 3) {
      number = number.split("-").join("");
      number = number.slice(0, 3) + "-" + number.slice(3);
      if (number.length > 6) {
        number = number.slice(0, 6) + "-" + number.slice(6);
      }
      if (number.length === 12) return;
    }
    setFieldValue("taxNumber", number);
  };

  return (
    <SectionBox
      title="Personal Information"
      description={"Manage and change clinician personal information on file"}
      columnDirection
    >
      {editMode && userPermissions?.edit_clinician_basic_info ? (
        <>
          <Grid container spacing={7}>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="firstName"
                label="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="lastName"
                label="Last Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="fullLegalName"
                label="Full Legal Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fullLegalName}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                label="Email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                variant="outlined"
                size="small"
                disabled={!userPermissions?.update_clinician_email_address}
                fullWidth
                helperText={
                  !userPermissions?.update_clinician_email_address
                    ? `Can't edit. Reach out to support if a change is needed.`
                    : ``
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="phone"
                label="Phone Number"
                onChange={(e) => setFieldValue("phone", e.target.value.replace(/[^\d]/g, ""), true)}
                onBlur={handleBlur}
                value={formatToPhoneNumber(values.phone)}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="address1"
                label="Address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address1}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="address2"
                label="Address 2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address2}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="city"
                label="City"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                select
                name="state"
                autoComplete="off"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                label="State"
                fullWidth
              >
                <MenuItem disabled value={""} />
                {Object.keys(STATES).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {STATES[key]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="zip"
                label="ZIP Code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zip}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="gender"
                label="Gender"
                select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.gender}
                fullWidth
              >
                <MenuItem disabled value={""} />
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="non_binary">Non-binary</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="pronouns"
                select
                label="Pronouns"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pronouns}
                fullWidth
              >
                <MenuItem value="" disabled />
                <MenuItem value="she/her">She/Her</MenuItem>
                <MenuItem value="he/him">He/Him</MenuItem>
                <MenuItem value="they/them">They/Them</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <FormControl margin="none" fullWidth>
                <ANDatePicker
                  value={values.dateOfBirth}
                  onChange={(value) => setFieldValue("dateOfBirth", value, true)}
                  label="Date of Birth"
                  format="PP" //"MMM d, yyyy"
                  disableFuture
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="taxNumber"
                label="Social Security Number"
                value={values.taxNumber}
                onChange={onChangeTaxNumber}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TimezonePicker
                name="timezone"
                timezone={values.timezone}
                onTimezoneChange={handleChange}
                fullWidth
                label="Time Zone"
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ "@media (min-width: 1200px)": { pl: "16px" } }}>
              <TextField
                name="stateIdNumber"
                label="Driver's License Number"
                value={values.stateIdNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <ANFormView
          data={{
            firstName: basicInfo?.first_name || "",
            lastName: basicInfo?.last_name || "",
            fullLegalName: basicInfo?.full_legal_name || "",
            email: basicInfo?.email || "",
            phone: basicInfo?.phone || "",
            address1: basicInfo?.street_1 || "",
            address2: basicInfo?.street_2 || "",
            city: basicInfo?.city || "",
            state: basicInfo?.state || "",
            zip: basicInfo?.zip_code || "",
            dateOfBirth: basicInfo?.date_of_birth
              ? dateWithoutTimezone(basicInfo?.date_of_birth)
              : null,
            timezone: basicInfo?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            gender: basicInfo?.gender || "",
            taxNumber: basicInfo?.last_four ? `XXX-XX-${basicInfo?.last_four}` : "",
            filename: basicInfo?.image_name || "",
            licenseState: basicInfo?.dl_state || "",
            licenseNumber: basicInfo?.state_id_number || "",
            employmentType: basicInfo?.employment_type,
            pronouns: basicInfo?.pronouns,
          }}
          config={basicInfoConfig}
        ></ANFormView>
      )}
    </SectionBox>
  );
};

export default BasicInfo;
