import React, { useState } from "react";
import { Button, Box, Typography, Stack, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ModalV2 from "./ModalV2";
import Checkbox from "@mui/material/Checkbox";
import { MagnifyingGlass, UserCircle } from "@phosphor-icons/react";

const ItemControlPanel = ({
  classes,
  items = [],
  setItems = () => {},
  renderItem = null,
  buttonIcon = <AddIcon />,
  listItemIcon = <UserCircle />,
  buttonText = "Add Item",
  titleSubmitDialog,
  onSubmitDialog,
  isSubmittingDialog,
  titleDialog,
  onCancelDialog,
  onSearch,
  filteredItems,
  onCheckboxSelected,
  descriptionDialog,
  searchInputPlaceHolder,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    onSearch("");
    setOpen(false);
  };

  const handleRemove = (index) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  return (
    <Box>
      {items.length > 0 && (
        <Box sx={{ marginTop: "16px", gap: 2, display: "flex", flexDirection: "column" }}>
          {items?.map((item, index) => {
            const isOnlyItem = items.length === 1;
            return renderItem
              ? React.cloneElement(renderItem(item, isOnlyItem), {
                  key: index,
                  onRemove: () => handleRemove(index),
                })
              : null;
          })}
        </Box>
      )}
      <Button
        color="secondary"
        variant="contained"
        startIcon={buttonIcon}
        onClick={handleOpen}
        fullWidth
        sx={{ marginTop: "16px" }}
      >
        {buttonText}
      </Button>
      <ModalV2
        open={open}
        titleDialog={titleDialog}
        onCancelDialog={onCancelDialog}
        maxWidth="xs"
        cancelActionText={"Cancel"}
        cancelOnClick={handleClose}
        onCloseDialog={handleClose}
        actionText={titleSubmitDialog}
        actionOnClick={() => {
          onSubmitDialog().then(() => {
            handleClose();
          });
        }}
        isSubmittingDialog={isSubmittingDialog}
      >
        <Box display="flex" flexDirection="column" gap={5} paddingTop={"24px"} width={"100%"}>
          <Typography variant="body2">{descriptionDialog}</Typography>
          <TextField
            onChange={(event) => {
              onSearch(event.target.value);
            }}
            placeholder={searchInputPlaceHolder}
            classes={{
              root: classes.searchBar,
            }}
            sx={{ height: 38 }}
            InputProps={{
              startAdornment: (
                <MagnifyingGlass size={20} weight="regular" color="rgba(0, 0, 0, 0.56)" />
              ),
            }}
          />
          <Stack direction="column" spacing={2} width="100%" sx={{ maxHeight: "340px" }}>
            {filteredItems?.map((clinic) => (
              <Stack
                key={clinic.id}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                sx={{
                  paddingY: "8px",
                  backgroundColor: "#fff",
                }}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  {listItemIcon}
                  <Typography variant="body2">{clinic.name}</Typography>
                </Stack>
                <Checkbox
                  onChange={() => {
                    onCheckboxSelected(clinic.id);
                  }}
                />
              </Stack>
            ))}
          </Stack>
        </Box>
      </ModalV2>
    </Box>
  );
};

export default ItemControlPanel;
