import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import ANLogo from "../../assets/answers_now_logo_purple.png";
import React, { useEffect } from "react";
import { useTheme } from "@mui/styles";
import { CaretRight } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import cookie from "react-cookies";
import { addDays } from "date-fns";

export default function ClientAccountSelectionScreen({ clients, userId }) {
  const theme = useTheme();
  const history = useHistory();

  const selectAccount = (clientId) => {
    cookie.save(`${userId}-clientId`, clientId, {
      path: "/",
      expires: addDays(new Date(), 30),
    });
    history.push("./dashboard");
    window.location.reload();
  };

  // If there is only one client, select it automatically
  useEffect(() => {
    if (clients.length === 1) selectAccount(clients[0].id);
  }, [clients]);

  return (
    <Stack height="100vh" alignItems="center" justifyContent="center">
      <Stack
        maxWidth="352px"
        alignItems="center"
        gap={7}
        style={{
          padding: theme.spacing(9, 3, 6, 3),
          borderRadius: "16px",
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <img src={ANLogo} alt="AnswersNow" title="AnswersNow" height="29px" />
        <Stack alignItems="center" gap={5}>
          <Stack alignItems="center">
            <Typography variant="h4">Client Accounts</Typography>
            <Typography variant="body2" textAlign="center" color="text.secondary">
              Choose which account to access
            </Typography>
          </Stack>
          <List disablePadding>
            {clients.map((account) => {
              const initials = `${account.name?.[0]?.toUpperCase() || ""}${account.child_last_name?.[0]?.toUpperCase() || ""}`;
              return (
                <ListItem disablePadding key={account.id} sx={{ width: "284px" }}>
                  <ListItemButton onClick={() => selectAccount(account.id)}>
                    <Avatar sx={{ mr: theme.spacing(3) }} size="24px">
                      {initials}
                    </Avatar>
                    <Typography flexGrow={1} variant="body2">
                      {account.name}
                    </Typography>
                    <IconButton>
                      <CaretRight />
                    </IconButton>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Stack>
      </Stack>
    </Stack>
  );
}
