import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Link, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ArrowUpRight, Buildings, MapPin, Stethoscope } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import NoDocumentsWithPermissionOverlay from "elements/NoDocumentsWithPermissionOverlay";
import ANNoResultsOverlay from "elements/ANNoResultsOverlay";
import * as selectors from "selectors";
import ANTableFilters from "elements/ANTableFilters";
import { useGlobalToast } from "components/GlobalToastProvider";
import {
  useCreateClinic,
  useGetClinicById,
  useGetClinics,
  useUpdateClinic,
} from "../../hooks/useClinic";
import ANTooltip from "../../elements/ANTooltip";
import AnChipPopper from "../../elements/AnPopper";
import AnDetailsPanel from "../../elements/Forms/AnDetailsPanel";
import { ClinicConfig } from "./ClinicConfig";
import { ClinicForm } from "./ClinicForm";
import { GET_CLINICS, GET_PROVIDERS } from "../../constants/reactQueryKeys";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import { useGetProviders } from "../../hooks/useProvider";
import {
  useCreateProvidersClinics,
  useDeleteProvidersClinics,
} from "../../hooks/useProvidersClinics";
import { COVERAGE_STATES } from "../../constants";
import useHashParams from "../../hooks/useHashParams";
import { useHistory } from "react-router-dom";
import { formatToPhoneNumber } from "../../utils/formatFormFields";

const Clinics = (props) => {
  const {
    classes,
    editMode,
    dataPanel,
    resetDataPanel,
    copyToDataPanel,
    configPanel,
    setConfigPanel,
    isPanelOpen,
    setIsPanelOpen,
    titlePanel,
    updateTitlePanel,
    setOpenWarning,
    unsavedChanges,
    setEditMode,
    setSaveButtonTitle,
    updateActivePanel,
    inputsForm,
    updateInputsForm,
    updateDataPanel,
    setUnsavedChanges,
    activePanel,
    isDirty,
    updateIsDirty,
    setIsCancelClicked,
    panelRef,
    clearData,
  } = props;
  const theme = useTheme();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const orderByMap = {
    clinic_name: { sort: "clinic_name", direction: "asc" },
    city: { sort: "city", direction: "asc" },
    state: { sort: "state", direction: "asc" },
  };
  const params = useHashParams();
  const [orderBy, setOrderBy] = useState(orderByMap.clinic_name.sort);
  const [byFilterState, setByFilterState] = useState([]);
  const [byFilterCity, setByFilterCity] = useState([]);
  const [byFilterProvider, setByFilterProvider] = useState([]);
  const [byFiltersProviders, setByFiltersProviders] = useState([]);
  const [uploadedDateFilter, setuploadedDateFilter] = useState([null, null]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const userId = useSelector(selectors.getUserId);
  const { showToast } = useGlobalToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { isFetching, data: response } = useGetClinics(
    {
      sort: orderByMap[orderBy]?.sort,
      direction: orderByMap[orderBy]?.direction,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      city: byFilterCity.join(","),
      state: byFilterState.join(","),
      provider: byFilterProvider.join(","),
    },
    {
      onSuccess: (data) => {
        if (
          (activePanel === "deleteProvider" || activePanel === "createProvider") &&
          dataPanel.id
        ) {
          const row = data.data.clinics.filter((value) => value.id === dataPanel.id);
          if (row) {
            setUnsavedChanges(false);
            copyToDataPanel(row[0]);
            updateInputsForm(inputForms(row[0], [], "editClinic"));
          } else {
            if (params.size > 0 && params.get("id_clinic") === dataPanel?.id) {
              getClinicByIdMutate({ id: params.get("id_clinic") });
            }
          }
        }
      },
    }
  );

  const { isFetching: isFetchingProviders, data: providers } = useGetProviders(
    {
      sort: "first_name",
      direction: "asc",
      page: 0,
      size: 1000,
      provider_name: byFiltersProviders,
    },
    {
      onSuccess: () => {},
    }
  );

  const fetchedData = response?.data.clinics;
  const rowCount = response?.data.count || 0;
  const citiesFilters = response?.data.cities;
  const statesFilters = Object.keys(COVERAGE_STATES)
    .map((stateId) => {
      const stateFromDB = response?.data.states.find((state) => state.id === stateId);
      return {
        id: stateId,
        display_name: COVERAGE_STATES[stateId],
        count: stateFromDB ? stateFromDB.count : 0,
      };
    })
    .sort((a, b) => a.display_name.localeCompare(b.display_name));

  const providersFilter = response?.data.providers;

  const generateRows = () => {
    if (!fetchedData) return [];
    return fetchedData.map((data) => ({
      id: data.id,
      clinic_name: data.clinic_name,
      phone_number: formatToPhoneNumber(data.phone_number),
      fax_number: formatToPhoneNumber(data.fax_number),
      address: data.address,
      address_2: data.address_2,
      city: data.city,
      state: data.state,
      state_text: COVERAGE_STATES[data.state] ?? data.state,
      zip_code: data.zip_code,
      website: data.website,
      physician_clinic: data.physician_clinic,
    }));
  };

  useEffect(() => {
    if (params?.size > 0) {
      getClinicByIdMutate({ id: params.get("id_clinic") });
    }
  }, [params]);

  const rows = generateRows();

  const getColumnDefinitions = () => {
    const defaultColAttrs = {
      sortable: false,
      flex: 1,
    };
    const columns = [
      {
        field: "clinic_name",
        headerName: "Clinic Name",
        ...defaultColAttrs,
      },
      { field: "phone_number", headerName: "Phone Number", ...defaultColAttrs },
      {
        field: "fax_number",
        headerName: "Fax Number",
        ...defaultColAttrs,
      },
      {
        field: "address",
        headerName: "Address",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <ANTooltip
              component={Typography}
              variant="body2"
              style={{ maxWidth: "100%", display: "block" }}
            >
              {params.row.address}
            </ANTooltip>
          );
        },
      },
      {
        field: "address_2",
        headerName: "Address 2",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <ANTooltip
              component={Typography}
              variant="body2"
              style={{ maxWidth: "100%", display: "block" }}
            >
              {params.row.address_2}
            </ANTooltip>
          );
        },
      },
      {
        field: "city",
        headerName: "City",
        ...defaultColAttrs,
      },
      {
        field: "state_text",
        headerName: "State",
        ...defaultColAttrs,
      },
      {
        field: "zip_code",
        headerName: "ZIP Code",
        ...defaultColAttrs,
      },
      {
        field: "physician_clinic",
        headerName: "Providers",
        ...defaultColAttrs,
        renderCell: (params) => {
          const physicianClinic = params.row.physician_clinic;
          const providerCount = physicianClinic.length;
          let labelText = `${providerCount} Providers`;
          if (providerCount === 1) {
            labelText = `${physicianClinic[0].physician.first_name} ${physicianClinic[0].physician.last_name}`;
          }
          return (
            <AnChipPopper
              list={physicianClinic}
              chipIcon={
                <Stethoscope
                  size={16}
                  color={theme.palette.primary.main}
                  style={{ flex: "none" }}
                  weight="duotone"
                />
              }
              linkIcon={
                <ArrowUpRight weight="regular" color={theme.palette.primary.main} size={16} />
              }
              label={labelText}
              getLink={(item) => {
                return `libraries#providers?id_provider=${item.physician.id}`;
              }}
              getLabel={(item) => {
                return `${item.physician.first_name} ${item.physician.last_name}`;
              }}
            />
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <Link
              color="primary"
              variant="body2"
              underline="hover"
              onClick={() => handleEditItem(params.row)}
              sx={{ "&:hover": { cursor: "pointer" } }}
            >
              Edit
            </Link>
          );
        },
      },
    ];
    return columns;
  };

  const orderingMenuItems = [
    { label: "Clinic Name", value: "clinic_name" },
    { label: "City", value: "city" },
    { label: "State", value: "state" },
  ];

  const filtersMenuItems = [
    {
      label: "City",
      value: "city",
      valueUncapitalize: false,
      icon: <MapPin weight="duotone" />,
      countObject: citiesFilters,
      state: byFilterCity,
      setState: setByFilterCity,
    },
    {
      label: "State",
      value: "state",
      valueUncapitalize: false,
      icon: <MapPin weight="duotone" />,
      countObject: statesFilters,
      state: byFilterState,
      setState: setByFilterState,
    },
    {
      label: "Providers",
      value: "physician_clinic.physician_id",
      icon: <Buildings weight="duotone" />,
      countObject: providersFilter,
      state: byFilterProvider,
      setState: setByFilterProvider,
    },
  ];

  const handleClearFilters = () => {
    setByFilterCity([]);
    setByFilterState([]);
    setByFilterProvider([]);
    setuploadedDateFilter([null, null]);
  };

  const customNoRowsOverlay = {
    NoResults: {
      component: ANNoResultsOverlay,
      height: "236px",
      props: { handleClearFilters },
    },
  };

  const overlayName = "NoResults";

  const validateRequired = (data, type) => {
    const errors = {};
    let hasErrors = false;
    const requiredIds = ClinicConfig.filter((item) => item.required).map((item) => item.id);
    requiredIds.forEach((id) => {
      if (type === "zipCode" && id.includes("zip")) {
        if (data[id]?.length < 5) {
          hasErrors = true;
          errors[id + "_format_error"] = true;
        } else if (data[id] === undefined || data[id] === null || data[id].length === 0) {
          hasErrors = true;
          errors[id] = true;
        } else {
          errors[id + "_format_error"] = false;
          errors[id] = false;
        }
      } else if (data[id] === undefined || data[id] === null || data[id].length === 0) {
        hasErrors = true;
        errors[id] = true;
      } else {
        errors[id] = false;
      }
    });
    updateIsDirty(hasErrors);
    return errors;
  };

  const onChange = (name, onChangeType, data) => (e) => {
    const { checked, type, value } = e.target;
    let needValidateRequired = true;
    if (!onChangeType) {
      let val = type === "checkbox" ? checked : value;
      data[name] = val;
      updateDataPanel(name, val);
    } else if (onChangeType === "radioButtonChange") {
      let parsedValue;
      if (typeof value === "boolean") {
        parsedValue = value;
      } else {
        parsedValue = value === "true";
      }
      data[name] = parsedValue;
      updateDataPanel(name, parsedValue);
    } else if (onChangeType === "phoneNumberchange") {
      const phone = value.replace(/[^\d]/g, "");
      data[name] = phone;
      updateDataPanel(name, phone);
    } else if (onChangeType === "numberChange") {
      data[name] = value.replace(/[^0-9]/g, "");
      updateDataPanel(name, value.replace(/[^0-9]/g, ""));
    } else if (onChangeType === "zipCode") {
      data[name] = value;
      updateDataPanel(name, value);
    } else if (onChangeType === "physician_clinic") {
      if (!data["physician_clinic"]) {
        data["physician_clinic"] = [];
      }
      const provider = providers?.data.providers?.find((provider) => provider.id === value);
      if (provider) {
        data["physician_clinic"].push({
          id: provider.id,
          physician_id: provider.id,
          physician: { ...provider },
        });
      }
      needValidateRequired = false;
    }
    setUnsavedChanges(true);
    updateInputsForm(
      inputForms(
        data,
        needValidateRequired ? validateRequired(data, onChangeType) : data,
        activePanel
      )
    );
  };

  const onCheckboxSelected = (clinicId) => {
    setSelectedProviders((prevSelected) => {
      return prevSelected.includes(clinicId)
        ? prevSelected.filter((id) => id !== clinicId)
        : [...prevSelected, clinicId];
    });
  };

  const onSubmitProviders = async () => {
    if (dataPanel?.id) {
      updateActivePanel("createProvider");
      const payloads = selectedProviders.map((value) => ({
        physician_id: value,
        clinic_id: dataPanel.id,
        created_by: userId,
      }));
      useCreateProvidersClinicsMutate(payloads);
      updateIsDirty(false);
    } else {
      selectedProviders.forEach((value) => {
        const clinicExists = dataPanel.physician_clinic.some(
          (clinic) => clinic.clinic_id === value
        );
        if (!clinicExists) {
          onChange("physician_clinic", "physician_clinic", dataPanel)({ target: { value } });
        }
      });
    }
  };

  const removeProvider = async (providerId, data) => {
    if (data?.id) {
      updateActivePanel("deleteProvider");
      useDeleteProvidersClinicsMutate(providerId);
      updateIsDirty(false);
    } else {
      if (data["physician_clinic"]) {
        const clinicIndex = data["physician_clinic"].findIndex((item) => item.id === providerId);
        if (clinicIndex !== -1) {
          data["physician_clinic"].splice(clinicIndex, 1);
          updateInputsForm(
            inputForms(data, validateRequired(data, "physician_clinic"), activePanel)
          );
        }
      }
    }
  };

  const inputForms = (data, errors) => {
    return ClinicForm(
      classes,
      data,
      errors,
      onChange,
      (providerId) => removeProvider(providerId, data),
      theme,
      isDeletingItem,
      providers?.data.providers,
      setByFiltersProviders,
      onCheckboxSelected,
      onSubmitProviders,
      isCreatingItem
    );
  };

  const { mutate: useCreateMutate, isLoading: isCreating } = useCreateClinic({
    onSuccess: () => {
      clearData();
      setSelectedProviders([]);
      showToast({ message: "Clinic Created Successfully!" });
      queryClient.invalidateQueries([GET_CLINICS]);
      queryClient.invalidateQueries([GET_PROVIDERS]);
    },
    onError: () => {
      showToast({
        message: "Error Creating VOB.",
        errorState: true,
      });
    },
  });

  const { mutate: useUpdateMutate, isLoading: isUpdating } = useUpdateClinic({
    onSuccess: () => {
      clearData();
      setSelectedProviders([]);
      showToast({ message: "Clinic Updated Successfully!" });
      queryClient.invalidateQueries([GET_CLINICS]);
    },
    onError: () => {
      showToast({
        message: "Error Creating VOB.",
        errorState: true,
      });
    },
  });

  const { mutate: useDeleteProvidersClinicsMutate, isLoading: isDeletingItem } =
    useDeleteProvidersClinics({
      onSuccess: () => {
        setSelectedProviders([]);
        queryClient.invalidateQueries([GET_CLINICS]);
        if (params.size > 0 && params.get("id_clinic") === dataPanel?.id) {
          getClinicByIdMutate({ id: params.get("id_clinic") });
        }
        showToast({ message: "Provider Removed Successfully!" });
      },
      onError: () => {
        showToast({
          message: "Error Deleting Provider of Clinic...",
          errorState: true,
        });
      },
    });

  const { mutate: useCreateProvidersClinicsMutate, isLoading: isCreatingItem } =
    useCreateProvidersClinics({
      onSuccess: () => {
        setSelectedProviders([]);
        queryClient.invalidateQueries([GET_CLINICS]);
        if (params.size > 0 && params.get("id_clinic") === dataPanel?.id) {
          getClinicByIdMutate({ id: params.get("id_clinic") });
        }
        showToast({ message: "Provider Connected Successfully!" });
      },
      onError: () => {
        showToast({
          message: "Error Connecting Provider on Clinic...",
          errorState: true,
        });
      },
    });

  const onSave = async () => {
    if (!isDirty && dataPanel) {
      delete dataPanel?.state_text;
      dataPanel.phone_number = dataPanel?.phone_number.replace(/[^\d]/g, "");
      dataPanel.fax_number = dataPanel?.fax_number?.replace(/[^\d]/g, "");
      if (dataPanel?.id) {
        useUpdateMutate({ id: dataPanel?.id, updated_by: userId, ...dataPanel });
      } else {
        useCreateMutate({ created_by: userId, ...dataPanel });
      }
    }
  };

  useEffect(() => {
    if (!isFetchingProviders) {
      updateInputsForm(inputForms(dataPanel, validateRequired(dataPanel, ""), activePanel));
    }
  }, [providers]);

  useEffect(() => {
    if (selectedProviders && selectedProviders.length > 0) {
      onChange(
        "physician_id",
        "physician_clinic",
        dataPanel
      )({ target: { value: selectedProviders[selectedProviders.length] } });
    }
  }, [selectedProviders]);

  const handleEditItem = (row) => {
    updateTitlePanel("Edit Clinic");
    setIsPanelOpen(true);
    setEditMode(true);
    updateIsDirty(true);
    setUnsavedChanges(false);
    setSaveButtonTitle("Edit Clinic");
    updateActivePanel("editClinic");
    copyToDataPanel(row);
    updateInputsForm(inputForms(row, [], "editClinic"));
  };

  const { mutate: getClinicByIdMutate } = useGetClinicById({
    onSuccess: (response) => {
      handleEditItem(response.data);
    },
  });

  return (
    <Stack
      direction="row"
      sx={{
        height: "calc(100vh - 163px)",
        [theme.breakpoints.down("md")]: {
          height: "calc(100vh - 171px)",
        },
        width: "100%",
      }}
    >
      <Stack
        flex={isPanelOpen ? 2 : 1}
        sx={{
          transition: "all 0.3s ease",
          overflow: "auto",
        }}
      >
        <div className={classes.header} style={{ padding: "16px" }}>
          <div className={classes.headerButtonContainer}>
            <ANTableFilters
              orderingMenuItems={orderingMenuItems}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              filtersMenuItems={filtersMenuItems}
              filtersButtonProps={{ disabled: fetchedData?.length === 0 }}
            />
          </div>
          <div className={classes.headerButtonContainer}>
            <Button
              color="secondary"
              startIcon={<Buildings />}
              size="small"
              onClick={() => {
                updateTitlePanel("Add Clinic");
                setIsPanelOpen(true);
                setEditMode(true);
                updateIsDirty(true);
                setUnsavedChanges(false);
                setSaveButtonTitle("Add Clinic");
                updateActivePanel("createClinic");
                updateInputsForm(inputForms({}, [], "createClinic"));
              }}
              sx={{ minWidth: "fit-content" }}
            >
              Add Clinic
            </Button>
          </div>
        </div>
        <div>
          <DataGrid
            rows={rows}
            loading={isFetching && !fetchedData}
            pageSizeOptions={[5, 10, 20]}
            columns={getColumnDefinitions()}
            disableRowSelectionOnClick
            autoHeight
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            slots={{
              noRowsOverlay: customNoRowsOverlay[overlayName].component,
            }}
            slotProps={{ noRowsOverlay: { ...customNoRowsOverlay[overlayName].props } }}
            sx={{ "--DataGrid-overlayHeight": customNoRowsOverlay[overlayName].height }}
            hideFooter={!rowCount}
            paginationMode="server"
            rowCount={rowCount}
          />
        </div>
      </Stack>

      <Stack
        flex={isPanelOpen ? 2 : 0}
        sx={{
          borderLeft: `1px solid ${theme.palette.divider}`,
          transition: "all 0.3s ease",
          alignItems: "flex-end",
          overflow: "auto",
          display: "flow",
        }}
        maxWidth="400px"
      >
        <AnDetailsPanel
          ref={panelRef}
          classes={classes}
          open={isPanelOpen}
          setOpen={setIsPanelOpen}
          editMode={editMode}
          titlePanel={titlePanel}
          data={dataPanel}
          resetData={resetDataPanel}
          config={configPanel}
          inputsForm={inputsForm}
          isLoading={isCreating || isUpdating || isDeletingItem || isCreatingItem}
          disableSaveButton={isDirty}
          onSave={onSave}
          onCancel={() => {
            if (unsavedChanges) {
              setIsCancelClicked(true);
              setOpenWarning(true);
            } else {
              clearData();
              setSelectedProviders([]);
              updateActivePanel(null);
              history.push("#clinics");
            }
          }}
          saveTitle={"Save Changes"}
          icon={
            <Buildings
              fontSize={"20px"}
              weight="duotone"
              color={theme.palette.primary.main}
            ></Buildings>
          }
          topEditEnable={false}
        />
      </Stack>
    </Stack>
  );
};
export default withStyles(styles)(Clinics);
