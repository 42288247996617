import { Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useIntake } from "components/SelfService/IntakeProvider";
import { STAGES } from "components/SelfService/utils";
import React from "react";

export default function AddSecondaryInsurance(props) {
  const theme = useTheme();
  const { setCurrentSubStage, setCurrentStage, goBack } = useIntake();
  return (
    <Stack gap={theme.spacing(7)} {...props}>
      <Typography variant="h2">Do you have a secondary insurance you’d like to upload?</Typography>

      <Stack gap={theme.spacing(3)}>
        <Button
          color="secondary"
          size="large"
          onClick={() => setCurrentSubStage(STAGES.insuranceCard.subStages.frontCard2.name)}
        >
          I have secondary insurance
        </Button>
        <Button
          color="secondary"
          size="large"
          onClick={() => setCurrentStage(STAGES.pediatrician.name)}
        >
          Not at this time
        </Button>
        <Button variant="text" size="large" onClick={goBack}>
          Back
        </Button>
      </Stack>
    </Stack>
  );
}
