import { useMutation, useQueryClient } from "react-query";
import { saveClinicianCredentialsAsync } from "api/clinician";

const useSaveClinicianCredentials = (options) => {
  const { ...otherOptions } = options;
  const queryClient = useQueryClient();
  return useMutation((payload) => saveClinicianCredentialsAsync(payload), {
    onSuccess: () => {
      // This will refetch the clinicianCredentials query
      queryClient.invalidateQueries("clinicianCredentials");
      options?.onSuccess();
    },
    ...otherOptions,
  });
};

export default useSaveClinicianCredentials;
