import React from "react";
import { Box } from "@mui/material";

import { useIntake } from "components/SelfService/IntakeProvider";
import { STAGES } from "components/SelfService/utils";

import PhysicianSearchForm from "../Physician/PhysicianSearchForm";
import PhysicianForm from "../Physician/PhysicianForm";

export default function Pediatrician({ client }) {
  const { currentSubStage, intakeFlowJson, isSavingLoading, saveProgress, setCurrentStage } =
    useIntake();
  const { subStages } = STAGES.pediatrician;
  const { manualForm, isComplete } = intakeFlowJson?.pediatrician ?? {};

  const handleSaveManualForm = async (data) => {
    saveProgress({
      // Clear the search data
      clearData: {
        subStages: [subStages.search.name],
      },
      subStage: subStages.manual.name,
      subStageData: data,
      onSuccess: () => setCurrentStage(STAGES.diagnosisReport.name),
      isStageCompleted: true,
    });
  };

  const showPhysicianForm =
    (isComplete && manualForm?.data) || currentSubStage === subStages.manual.name;

  if (showPhysicianForm) {
    return (
      <Box paddingLeft={5}>
        <PhysicianForm
          clientName={client}
          data={manualForm?.data}
          isLoading={isSavingLoading}
          label="Pediatrician"
          onContinueClick={handleSaveManualForm}
        />
      </Box>
    );
  }

  return (
    <Box paddingLeft={5}>
      <PhysicianSearchForm
        clientName={client}
        isLoading={isSavingLoading}
        label="Pediatrician"
        linkText="I don't see my Pediatrician"
        placeholder="Search by Pediatrician or Clinic"
        stage={STAGES.pediatrician}
        onSaveSuccess={() => setCurrentStage(STAGES.diagnosisReport.name)}
      />
    </Box>
  );
}
