import { getIntakeFlowData } from "api/customers";
import { INTAKE_FLOW } from "constants/reactQueryKeys";
import { useQuery } from "react-query";

export const useGetIntakeFlowData = (clientId) => {
  const { data, isLoading, ...rest } = useQuery(
    [INTAKE_FLOW, clientId],
    () => getIntakeFlowData(clientId),
    {
      enabled: !!clientId,
      refetchOnWindowFocus: false,
    }
  );

  return {
    intakeFlow: data?.data || null,
    intakeLoading: isLoading,
    ...rest,
  };
};
