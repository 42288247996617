import React from "react";
import { useTheme } from "@mui/styles";
import { Typography } from "@mui/material";

export default function PhysicianHeader({ clientName, physicianType, showDescription = true }) {
  const theme = useTheme();

  return (
    <>
      <Typography component="h2" variant="h2" sx={{ marginBottom: theme.spacing(3) }}>
        Who is {clientName}&apos;s {physicianType}?
      </Typography>

      {showDescription && (
        <Typography component="p" variant="body1">
          We&apos;ll work with your {physicianType} to build a comprehensive profile.
        </Typography>
      )}
    </>
  );
}
