/**
 * v2/index.js
 * Autogenerated via generateMockRouteIndexes.js
 * DO NOT MODIFY THIS FILE DIRECTLY.
 */

import clinician from './clinician';

/** @type {import('../../mockRequestHandler').EndpointMockConfig} */
export default {
  ...clinician
};
