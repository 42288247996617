import { useMutation } from "react-query";

import { postCreateUserInsurance, postUpdateUserInsurance } from "api/insurance";
import { useGlobalToast } from "components/GlobalToastProvider";

/**
 * `insuranceSubmission` determines whether to update or create a client's insurance record and
 * then calls the appropriate mutation function with the provided insurance data.
 */
const insuranceSubmission = (values) => {
  const mutationFn = !values.id ? postCreateUserInsurance : postUpdateUserInsurance;

  const insuranceData = {
    insuranceId: values.insuranceid || null,
    insurancePlanId: values.insurancePlanId,
    memberId: values.memberId,
    groupNumber: values.groupNumber,
    effectiveDate: values.effectiveDate || null,
    expirationDate: values.expirationDate || null,
    isPrimary: values.isPrimary,
    insuranceUserId: values.id,
    userId: values.userId,
    clientId: values.clientId,
    // Keep them as long as they are in the database ---------------->
    insuranceHolderDOB: values.insuranceHolderDOB,
    insuranceHolderRelationship: values.insurance_holder_relationship,
    insuranceHolderName: values.insuranceHolderName,
    // <-----------------------------------------------------
    isMedicaid: values.isMedicaid,
    subscriberFirstName: values.subscriberFirstName,
    subscriberLastName: values.subscriberLastName,
    subscriberDOB: values.subscriberDOB,
    subscriberGender: values.subscriberGender,
    subscriberEmail: values.subscriberEmail,
    subscriberPhone: values.subscriberPhone,
    subscriberRelationship: values.subscriberRelationship,
    subscriberCountry: values.subscriberCountry,
    subscriberState: values.subscriberState,
    subscriberCity: values.subscriberCity,
    subscriberZip: values.subscriberZip,
    subscriberAddress: values.subscriberAddress,
    subscriberAddress2: values.subscriberAddress2,
  };

  return mutationFn(insuranceData);
};

const useUpdateClientInsurance = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, isIdle, isLoading, ...rest } = useMutation(
    (payload) => insuranceSubmission(payload),
    {
      onError: () => {
        showToast({
          message: "Whoops! Something went wrong while updating insurance",
          errorState: true,
        });
        onError();
      },
      ...otherOptions,
    }
  );

  return {
    updateInsuranceMutation: mutate,
    isMutationInsuranceIdle: isIdle,
    isMutationInsuranceLoading: isLoading,
    ...rest,
  };
};

export default useUpdateClientInsurance;
