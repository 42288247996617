import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { useGlobalToast } from "components/GlobalToastProvider";
import ClinicianProfile from "./Profile";
import ClinicianDetailsPageContentsButtons from "./ClinicianDetailsPageContentsButtons";
import { ClinicianDetailsFormProvider } from "./ClinicianDetailsFormContext";
import Documents from "./Documents";
import AccountSettings from "./AccountSettings";
import { ClinicianWorkingHours } from "../ClinicianWorkingHours";
import ClinicianCredentials from "../ClinicianCredentials";
import DialogsContainer from "./Dialogs";
import ResetPasswordDialog from "../ClientDetails/Dialogs/ResetPasswordDialog";
import { IconButton, Snackbar } from "@mui/material";
import DeactivateUserDialog from "../ClientDetails/Dialogs/DeactivateUserDialog";
import { X } from "@phosphor-icons/react";
import actions from "../../actions";
import * as selectors from "../../selectors";
import LicensesCertifications from "./LicensesCertifications";
import { useUpdateClinicianAvailability } from "../ClinicianWorkingHours/hooks";

const ClinicianDetailsPageContents = (props) => {
  const {
    classes,
    onActiveTabChange,
    currentTab,
    onCloseEditMode,
    userPermissions,
    resetTab,
    submitButtonDisabled,
    editMode,
    setEditMode,
    visibleTabs,
    basicInfo,
    onCloseCalendarSyncToast,
    calendarSyncToastOpen,
    calendarSyncToastMessage,
    clinicianCredentialData,
    handleToggleInsuranceFormDialog,
    handleSelectedInsuranceCompanyChange,
    handleSelectedStateChange,
    handleAddNewInsurance,
    getInsuranceCompanyOptions,
    getStateFilterOptions,
    handleChangeCredentialsPageModel,
    handleInsuranceEdit,
    setSortModel,
    setPaginationModel,
    clinicianCredentialDataLoading,
    sortModel,
    paginationModel,
    handleCloseInsuranceFormDialog,
    onCompanyChange,
    onPlanChange,
    setInsuranceFormValues,
    submitInsurance,
    handleFileUpload,
    handleFileDelete,
    handleLoadedFileDelete,
    onViewCredentialFile,
    handleConfirmQuitCredentialEdit,
    handleCancelQuitCredentialEdit,
    handleToggleConfirmQuitCredentialEdit,
    credentialModifiedListener,
    selectedInsuranceCompanyFilter,
    selectedStateFilter,
    insuranceFormDialogOpen,
    formInsuranceCompany,
    formInsuranceCompanyId,
    formInsurancePlan,
    formInsurancePlanId,
    isNewCredential,
    selectedCredentialRow,
    formSubmittedDate,
    formInitialCredentialDate,
    formApprovalDate,
    formRecredentialDate,
    formTermDate,
    getInsurancePlanOptions,
    dropzoneFiles,
    documentUploadError,
    insuranceSaveFail,
    insuranceSaving,
    confirmQuitCredentialEditDialogOpen,
    credentialModified,
    editWorkingHoursPerm,
    viewWorkingHoursPerm,
    clinicianId,
    onSaveAccountSettings,
    onChangeAccountSettings,
    onChangeWorkingHours,
    setUnsavedChanges,
    unsavedChanges,
  } = props;

  const dispatch = useDispatch();
  const { section } = useParams();
  const { showToast } = useGlobalToast();
  const bioSuccess = useSelector(selectors.getClinicianBioSuccess);
  const bioFail = useSelector(selectors.getClinicianBioFail);
  const bioPictureSuccess = useSelector(selectors.getClinicianBioPictureSuccess);
  const bioPictureFail = useSelector(selectors.getClinicianBioPictureFail);
  const [newWorkingHours, setNewWorkingHours] = useState({});

  useEffect(() => {
    onActiveTabChange(section);
  }, [section]);

  const handleToggleDeactivateApplicant = () => {
    this.handleToggleRejectApplicantDialog(true);
  };
  /**
   * Add here all the updates need to do on the save
   *
   * The method called will need to have a handle change
   * data in fields to not execute extra updates
   */
  const onSubmitGlobalEdit = () => {
    resetTab(props.match.params.section);
    if (currentTab === "account-settings") {
      onSaveAccountSettings();
    } else if (currentTab === "workingHours") {
      saveWorkingHours({ newWorkingHours, basicInfo: basicInfo });
    }

    setEditMode(false);
  };

  useEffect(() => {
    if (bioSuccess || bioPictureSuccess) {
      finishSubmit("Success!");
    }
    if (bioFail || bioFail) {
      finishSubmit("Whoops! Something went wrong", true);
    }
  }, [bioSuccess, bioFail, bioPictureSuccess, bioPictureFail]);

  const finishSubmit = (message, isError = false) => {
    showToast({
      message: message,
      errorState: isError,
    });
    resetTab(currentTab);
    dispatch(actions.resetClientInfoState());
    dispatch(actions.clearUpdateUserInsuranceStatus());
  };

  const { mutate: saveWorkingHours } = useUpdateClinicianAvailability({
    onSuccess: () => {
      finishSubmit("Success!");
    },
    onError: () => {
      finishSubmit("Whoops! Something went wrong", true);
    },
  });

  useEffect(() => {
    if (props.match.params.section) {
      resetTab(props.match.params.section);
    }
  }, []);

  const tabs = [
    {
      label: "Profile",
      link: "profile",
      component: () => (
        <div>
          <ClinicianProfile isActive={basicInfo?.is_active} {...props} />
          <DeactivateUserDialog isActive={basicInfo?.is_active} {...props} />
          <DialogsContainer {...props} />
          <ResetPasswordDialog {...props} />
          <Snackbar
            sx={{ zIndex: 3007 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={calendarSyncToastOpen}
            onClose={onCloseCalendarSyncToast}
            message={calendarSyncToastMessage}
            classes={{ anchorOriginBottomCenter: classes.snackbar }}
            action={
              <IconButton color="inherit" sx={{ p: 0.5 }} onClick={onCloseCalendarSyncToast}>
                <X size={20} color="#fff" />
              </IconButton>
            }
          />
        </div>
      ),
    },
    {
      label: "Credentials",
      link: "credentials",
      component: () => (
        <div>
          <ClinicianCredentials
            overView={{
              //values
              numCredentialedPayors: clinicianCredentialData?.credentialed_count ?? 0,
              numStatesCovered: clinicianCredentialData?.state_count ?? 0,
            }}
            filter={{
              //handlers
              handleToggleInsuranceFormDialog: handleToggleInsuranceFormDialog,
              handleSelectedInsuranceCompanyChange: handleSelectedInsuranceCompanyChange,
              handleSelectedStateChange: handleSelectedStateChange,
              handleAddNewInsurance: handleAddNewInsurance,
              //values
              userPermissions,
              insuranceCompanyOptions: getInsuranceCompanyOptions(),
              stateOptions: getStateFilterOptions(),
              selectedInsuranceCompany: selectedInsuranceCompanyFilter,
              selectedState: selectedStateFilter,
            }}
            table={{
              // handlers
              handleChangeCredentialsPageModel: handleChangeCredentialsPageModel,
              handleEdit: handleInsuranceEdit,
              setSortModel: setSortModel,
              setPaginationModel: setPaginationModel,
              // values
              userPermissions,
              clinicianCredentialDataLoading,
              totalRowCount: +clinicianCredentialData?.count,
              rows: clinicianCredentialData?.credentials ?? [],
              sortModel,
              paginationModel,
            }}
            insuranceFormDialog={{
              // handlers
              handleCloseInsuranceFormDialog: handleCloseInsuranceFormDialog,
              onCompanyChange: onCompanyChange,
              onPlanChange: onPlanChange,
              setSubmittedDate: setInsuranceFormValues("formSubmittedDate"),
              setInitialCredentialDate: setInsuranceFormValues("formInitialCredentialDate"),
              setApprovalDate: setInsuranceFormValues("formApprovalDate"),
              setRecredentialDate: setInsuranceFormValues("formRecredentialDate"),
              setTermDate: setInsuranceFormValues("formTermDate"),
              submitInsurance: submitInsurance,
              handleFileUpload: handleFileUpload,
              handleFileDelete: handleFileDelete,
              handleLoadedFileDelete: handleLoadedFileDelete,
              onViewCredentialFile: onViewCredentialFile,
              handleConfirmQuitCredentialEdit: handleConfirmQuitCredentialEdit,
              handleCancelQuitCredentialEdit: handleCancelQuitCredentialEdit,
              handleToggleConfirmQuitCredentialEdit: handleToggleConfirmQuitCredentialEdit,
              credentialModifiedListener: credentialModifiedListener,
              // values
              insuranceFormDialogOpen,
              insuranceCompanyName: formInsuranceCompany,
              insuranceCompanyNameId: formInsuranceCompanyId,
              insurancePlan: formInsurancePlan,
              insurancePlanId: formInsurancePlanId,
              isNewCredential,
              selectedCredentialRow: selectedCredentialRow,
              submittedDate: formSubmittedDate,
              initialCredentialDate: formInitialCredentialDate,
              approvalDate: formApprovalDate,
              recredentialDate: formRecredentialDate,
              termDate: formTermDate,
              insuranceCompanyOptions: getInsuranceCompanyOptions(),
              insurancePlanOptions: getInsurancePlanOptions(),
              dropzoneFiles: dropzoneFiles,
              documentUploadError,
              insuranceSaveFail,
              insuranceSaving: insuranceSaving,
              confirmQuitCredentialEditDialogOpen,
              credentialModified,
            }}
          />
        </div>
      ),
    },
    {
      label: "Licenses & Certifications",
      link: "licenses-certifications",
      component: () => (
        <LicensesCertifications
          clinicianId={clinicianId}
          basicInfo={basicInfo}
          setUnsavedChanges={setUnsavedChanges}
          unsavedChanges={unsavedChanges}
        />
      ),
    },
    {
      label: "Documents",
      link: "documents",
      component: () => (
        <>
          <Documents editDisabled={true} {...props} clinicianId={clinicianId} />;
        </>
      ),
    },
    {
      label: "Working Hours",
      link: "workingHours",
      component: () => (
        <>
          {basicInfo && (
            <ClinicianWorkingHours
              editMode={editMode}
              basicInfo={basicInfo}
              newWorkingHours={newWorkingHours}
              setNewWorkingHours={setNewWorkingHours}
              editWorkingHoursPerm={editWorkingHoursPerm}
              viewWorkingHoursPerm={viewWorkingHoursPerm}
              onChangeWorkingHours={onChangeWorkingHours}
            />
          )}
        </>
      ),
    },
    {
      link: "account-settings",
      label: "Account Settings",
      component: (props) => {
        return (
          <AccountSettings
            handleToggleDeactivateApplicant={handleToggleDeactivateApplicant}
            {...props}
            isRoleClinician={basicInfo?.is_role_clinician}
            isActive={basicInfo?.is_active}
            editDisabled={props.editMode}
            onCloseEditMode={onCloseEditMode}
            onSubmitGlobalEdit={onSubmitGlobalEdit}
            onChangeUserStatus={(e) => changeAccountStatus(e)}
          />
        );
      },
    },
  ];

  const changeAccountStatus = (e) => {
    onChangeAccountSettings({ accountSettingsCID: clinicianId, name: "AccountStatus", value: e });
  };

  const selectedTab = tabs.find((tab) => {
    return (
      visibleTabs?.some((includedTab) => includedTab.link === tab.link) &&
      tab.link === (section ?? "profile")
    );
  });

  return (
    <div>
      <ClinicianDetailsFormProvider editMode={editMode} currentTab={currentTab}>
        {selectedTab && selectedTab.component(props)}
        {editMode ? (
          <ClinicianDetailsPageContentsButtons
            onCloseEditMode={onCloseEditMode}
            submitButtonDisabled={submitButtonDisabled}
            onSubmitGlobalEdit={onSubmitGlobalEdit}
            currentTab={currentTab}
            resetTab={() => resetTab(currentTab)}
          />
        ) : (
          ""
        )}
      </ClinicianDetailsFormProvider>
    </div>
  );
};

export default withStyles(styles)(ClinicianDetailsPageContents);
