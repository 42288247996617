import React from "react";
import { useSelector } from "react-redux";
import { Drawer, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { X } from "@phosphor-icons/react";
import Summary from "./Summary";
import InsuranceCard from "../Components/InsuranceCard";
import Pediatrician from "../Components/Pediatrician";
import DiagnosisDoctor from "../Components/DiagnosisDoctor";

import { IntakeProvider } from "../IntakeProvider";
import { STAGES } from "../utils";
import * as selectors from "selectors";
import DiagnosisReport from "../Components/DiagnosisReport";
import { useParams } from "react-router-dom";

export default function IntakeSpecialistExperience({ open, onClose }) {
  const theme = useTheme();
  const { id } = useParams();
  const clientId = useSelector(selectors.getCustomerClientId) || id;
  const customerDetails = useSelector(selectors.getCustomerDetails);
  const clientName = customerDetails?.details?.name;
  const workflowStatus = useSelector(selectors.getCustomerWorkflowStatus);
  const isActive = customerDetails?.details?.is_active;
  const hasStartedIntake = isActive && workflowStatus === "SELF_SERVICE_INTAKE";

  const stagesOrder = [
    STAGES.summary.name,
    STAGES.insuranceCard.name,
    STAGES.pediatrician.name,
    STAGES.diagnosisReport.name,
    STAGES.diagnosingDoctor.name,
  ];

  return (
    <IntakeProvider
      initialValues={{
        currentStage: STAGES.summary.name,
        stagesOrder,
        clientId,
        isSpecialist: true,
      }}
    >
      {({ currentStage, setCurrentStage, setCurrentSubStage, history }) => {
        return (
          <Drawer
            open={open}
            anchor="right"
            sx={{ "& .MuiPaper-root": { borderRadius: theme.radius.large } }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={theme.spacing(5, 7)}
              borderBottom={`1px solid ${theme.palette.divider}`}
            >
              <Typography variant="h4">Client Intake</Typography>
              <IconButton aria-label="close" onClick={onClose} fontSize="small" size="small">
                <X />
              </IconButton>
            </Stack>
            <Stack gap={theme.spacing(5)} padding={theme.spacing(7)} direction="row">
              {hasStartedIntake && (
                <Stack>
                  <Tabs
                    orientation="vertical"
                    sx={{ "& .MuiTab-root": { alignItems: "flex-start" } }}
                    value={currentStage}
                    onChange={(_, newValue) => {
                      setCurrentStage(newValue);
                      // Reset the subStage when changing the stage
                      setCurrentSubStage(history[newValue]?.at(-1), false);
                    }}
                  >
                    <Tab label="Summary" value={STAGES.summary.name} />
                    <Tab label="Insurance Card" value={STAGES.insuranceCard.name} />
                    <Tab label="Pediatrician" value={STAGES.pediatrician.name} />
                    <Tab label="Diagnosis Report" value={STAGES.diagnosisReport.name} />
                    <Tab label="Diagnosing Doctor" value={STAGES.diagnosingDoctor.name} />
                  </Tabs>
                </Stack>
              )}
              <Stack width="512px" height="calc(100vh - 111px)" overflow="auto">
                {currentStage === STAGES.summary.name && (
                  <Summary onClose={onClose} hasStartedIntake={hasStartedIntake} />
                )}
                {currentStage === STAGES.insuranceCard.name && (
                  <InsuranceCard padding={theme.spacing(0, 7, 7)} />
                )}
                {currentStage === STAGES.pediatrician.name && <Pediatrician client={clientName} />}
                {currentStage === STAGES.diagnosisReport.name && (
                  <DiagnosisReport padding={theme.spacing(0, 7, 7)} />
                )}
                {currentStage === STAGES.diagnosingDoctor.name && (
                  <DiagnosisDoctor client={clientName} />
                )}
              </Stack>
            </Stack>
          </Drawer>
        );
      }}
    </IntakeProvider>
  );
}
