import SecureRequest from "../utils/securerequest";

/**
 * Deletes a provider-clinic relationship.
 *
 * @param {string} providersClinicsId - The ID of the provider-clinic relationship to delete.
 * @param {object} payload - The payload containing additional data for the request.
 * @returns {Promise} - A promise that resolves when the deletion is complete.
 */
export const deleteProvidersClinics = async (providersClinicsId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.delete(`/v3/providersClinics/${providersClinicsId}`);
};


/**
 * Creates a new provider.
 *
 * @param {Object} payload - The data for the new provider.
 * @returns {Promise<Object>} The promise object of the API request.
 */
export const createProvidersClinics = async (payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.post(`/v3/providersClinics`, payload);
};