import { GET_CLINICS } from "constants/reactQueryKeys";
import { useMutation, useQuery } from "react-query";
import { createClinic, getAllClinics, getClinicById, updateClinic } from "../api/clinic";

export const useGetClinics = (params, { onSuccess, } ) => {
  return useQuery([GET_CLINICS, params], () => getAllClinics(params), {
    keepPreviousData: true,
    onSuccess,
  });
};

export const useGetClinicById = (options) => {
  const mutation = useMutation(async (params) => {
    return await getClinicById(params.id);
  }, options);
  return mutation;
};

export const useCreateClinic = (options) => {
  const mutation = useMutation(async (payload) => {
    return await createClinic(payload);
  }, options);
  return mutation;
};

export const useUpdateClinic = (options) => {
  const mutation = useMutation(async (payload) => {
    delete payload.physician_clinic;
    return await updateClinic(payload.id, payload);
  }, options);
  return mutation;
};
