import { getTeamMembers } from "api/team";
import { GET_TEAM_MEMBERS } from "constants/reactQueryKeys";
import { useQuery } from "react-query";
import { queryParamsObjIsValid } from "utils/queryParamsObjIsValid";

export const useGetTeamMembers = (query) => {
  const { data, isLoading, ...rest } = useQuery(
    [GET_TEAM_MEMBERS, { ...query }],
    () => getTeamMembers(query),
    {
      enabled: queryParamsObjIsValid(query),
    }
  );
  return {
    teamMembers: data?.data || null,
    teamMembersLoading: isLoading,
    ...rest,
  };
};
