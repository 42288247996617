import { TextField } from "@mui/material";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { STATES } from "../../constants";
import ItemControlPanel from "../../elements/AnItemControlPanel";
import AnItemLinked from "../../elements/AnItemLinked";
import { UserCircle, UserCirclePlus } from "@phosphor-icons/react";
import { formatToPhoneNumber } from "../../utils/formatFormFields";

export const ClinicForm = (
  classes,
  data,
  errors,
  onChange,
  onDeleteItem,
  theme,
  isDeletingItem,
  providers,
  onSearch,
  onProviderSelect,
  onSubmitProvider,
  isCreatingItem,
  onCloseAction
) => {
  const mapItems = data?.physician_clinic?.map((obj) => ({
    id: obj.id,
    clinic_id: obj.physician_id,
    text: obj.physician.first_name + " " + obj.physician.last_name,
  }));
  const mapProviders = providers
    ?.filter((provider) => !mapItems?.some((item) => item.clinic_id === provider.id))
    .map((provider) => ({
      id: provider.id,
      name: provider.first_name + " " + provider.last_name,
    }));
  return [
    {
      id: "clinic_name",
      headerTitle: "Clinic Details",
      input: (
        <TextField
          id="clinic_name"
          label="Clinic Name"
          onChange={onChange("clinic_name", null, data)}
          value={data?.clinic_name ?? ""}
          fullWidth
        />
      ),
      cols: 12,
    },
    {
      id: "phone_number",
      tab: 0,
      input: (
        <TextField
          label="Phone Number"
          id="phone_number"
          onChange={onChange("phone_number", "phoneNumberchange", data)}
          value={formatToPhoneNumber(data.phone_number ?? "")}
          variant="outlined"
          size="small"
          fullWidth
          inputProps={{ maxLength: 14 }}
        />
      ),
    },
    {
      id: "fax_number",
      tab: 0,
      input: (
        <TextField
          label="Fax Number"
          id="fax_number"
          onChange={onChange("fax_number", "phoneNumberchange", data)}
          value={formatToPhoneNumber(data.fax_number ?? "")}
          variant="outlined"
          size="small"
          fullWidth
          inputProps={{ maxLength: 14 }}
        />
      ),
    },
    {
      id: "address",
      tab: 0,
      input: (
        <TextField
          id="address"
          name="address"
          autoComplete="off"
          value={data?.address ?? ""}
          onChange={onChange("address", null, data)}
          variant="outlined"
          label="Address"
          fullWidth
          inputProps={{ maxLength: 1000 }}
        />
      ),
    },
    {
      id: "address_2",
      tab: 0,
      input: (
        <TextField
          id="address_2"
          name="address_2"
          autoComplete="off"
          value={data?.address_2 ?? ""}
          onChange={onChange("address_2", null, data)}
          variant="outlined"
          label="Address 2 (optional)"
          fullWidth
          inputProps={{ maxLength: 1000 }}
        />
      ),
    },
    {
      id: "city",
      tab: 0,
      input: (
        <TextField
          id="city"
          label="City"
          onChange={onChange("city", null, data)}
          value={data?.city ?? ""}
          fullWidth
          inputProps={{ maxLength: 255 }}
        />
      ),
      cols: 12,
    },
    {
      id: "state",
      tab: 0,
      input: (
        <TextField
          id="state"
          select
          label="State"
          onChange={onChange("state", null, data)}
          value={data?.state ?? ""}
          fullWidth
          autoComplete="off"
        >
          <MenuItem disabled value={""}>
            Select
          </MenuItem>
          {Object.keys(STATES).map((key) => {
            return (
              <MenuItem key={key} value={key}>
                {STATES[key]}
              </MenuItem>
            );
          })}
        </TextField>
      ),
    },
    {
      id: "zip_code",
      tab: 0,
      input: (
        <TextField
          id="zip_code"
          label="ZIP Code"
          placeholder="23226"
          onChange={onChange("zip_code", "zipCode", data)}
          value={data?.zip_code ?? ""}
          fullWidth
          inputProps={{ maxLength: 5 }}
          helperText={
            errors["zip_code"]
              ? "Please enter ZIP Code"
              : errors["zip_format_error"]
                ? "Invalid ZIP Code"
                : ""
          }
        />
      ),
      cols: 12,
    },
    {
      id: "website",
      input: (
        <TextField
          id="website"
          placeholder="health.com"
          label="Website (optional)"
          onChange={onChange("website", null, data)}
          value={data?.website ?? ""}
          fullWidth
        />
      ),
      cols: 12,
      divider: true,
    },
    {
      id: "providers",
      label: "Providers",
      headerTitle: "Providers",
      headerDescription: "Connect providers to the clinic from the provider library",
      input: (
        <>
          <ItemControlPanel
            classes={classes}
            items={mapItems}
            renderItem={(item) => (
              <AnItemLinked
                text={item?.text || ""}
                buttonIcon={<UserCirclePlus fontSize={"20px"} weight="duotone" />}
                editMode={true}
                onDeleteItem={() => onDeleteItem(item.id)}
                isDeletingItem={isDeletingItem}
              />
            )}
            buttonIcon={
              <UserCirclePlus
                fontSize={"20px"}
                weight="duotone"
                color={theme.palette.primary.main}
              />
            }
            listItemIcon={<UserCircle fontSize={"20px"} weight="duotone" />}
            buttonText="Connect Providers"
            stepsDialog={[<></>]}
            defaultValuesDialog={" Connect Providers"}
            submitLabelDialog={" Connect Providers"}
            titleDialog={"Connect Providers"}
            titleSubmitDialog={"Connect Provider"}
            descriptionDialog={"Choose providers below to connect with the Clinic’s profile."}
            onSubmitDialog={onSubmitProvider}
            onCheckboxSelected={onProviderSelect}
            filteredItems={mapProviders}
            onSearch={onSearch}
            isSubmittingDialog={isCreatingItem}
            searchInputPlaceHolder={"Search Providers"}
            onCloseAction={onCloseAction}
          />
        </>
      ),
      paddingTop: 0,
      cols: 12,
      divider: true,
    },
  ];
};
