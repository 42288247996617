import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/styles";
import { ArrowRight, Clock, Envelope, GlobeHemisphereWest, Phone } from "@phosphor-icons/react";
import React from "react";
import { format } from "date-fns";

import * as selectors from "selectors";
import actions from "actions";
import { startIntakeFlow } from "api/customers";
import useCompleteIntake from "hooks/api/customer/useCompleteIntake";
import ANLoadingScreen from "elements/ANLoadingScreen";
import { useIntake } from "components/SelfService/IntakeProvider";
import { STAGES } from "components/SelfService/utils";
import useGetClientFormsForSignature from "components/ClientDashboard/hooks/useGetClientFormsForSignature";
import { useGlobalToast } from "components/GlobalToastProvider";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { formatTimeZoneToAbbreviation } from "utils/formatTimeZoneToAbbreviation";
import SummaryInfoItem from "./SummaryInfoItem";
import SummaryTaskItem from "./SummaryTaskItem";

export default function Summary({ onClose, hasStartedIntake }) {
  const theme = useTheme();
  const { showToast } = useGlobalToast();
  const dispatch = useDispatch();
  const customerDetails = useSelector(selectors.getCustomerDetails);
  const { details } = customerDetails;
  const { setCurrentStage, setCurrentSubStage, intakeFlowJson, clientId } = useIntake();
  const { forms, isLoading } = useGetClientFormsForSignature(clientId, true, { show_all: true });

  // If there are no forms, consent forms are completed (if the intake has started)
  const isConsentFormsCompleted = forms.length === 0 && hasStartedIntake;

  // Mutation to complete the intake
  const { completeIntakeMutation, isIntakeMutationLoading } = useCompleteIntake({
    onSuccess: () => {
      showToast({ message: "Intake successfully completed" });
      dispatch(actions.loadCustomerDetails(clientId));
    },
  });

  // Mutation to start the intake
  const { mutate: startIntake, isLoading: startIntakeLoading } = useMutation(
    () => startIntakeFlow(clientId),
    {
      onSuccess: () => {
        showToast({ message: "Success!" });
        dispatch(actions.loadCustomerDetails(clientId));
      },
      onError: () => {
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: startIntake,
        });
      },
    }
  );

  const isReadyToComplete =
    intakeFlowJson?.insuranceCard?.isComplete &&
    isConsentFormsCompleted &&
    intakeFlowJson?.pediatrician?.isComplete &&
    intakeFlowJson?.diagnosisReport?.isComplete &&
    intakeFlowJson?.diagnosingDoctor?.isComplete;

  const onContinueIntake = () => {
    setCurrentStage(intakeFlowJson?.lastVisitedStage || STAGES.insuranceCard.name);
    setCurrentSubStage(intakeFlowJson?.lastVisitedSubStage, false);
  };

  if (isLoading) return <ANLoadingScreen />;

  const lastVisitedDate = new Date(intakeFlowJson?.lastVisitedDate);
  const lastVisitedText =
    intakeFlowJson?.lastVisitedStage && lastVisitedDate instanceof Date && !isNaN(lastVisitedDate)
      ? `Last step visited was ${STAGES[intakeFlowJson.lastVisitedStage].displayName} on ${format(lastVisitedDate, "MMMM do 'at' h:mm a")}`
      : "-";

  return (
    <Stack gap={theme.spacing(7)}>
      <Stack bgcolor="#F6F7F8" borderRadius={theme.radius.large}>
        <Stack direction="row" alignItems="center" padding={theme.spacing(5)}>
          <Avatar sx={{ mr: theme.spacing(4) }} size="32px">
            {`${details.name[0]}${details.last_name[0]}`}
          </Avatar>
          <Typography variant="body1" mr={theme.spacing(4)}>
            {details.name} {details.last_name}
          </Typography>
          <Chip label="Intake" color="warning" size="small" variant="outlined" />
        </Stack>

        <Stack padding={theme.spacing(0, 6, 7, 6)} gap={theme.spacing(4)}>
          <Divider />
          <SummaryInfoItem Icon={Phone} text={formatPhoneNumber(details.phone_number)} />
          <SummaryInfoItem Icon={Envelope} text={details.email} />
          <SummaryInfoItem
            Icon={GlobeHemisphereWest}
            text={`${details.city}, ${details.state} ${details.timezone ? "(" + formatTimeZoneToAbbreviation(details.timezone) + ")" : ""}`}
          />
          <SummaryInfoItem Icon={Clock} text={lastVisitedText} />
        </Stack>
      </Stack>

      <Stack padding={theme.spacing(5, 0)} bgcolor="#F6F7F8" borderRadius={theme.radius.large}>
        <SummaryTaskItem
          status={intakeFlowJson?.password_created_date ? "completed" : "in-progress"}
          title="Password Creation"
          completedDate={intakeFlowJson?.password_created_date}
        />
        <SummaryTaskItem
          status={isConsentFormsCompleted ? "completed" : "in-progress"}
          title="Consent Forms"
          completedDate={intakeFlowJson?.forms_completed_date}
        />
        <SummaryTaskItem
          title="Insurance Card"
          status={intakeFlowJson?.insuranceCard?.isComplete ? "completed" : "in-progress"}
          completedDate={intakeFlowJson?.insuranceCard?.completedDate}
          onClick={hasStartedIntake && (() => setCurrentStage(STAGES.insuranceCard.name))}
        />
        <SummaryTaskItem
          title="Pediatrician"
          status={intakeFlowJson?.pediatrician?.isComplete ? "completed" : "in-progress"}
          completedDate={intakeFlowJson?.pediatrician?.completedDate}
          onClick={hasStartedIntake && (() => setCurrentStage(STAGES.pediatrician.name))}
        />
        <SummaryTaskItem
          title="Diagnosis Report"
          status={intakeFlowJson?.diagnosisReport?.isComplete ? "completed" : "in-progress"}
          completedDate={intakeFlowJson?.diagnosisReport?.completedDate}
          onClick={hasStartedIntake && (() => setCurrentStage(STAGES.diagnosisReport.name))}
        />
        <SummaryTaskItem
          title="Diagnosing Doctor"
          status={intakeFlowJson?.diagnosingDoctor?.isComplete ? "completed" : "in-progress"}
          completedDate={intakeFlowJson?.diagnosingDoctor?.completedDate}
          onClick={hasStartedIntake && (() => setCurrentStage(STAGES.diagnosingDoctor.name))}
        />
      </Stack>

      <Stack gap={3}>
        {isReadyToComplete ? (
          <LoadingButton
            endIcon={<ArrowRight />}
            loading={isIntakeMutationLoading}
            onClick={() => {
              completeIntakeMutation(clientId);
            }}
          >
            Complete Intake
          </LoadingButton>
        ) : (
          <LoadingButton
            endIcon={hasStartedIntake && <ArrowRight />}
            onClick={hasStartedIntake ? onContinueIntake : startIntake}
            loading={startIntakeLoading}
          >
            {hasStartedIntake ? "Continue" : "Send Invite and Start"} Intake
          </LoadingButton>
        )}

        <Button onClick={onClose} variant="text">
          Exit Intake
        </Button>
      </Stack>
    </Stack>
  );
}
