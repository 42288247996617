import { getUsersPage, getUsersPerPage, getUsersSort, getUsersSortDirection } from "../selectors";
import { select } from "redux-saga/effects";
import SecureRequest from "../utils/securerequest";
/** @typedef {import("api/typesDef").User} User */

export function* getUserList() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const page = yield select(getUsersPage);
  const size = yield select(getUsersPerPage);
  const sort = yield select(getUsersSort);
  const direction = yield select(getUsersSortDirection);
  const params = {
    page,
    size,
    sort,
    direction,
  };

  return yield Request.get(`/users`, { params });
}

export function* createUser(params) {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.post("/users/admin", params);
}

export function* getUserDeactivationReasons() {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return yield Request.get("users/deactivation/reasons");
}

export const searchUsers = async (userId, params) => {
  const url = `/v2/users/${userId}/search-users`;

  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return await Request.get(url, { params });
};

/**
 * This function retrieves user data for a specific user ID.
 * @param {number|string} userId - The `userId` parameter is the unique identifier of
 * the user whose information you want to retrieve.
 * @returns {Promise<{data: User}>} The result of a GET request to the endpoint `/v3/users/[userId]` .
 */
export const getUser = async (userId) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(`/v3/users/${userId}`, {});
};

export const getUsers = async (params) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get("/v3/users", { params });
};
