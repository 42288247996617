import { useMutation } from "react-query";
import { createProvidersClinics, deleteProvidersClinics } from "../api/providersClinics";

export const useCreateProvidersClinics = (options) => {
  const mutation = useMutation(async (payloads) => {
    const results = [];
    for (const payload of payloads) {
      const result = await createProvidersClinics(payload);
      results.push(result);
    }
    return results;
  }, options);
  return mutation;
};

export const useDeleteProvidersClinics = (options) => {
  const mutation = useMutation(async (providersClinicsId) => {
    return await deleteProvidersClinics(providersClinicsId);
  }, options);
  return mutation;
};