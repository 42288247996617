import React from "react";
import { Box } from "@mui/material";

import { useIntake } from "components/SelfService/IntakeProvider";
import { STAGES } from "components/SelfService/utils";

import PhysicianSearchForm from "../Physician/PhysicianSearchForm";
import PhysicianForm from "../Physician/PhysicianForm";

export default function DiagnosisDoctor({ client }) {
  const { currentSubStage, intakeFlowJson, isSavingLoading, saveProgress, setCurrentStage } =
    useIntake();
  const { subStages } = STAGES.diagnosingDoctor;
  const { manualForm, isComplete } = intakeFlowJson?.diagnosingDoctor ?? {};

  const handleSaveManualForm = async (data) => {
    saveProgress({
      // Clear the search data
      clearData: {
        subStages: [subStages.search.name],
      },
      subStage: subStages.manual.name,
      subStageData: data,
      onSuccess: () => setCurrentStage(STAGES.summary.name),
      isStageCompleted: true,
    });
  };

  const handleSkip = () => {
    setCurrentStage(STAGES.summary.name);
  };

  const showPhysicianForm =
    (isComplete && manualForm?.data) || currentSubStage === subStages.manual.name;

  if (showPhysicianForm) {
    return (
      <Box paddingLeft={5}>
        <PhysicianForm
          clientName={client}
          data={manualForm?.data}
          isLoading={isSavingLoading}
          label="Diagnosing doctor"
          onContinueClick={handleSaveManualForm}
          onSkip={handleSkip}
        />
      </Box>
    );
  }

  return (
    <Box paddingLeft={5}>
      <PhysicianSearchForm
        clientName={client}
        label="Diagnosing Doctor"
        linkText="I don't see my Doctor"
        isLoading={isSavingLoading}
        onSkip={handleSkip}
        placeholder="Search by Doctor or Clinic"
        stage={STAGES.diagnosingDoctor}
        onSaveSuccess={() => setCurrentStage(STAGES.summary.name)}
      />
    </Box>
  );
}
