/**
 * @typedef {Object.<string, string|number|boolean>} GenericObject
 */

/**
 * The function `formatToPhoneNumber` takes a string input and formats it into a standard phone number
 * format `(XXX)-XXX-XXXX`. It removes any non-numeric characters from the input string
 * @param {string} value - The input value to be formatted as a phone number.
 * @returns {string} The input `value` formatted as a phone number.
 * @example
 * formatToPhoneNumber("1234567890"); // => "(123)-456-7890"
 */
function formatToPhoneNumber(value) {
  if (typeof value !== "string") {
    return value;
  }

  let numberValue = value.replace(/[^0-9]/g, "");

  if (numberValue.length > 10) {
    numberValue = numberValue.slice(0, 10);
  }

  let phone = numberValue.replace(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
    "($1)-$2-$3"
  );

  return phone;
}

/**
 * The function `getOnlyChangedValues` compares two Formik values objects and returns only the values that have
 * changed. If the value is a Date object, it is converted to an ISO string before comparison.
 * @param {GenericObject} originalValues - The `originalValues` parameter is an object containing the original values
 * of certain properties.
 * @param {GenericObject} updatedValues - An object containing updated values that may have changed from the original values.
 * @returns {GenericObject} An object containing only the key-value pairs from the `updated` object that have values different
 * from the corresponding values in the `originalValues` object.
 * @example
 * getOnlyChangedValues({a: 1, b: 2}, {a: 1, b: 3}); // => {b: 3}
 */
function getOnlyChangedValues(originalValues, updatedValues) {
  const changedValues = Object.entries(updatedValues).reduce((obj, [key, value]) => {
    // If the value is a date, convert it to an ISO string
    if (value instanceof Date) {
      value = value.toISOString();
    }

    if (value !== originalValues[key]) {
      obj[key] = value;
    }
    return obj;
  }, {});

  return changedValues;
}

export { formatToPhoneNumber, getOnlyChangedValues };
