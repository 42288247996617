import React, { useState } from "react";
import { Button, ListItemIcon, MenuItem, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import {
  ArrowArcRight,
  Buildings,
  IdentificationCard,
  MagnifyingGlass,
} from "@phosphor-icons/react";

import PhysicianHeader from "./PhysicianHeader";
import { useIntake } from "components/SelfService/IntakeProvider";
import useDebounce from "hooks/utils/useDebounce";
import { useSearchProviders } from "hooks/api/provider/useSearchProviders";

/**
 * The function `PhysicianSearchForm` is a React component that renders an autocomplete search form for
 * selecting physicians, with a link displayed below the form.
 * @category Component
 * @param {Object} props The props for this component.
 * @param {string} props.clientName The name of the client.
 * @param {boolean} props.isLoading Whether the form is in a loading state.
 * @param {string} props.label The label for the search form.
 * @param {string} props.linkText The text for the link displayed below the search form.
 * @param {Function} props.onContinueClick The function to call when the continue button is clicked.
 * @param {Function} props.onSkip The function to call when the skip button is clicked.
 * @param {string} props.placeholder The placeholder text for the search form.
 * @param {Object} props.stage The stage from STAGES object of "components/SelfService/utils".
 * @param {Function} props.onSaveSuccess The function to call when the form is saved successfully.
 * @returns {React.Component} The `PhysicianSearchForm` component is being returned.
 */
export default function PhysicianSearchForm({
  clientName,
  isLoading,
  label,
  linkText,
  onSkip,
  placeholder,
  stage,
  onSaveSuccess,
}) {
  const { subStages } = stage;
  const { goBack, intakeFlowJson, setCurrentSubStage, saveProgress } = useIntake();
  const { searchForm } = intakeFlowJson?.[stage.name] ?? {};
  const [selectedPhysician, setSelectedPhysician] = useState(searchForm?.data || null);
  const [providerInputValue, setProviderInputValue] = useState("");
  const debouncedProviderInputValue = useDebounce(providerInputValue, 500);
  const { providers } = useSearchProviders({ inputValue: debouncedProviderInputValue });
  const providersOptions = React.useMemo(() => {
    if (!providers) return [];

    const newArray = [];
    providers.forEach((provider, index) => {
      const isLastInGroup = providers[index + 1]?.clinic_id !== provider.clinic_id;
      newArray.push(provider);
      if (isLastInGroup && provider.clinic_id) {
        newArray.push({
          clinic_id: provider.clinic_id,
          clinic_name: provider.clinic_name,
          physician_id: null,
        });
      }
    });

    return newArray;
  }, [providers]);

  const handleSaveSearchForm = () => {
    saveProgress({
      subStage: subStages.search.name,
      subStageData: selectedPhysician,
      onSuccess: onSaveSuccess,
      isStageCompleted: true,
    });
  };

  return (
    <>
      <Stack marginBottom={7}>
        <PhysicianHeader clientName={clientName} physicianType={label} />
      </Stack>

      <Stack marginBottom={7} spacing={3}>
        <Autocomplete
          slotProps={{
            popper: { sx: { "& .MuiAutocomplete-listbox": { py: 4 } } },
            paper: { elevation: 8 },
          }}
          forcePopupIcon={false}
          options={providersOptions}
          filterOptions={(x) => x}
          onChange={(_, newValue) => setSelectedPhysician(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={label}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: params.InputProps.endAdornment ?? (
                  <MagnifyingGlass size={20} weight="regular" />
                ),
              }}
            />
          )}
          getOptionLabel={(option) =>
            option.physician_id ? `${option.first_name} ${option.last_name}` : option.clinic_name
          }
          getOptionKey={(option) => option?.clientId || option?.userId}
          isOptionEqualToValue={(option, value) =>
            option.physician_id === value.physician_id && option.clinic_id === value.clinic_id
          }
          inputValue={providerInputValue}
          onInputChange={(_, newValue) => setProviderInputValue(newValue)}
          value={selectedPhysician}
          renderOption={(props, option) => {
            const Icon = option.physician_id ? IdentificationCard : Buildings;
            const name = option.physician_id
              ? `${option.first_name} ${option.last_name}`
              : "Use Clinic Only";
            return (
              <MenuItem {...props} style={{ paddingLeft: option.clinic_id ? "44px" : "16px" }}>
                <ListItemIcon>
                  <Icon weight="duotone" />
                </ListItemIcon>
                {name}
              </MenuItem>
            );
          }}
          groupBy={(option) => option.clinic_name}
          renderGroup={(params) => {
            if (!params.group) return params.children;
            return (
              <Stack key={params.key}>
                <Typography py={3} pl={5} variant="body2" color="text.secondary">
                  {params.group}
                </Typography>
                {params.children}
              </Stack>
            );
          }}
        />

        <aside>
          <Link
            component="button"
            underline="hover"
            variant="body2"
            color="primary"
            align="left"
            onClick={() => setCurrentSubStage(subStages.manual.name)}
            type="button"
          >
            {linkText}
          </Link>
        </aside>
      </Stack>

      <Stack spacing={3}>
        <LoadingButton
          size="large"
          onClick={handleSaveSearchForm}
          disabled={!selectedPhysician}
          loading={isLoading}
        >
          Continue
        </LoadingButton>

        {onSkip && (
          <Button size="large" color="secondary" endIcon={<ArrowArcRight />} onClick={onSkip}>
            Skip
          </Button>
        )}

        <Button variant="text" onClick={goBack} size="large">
          Back
        </Button>
      </Stack>
    </>
  );
}
