import { useMutation } from "react-query";

import { createFinancialGuarantor, updateFinancialGuarantor } from "api/customers";
import { useGlobalToast } from "components/GlobalToastProvider";

function financialGuarantorAPI(payload) {
  const { isCreating, ...values } = payload;

  if (isCreating) {
    return createFinancialGuarantor(values);
  }

  return updateFinancialGuarantor(values);
}

const useUpdateGuarantor = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, isIdle, isLoading, ...rest } = useMutation(
    (payload) => financialGuarantorAPI(payload),
    {
      onError: () => {
        showToast({
          message: "Whoops! Something went wrong while updating the guarantor.",
          errorState: true,
        });
        onError();
      },
      ...otherOptions,
    }
  );

  return {
    updateGuarantorMutation: mutate,
    isMutationGuarantorIdle: isIdle,
    isMutationGuarantorLoading: isLoading,
    ...rest,
  };
};

export default useUpdateGuarantor;
