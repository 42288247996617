import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, IconButton, Link, Stack, Chip, Menu, MenuItem, ListItemIcon } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  Calendar,
  Files,
  UploadSimple,
  UserCircle,
  Pulse,
  DotsThree,
  SealCheck,
  PencilSimpleLine,
} from "@phosphor-icons/react";
import UploadDocumentDialog from "../../Dialogs/UploadDocumentDialog";
import { useGetClientDocuments } from "hooks/useGetClientDocuments";
import { format } from "date-fns";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import actions from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { unverifyClientDocument, updateDocument, verifyClientDocument } from "api/customers";
import NoDocumentsWithPermissionOverlay from "elements/NoDocumentsWithPermissionOverlay";
import ANNoDataOverlay from "elements/ANNoDataOverlay";
import ANNoResultsOverlay from "elements/ANNoResultsOverlay";
import * as selectors from "selectors";
import ANTableFilters from "elements/ANTableFilters";
import noDocumentSvg from "../../../../assets/no_document.svg";
import { v4 as uuidv4 } from "uuid";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";
import { useGlobalToast } from "components/GlobalToastProvider";

const Documents = (props) => {
  const { customerDetails, currentUser, userPermissions, documentTypes, clientId } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false);
  const [isNewDocument, setIsNewDocument] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [orderBy, setOrderBy] = useState("uploaded-date-desc");
  const [typeFilter, setTypeFilter] = useState([]);
  const [uploadedByFilter, setUploadedByFilter] = useState([]);
  const [uploadedDateFilter, setUploadedDateFilter] = useState([null, null]);
  const [dropzoneFiles, setDropzoneFiles] = useState([]);
  const [alreadyUploadedFiles, setAlreadyUploadedFiles] = useState([]);
  const [uploadDocType, setUploadDocType] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const uploadDocumentLoading = useSelector(selectors.getuploadDocumentLoading);
  const userId = useSelector(selectors.getUserId);
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = useState(null);

  const { showToast } = useGlobalToast();

  const { mutate: updateClientDocument, isLoading: isLoadingDocumentUpdate } = useMutation(
    (documentData) => updateDocument(clientId, documentData),
    {
      onSuccess: () => updateTable(),
      onError: (error) => {
        console.error("Error updating document:", error);
      },
    }
  );

  const { mutate: verifyDocument, isLoading: verifyIsLoading } = useMutation(
    (documentId) => verifyClientDocument(documentId),
    {
      onSuccess: () => updateTable(),
      onError: (error, documentId) => {
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => verifyDocument(documentId),
        }),
          console.error("Error verifying document:", error);
      },
    }
  );

  const { mutate: unverifyDocument, isLoading: unverifyIsLoading } = useMutation(
    (documentId) => unverifyClientDocument(documentId),
    {
      onSuccess: () => updateTable(),
      onError: (error, documentId) => {
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => unverifyDocument(documentId),
        }),
          console.error("Error unverifying document:", error);
      },
    }
  );

  useEffect(() => {
    if (!uploadDocumentLoading) updateTable();
  }, [uploadDocumentLoading]);

  const viewMode = userPermissions["view_client_dashboard"];
  const orderByMap = {
    "uploaded-date-asc": { sort: "created", direction: "asc" },
    "uploaded-date-desc": { sort: "created", direction: "desc" },
    type: { sort: "display_name", direction: "asc" },
    "uploaded-by": { sort: "uploaded_by", direction: "asc" },
  };
  // using isFetching instead of isLoading, since keepPreviousData (avoids showing `undefined` in chips)
  // is set to true in useGetClientDocuments and isLoading will be false when
  // the previous data is being used
  const {
    isFetching,
    data: response,
    refetch: updateTable,
  } = useGetClientDocuments(clientId, {
    sort: orderByMap[orderBy]?.sort,
    direction: orderByMap[orderBy]?.direction,
    page: paginationModel.page,
    size: paginationModel.pageSize,
    typeList: !typeFilter.length ? null : typeFilter.join(","),
    uploadedByList: uploadedByFilter.join(","),
    startDate: uploadedDateFilter?.[0],
    endDate: uploadedDateFilter?.[1],
    status: statusFilter.join(","),
  });

  const debounce = (fn, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => fn(...args), delay);
    };
  };

  const debouncedUpdateTable = debounce(updateTable, 300);

  // If new filter is added make sure to add it to the prevFiltersRef
  const prevFiltersRef = useRef({
    typeFilter: typeFilter,
    uploadedByFilter: uploadedByFilter,
    uploadedDateFilter: uploadedDateFilter,
    statusFilter: statusFilter,
  });

  // If new filter is added make sure to add it this useEffect
  useEffect(() => {
    const prevFilters = prevFiltersRef.current;

    if (
      !arraysEqual(prevFilters.typeFilter, typeFilter) ||
      !arraysEqual(prevFilters.uploadedByFilter, uploadedByFilter) ||
      !arraysEqual(prevFilters.uploadedDateFilter, uploadedDateFilter) ||
      !arraysEqual(prevFilters.statusFilter, statusFilter)
    ) {
      setPaginationModel((prevPaginationModel) => ({
        ...prevPaginationModel,
        page: 0,
      }));
    }
    prevFiltersRef.current = {
      typeFilter: typeFilter,
      uploadedByFilter: uploadedByFilter,
      uploadedDateFilter: uploadedDateFilter,
      statusFilter: statusFilter,
    };

    debouncedUpdateTable();
  }, [typeFilter, uploadedByFilter, uploadedDateFilter, statusFilter]);

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const documents = response?.data.documents;
  const rowCount = response?.data.count;
  const typeCount = response?.data.type_count;
  const uploadedByCount = response?.data.uploaded_by_count;
  const statusCount = response?.data.status_count;

  const handleFileUpload = (file) => {
    setDropzoneFiles([
      { errors: !!file.fileRejections?.[0], file: file.acceptedFiles[0] || file.fileRejections[0] },
    ]);
    if (!uploadDocumentOpen) setUploadDocumentOpen(true);
  };

  const handleFileDelete = (file) => {
    setDropzoneFiles(dropzoneFiles.filter((dropzoneFile) => dropzoneFile !== file));
  };

  const handleLoadedFileDelete = (file) => {
    setAlreadyUploadedFiles(alreadyUploadedFiles.filter((uploadedFile) => uploadedFile !== file));
  };

  const handleOptionsMenuOpen = (event) => {
    setOptionsMenuAnchorEl(event.currentTarget);
  };

  const handleOptionsMenuClose = () => {
    setSelectedRow(null);
    setOptionsMenuAnchorEl(null);
  };

  const isOptionsMenuOpen = Boolean(optionsMenuAnchorEl);

  const handleVerifyDocument = () => {
    setOptionsMenuAnchorEl(null);
    verifyDocument(selectedRow.id);
  };

  const handleUnverifyDocument = () => {
    setOptionsMenuAnchorEl(null);
    unverifyDocument(selectedRow.id);
  };

  const handleEdit = () => {
    const { row } = selectedRow;
    setUploadDocumentOpen(true);
    setIsNewDocument(false);
    setAlreadyUploadedFiles([{ name: row.name, key: row.name, size: row.file_size ?? 0 }]);
    setUploadDocType(documentTypes.find((docType) => docType.display_name === row.type).name);
    setOptionsMenuAnchorEl(null);
  };

  const submitDocumentUpload = async (uploadDocType) => {
    const uploadDoc = dropzoneFiles[0]?.file;
    const { details } = customerDetails;
    // if there is a new file or updated file to upload
    let upload;
    if (uploadDoc) {
      const credentials = await getAWSCredentialsForCurrentUserSession();
      const s3 = new AWS.S3({
        credentials,
        apiVersion: "2006-03-01",
        params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
      });
      const fileUUID = uuidv4();
      const [fileName, fileExtension] = uploadDoc.name.split(".");
      const params = {
        ACL: "authenticated-read",
        Body: uploadDoc,
        ContentType: uploadDoc.type,
        Key: isNewDocument
          ? `${details.cognito_id}/${fileName}-${fileUUID}.${fileExtension}`
          : selectedRow?.row?.key,
      };
      upload = await s3.upload(params).promise();
    }
    if (isNewDocument) {
      const document = {
        filename: upload.Key.split("/")[1],
        key: upload.Key,
        display_filename: uploadDoc.name,
        clientId: clientId,
        type: uploadDocType,
        uploaded_by: currentUser.id,
        file_size: uploadDoc.size,
        clinicianUserId: currentUser.id, // Needed for the saga to update treatment plan
      };
      dispatch(actions.uploadCustomerDocument(document));
    } else {
      const documentData = {
        id: selectedRow.id,
        type: uploadDocType,
        filename: uploadDoc ? upload.Key.split("/")[1] : selectedRow?.row?.name,
        display_filename: uploadDoc ? uploadDoc.name : selectedRow?.row?.name,
        file_size: uploadDoc ? uploadDoc.size : selectedRow?.row?.file_size,
        uploaded_by: uploadDoc ? currentUser.id : null, // we want to change uploaded_by only if the document is being updated
      };
      updateClientDocument(documentData);
    }
  };

  const generateRows = () => {
    if (!documents) return [];
    return documents.map((document) => ({
      id: document.id,
      name: document.display_filename || document.filename,
      type: document.display_name,
      "uploaded-by": document.uploaded_by,
      "uploaded-date": document.created,
      verified: document.verified,
      edit: "Edit",
      file_size: document.file_size,
      key: document.key,
    }));
  };
  const rows = generateRows();

  const getColumnDefinitions = (handleOpenFile) => {
    const DateValueGetter = ({ value }) => (value ? format(new Date(value), "P") : null);
    const defaultColAttrs = {
      sortable: false,
      flex: 1,
    };
    const columns = [
      {
        field: "name",
        headerName: "Name",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <Link
              color="primary"
              variant="body2"
              underline="hover"
              onClick={() => handleOpenFile(params)}
              sx={{ "&:hover": { cursor: "pointer" } }}
            >
              {params.value}
            </Link>
          );
        },
      },
      { field: "type", headerName: "Type", ...defaultColAttrs },
      { field: "uploaded-by", headerName: "Uploaded By", ...defaultColAttrs },
      {
        field: "uploaded-date",
        headerName: "Uploaded Date",
        align: "left",
        headerAlign: "left",
        valueGetter: DateValueGetter,
        ...defaultColAttrs,
      },
      {
        field: "verified",
        headerName: "Status",
        ...defaultColAttrs,
        renderCell: (params) => {
          const isVerified = params.value;
          return isVerified ? (
            <Chip label="Verified" variant="outlined" size="small" color="success" />
          ) : (
            <Chip label="Unverified" variant="outlined" size="small" color="default" />
          );
        },
      },
    ];
    if (!viewMode) {
      columns.push({
        field: "edit",
        headerName: "More",
        align: "left",
        headerAlign: "left",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <IconButton
              size="medium"
              onClick={(e) => {
                setSelectedRow(params);
                handleOptionsMenuOpen(e);
              }}
            >
              <DotsThree />
            </IconButton>
          );
        },
      });
    }
    return columns;
  };

  const orderingMenuItems = [
    {
      label: "Type",
      value: "type",
      icon: <Files weight="duotone" />,
    },
    { label: "Uploaded By", value: "uploaded-by", icon: <UserCircle weight="duotone" /> },
    {
      label: "Uploaded Date",
      value: "uploaded-date-asc",
      icon: <Calendar weight="duotone" />,
      caption: "Ascending",
    },
    {
      label: "Uploaded Date",
      value: "uploaded-date-desc",
      icon: <Calendar weight="duotone" />,
      caption: "Descending",
    },
  ];
  const filtersMenuItems = [
    {
      label: "Type",
      value: "type",
      icon: <Files weight="duotone" />,
      countObject: typeCount,
      state: typeFilter,
      setState: setTypeFilter,
    },
    {
      label: "Uploaded Date",
      value: "uploaded-date",
      icon: <Calendar weight="duotone" />,
      customComponent: (
        <StaticDateRangePicker
          value={uploadedDateFilter}
          onChange={(date) => setUploadedDateFilter(date)}
          slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
          calendars={2}
        />
      ),
      filterChipProps: {
        label: `Uploaded Date is : ${format(new Date(uploadedDateFilter[0]), "MM/dd/yy")} 
          ${
            uploadedDateFilter[1] ? ` - ${format(new Date(uploadedDateFilter[1]), "MM/dd/yy")}` : ""
          }`,
        onDelete: () => setUploadedDateFilter([null, null]),
      },
      showChipConditionCustomComponent: !!uploadedDateFilter[0],
    },
    {
      label: "Uploaded By",
      value: "uploaded-by",
      icon: <UserCircle weight="duotone" />,
      countObject: uploadedByCount,
      state: uploadedByFilter,
      setState: setUploadedByFilter,
    },
    {
      label: "Status",
      value: "verified",
      icon: <Pulse weight="duotone" />,
      countObject: statusCount,
      state: statusFilter,
      setState: setStatusFilter,
    },
  ];

  const handleClearFilters = () => {
    setTypeFilter([]);
    setUploadedByFilter([]);
    setUploadedDateFilter([null, null]);
  };

  const customNoRowsOverlay = {
    NoDocuments: {
      component: ANNoDataOverlay,
      height: "296px",
      props: {
        imgSrc: noDocumentSvg,
        title: "No documents yet",
        description: "Client documents will display here once uploaded to the account",
      },
    },
    NoDocumentsWithPermission: {
      component: NoDocumentsWithPermissionOverlay,
      height: "296px",
      props: { handleFileUpload },
    },
    NoResults: {
      component: ANNoResultsOverlay,
      height: "236px",
      props: { handleClearFilters },
    },
  };
  const overlayName =
    !typeFilter.length &&
    !uploadedByFilter.length &&
    !uploadedDateFilter[0] &&
    !uploadedDateFilter[1]
      ? viewMode
        ? "NoDocuments"
        : "NoDocumentsWithPermission"
      : "NoResults";

  return (
    <>
      <Stack
        direction="row"
        padding={theme.spacing(5)}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <ANTableFilters
          orderingMenuItems={orderingMenuItems}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filtersMenuItems={filtersMenuItems}
          filtersButtonProps={{ disabled: documents?.length === 0 }}
        />
        {!viewMode && (
          <Button
            color="secondary"
            startIcon={<UploadSimple />}
            size="small"
            onClick={() => setUploadDocumentOpen(true)}
            sx={{ minWidth: "fit-content" }}
          >
            Upload Document
          </Button>
        )}
      </Stack>
      <DataGrid
        rows={rows}
        loading={
          (isFetching && !documents) ||
          isLoadingDocumentUpdate ||
          uploadDocumentLoading ||
          verifyIsLoading ||
          unverifyIsLoading
        }
        pageSizeOptions={[5, 10, 20]}
        columns={getColumnDefinitions(({ row }) => {
          const url = `/document-viewer/${userId}/${row.id}`;
          window.open(url, "_blank", "noopener,noreferrer");
        })}
        disableRowSelectionOnClick
        autoHeight
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          noRowsOverlay: customNoRowsOverlay[overlayName].component,
        }}
        slotProps={{ noRowsOverlay: { ...customNoRowsOverlay[overlayName].props } }}
        sx={{ "--DataGrid-overlayHeight": customNoRowsOverlay[overlayName].height }}
        hideFooter={!rowCount}
        paginationMode="server"
        rowCount={rowCount}
      />
      <UploadDocumentDialog
        open={uploadDocumentOpen}
        handleClose={() => {
          setUploadDocumentOpen(false);
          setSelectedRow(null);
          setTimeout(() => {
            setIsNewDocument(true);
            setAlreadyUploadedFiles([]);
            setDropzoneFiles([]);
            setUploadDocType("");
          }, 250); // Timeout avoids flickering
        }}
        handleFileUpload={handleFileUpload}
        dropzoneFiles={dropzoneFiles}
        handleFileDelete={handleFileDelete}
        handleLoadedFileDelete={handleLoadedFileDelete}
        submitDocumentUpload={submitDocumentUpload}
        isNewDocument={isNewDocument}
        alreadyUploadedFiles={alreadyUploadedFiles}
        uploadDocType={uploadDocType}
        setUploadDocType={setUploadDocType}
      />
      <Menu
        anchorEl={optionsMenuAnchorEl}
        open={isOptionsMenuOpen}
        onClose={handleOptionsMenuClose}
      >
        {selectedRow?.row?.verified ? (
          <MenuItem
            key={`${selectedRow?.id}_verify_document`}
            disabled={!userPermissions.verify_client_document}
            onClick={handleUnverifyDocument}
          >
            <ListItemIcon>{<SealCheck weight="duotone" />}</ListItemIcon>
            Cancel Verification
          </MenuItem>
        ) : (
          <MenuItem
            key={`${selectedRow?.id}_verify_document`}
            disabled={!userPermissions.verify_client_document}
            onClick={handleVerifyDocument}
          >
            <ListItemIcon>{<SealCheck weight="duotone" />}</ListItemIcon>
            Verify Document
          </MenuItem>
        )}
        <MenuItem key={`${selectedRow?.id}_handle_edit`} onClick={handleEdit}>
          <ListItemIcon>{<PencilSimpleLine weight="duotone" />}</ListItemIcon>
          Edit
        </MenuItem>
      </Menu>
    </>
  );
};

export default Documents;
