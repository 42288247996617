import React from "react";

import useGetFinancialGuarantor from "hooks/api/client/useGetFinancialGuarantor";
import { useGetTeams } from "hooks/api/useGetTeams";
import { useGetTeamMembers } from "hooks/api/useGetTeamMembers";
import SectionBox from "elements/SectionBox";
import FinancialGuarantorView from "./GuarantorView";
import FinancialGuarantorForm from "./GuarantorForm";

/**
 * The FinancialGuarantor component manages the financial guarantor information for a client, allowing
 * for editing and viewing modes of the guarantor information.
 * @component
 * @param {Object} props The props of the component.
 * @param {boolean} props.editMode The edit mode of the component.
 * @param {string} props.clientId The client ID.
 * @param {Function} props.handleUpdateGuarantor The function to call when the guarantor is updated.
 * @param {{current: Object}} props.formRef The Formik reference for the guarantor form.
 * @param {string} props.formId The form ID for the guarantor form.
 * @returns {JSX.Element} Depending on the value of the `editMode` prop, it will conditionally render
 * either the `Form` component (if in edit mode) or the `View` component (if not in edit mode).
 * The FinancialGuarantor component includes a checkbox to toggle between using the primary insurance
 * subscriber as the financial guarantor or entering.
 */
const FinancialGuarantor = ({ editMode, clientId, handleUpdateGuarantor, formRef, formId }) => {
  // Fetch the current financial guarantor for the client if there is one
  const { guarantor } = useGetFinancialGuarantor(clientId);
  let currentGuarantor = guarantor;

  // Fetch the team members for the client for the team members dropdown
  const { teams } = useGetTeams({ clientIds: clientId });
  const teamId = teams?.[0]?.id;
  const { teamMembers } = useGetTeamMembers({ teamIds: teamId, includeTeamInvitees: false });

  const teamMembersList = teamMembers
    ?.filter((member) => member.hasAcceptedInvite)
    .map((member) => ({
      value: member.userId,
      label: `${member.firstName} ${member.lastName}`,
    }));

  return (
    <SectionBox
      title="Financial Guarantor"
      description="The party that provides financial backing to ensure that claims will be paid"
    >
      {editMode ? (
        <FinancialGuarantorForm
          ref={formRef}
          id={formId}
          clientId={clientId}
          guarantor={currentGuarantor}
          onSubmit={handleUpdateGuarantor}
          teamMembersList={teamMembersList}
        />
      ) : (
        <FinancialGuarantorView guarantor={guarantor} />
      )}
    </SectionBox>
  );
};

export default FinancialGuarantor;
