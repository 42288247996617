import React, { forwardRef } from "react";
import { Box, IconButton, ListItemIcon, Menu, Slide, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import Button from "@mui/material/Button";
import { BookOpenText, PencilSimpleLine, SealCheck, X } from "@phosphor-icons/react";
import { LoadingButton } from "@mui/lab";
import MenuItem from "@mui/material/MenuItem";

import ANFormView from "./ANFormView";
import AnFormEdit from "./ANFormEdit";
import ANTooltip from "../ANTooltip";

const AnDetailsPanel = forwardRef(function AnDetailsPanel(
  {
    classes,
    open,
    setOpen,
    editMode,
    titlePanel,
    data = [],
    config = [],
    resetData,
    inputsForm,
    isLoading,
    onSave,
    onCancel,
    disableSaveButton,
    saveTitle,
    icon,
    topEditEnable,
    topEditActions,
  },
  ref
) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEditMenu, setOpenEditMenu] = React.useState(null);

  const handleOptionsMenuOpen = (event) => {
    setOpenEditMenu(true);
    setAnchorEl(event.currentTarget);
  };
  let menus = [];
  if (topEditActions) {
    topEditActions.forEach((item) => {
      menus.push(
        <MenuItem
          onClick={() => {
            setOpenEditMenu(false);
            setAnchorEl(null);
            item.action();
          }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          {item.text}
        </MenuItem>
      );
    });
  }
  return (
    <Slide
      ref={ref}
      direction="left"
      in={open}
      sx={{
        width: { xs: "100%", md: "75%", lg: "50%" },
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
      mountOnEnter
      unmountOnExit
    >
      <Stack
        sx={{ borderLeft: `1px solid ${theme.palette.divider}`, overflowY: "auto" }}
        gap={theme.spacing(3)}
        style={{
          padding: theme.spacing(7),
        }}
        width="400px"
        minWidth="400px"
      >
        <Box display={"inline-flex"} flexDirection={"row"}>
          <Box
            display={"inline-flex"}
            flexDirection={"row"}
            width={titlePanel && titlePanel.length > 15 ? "85%" : "100%"}
            style={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(3),
              flex: "1 0 0",
            }}
          >
            {titlePanel && (
              <>
                {icon && icon === "book" && (
                  <BookOpenText
                    fontSize={"20px"}
                    weight="duotone"
                    color={theme.palette.primary.main}
                  ></BookOpenText>
                )}
                {!icon && (
                  <SealCheck
                    fontSize={"20px"}
                    weight="duotone"
                    color={theme.palette.primary.main}
                  ></SealCheck>
                )}
                <ANTooltip
                  component={Typography}
                  variant={"subtitle1"}
                  style={{ maxWidth: "90%", display: "block" }}
                >
                  {titlePanel}
                </ANTooltip>
              </>
            )}
          </Box>
          {topEditEnable && menus && (
            <>
              <IconButton id="open-menu-button" fontSize="small" onClick={handleOptionsMenuOpen}>
                <PencilSimpleLine weight="duotone" />
              </IconButton>
              <Menu
                MenuListProps={{
                  "aria-labelledby": "open-menu-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorEl={anchorEl}
                open={openEditMenu}
                id="basic-menu"
                onClose={() => {
                  setAnchorEl(null);
                  setOpenEditMenu(false);
                }}
                slotProps={{
                  paper: {
                    style: {
                      maxHeight: undefined,
                    },
                  },
                }}
              >
                {menus}
              </Menu>
            </>
          )}
          {!editMode && (
            <IconButton
              fontSize="small"
              onClick={() => {
                setOpen(!open);
                resetData();
              }}
            >
              <X />
            </IconButton>
          )}
        </Box>
        {editMode ? (
          <AnFormEdit inputsForm={inputsForm} classes={classes} />
        ) : (
          <ANFormView data={data} config={config} panelMode />
        )}
        {editMode && (
          <Stack
            gap={theme.spacing(3)}
            style={{
              paddingTop: theme.spacing(7),
            }}
          >
            {onSave && (
              <LoadingButton
                disabled={disableSaveButton}
                onClick={onSave}
                className={classes.button}
                color="primary"
                loading={isLoading}
              >
                {saveTitle}
              </LoadingButton>
            )}
            {onCancel && (
              <Button
                variant="text"
                color={"primary"}
                size="medium"
                onClick={onCancel}
                style={{ minWidth: 0 }}
              >
                Cancel
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Slide>
  );
});

export default AnDetailsPanel;
