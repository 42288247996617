import {  GET_PROVIDERS } from "constants/reactQueryKeys";
import { useMutation, useQuery } from "react-query";
import { createProvider, getAllProviders, getProviderById, updateProvider } from "../api/provider";


export const useGetProviders = (params, { onSuccess, } ) => {
  return useQuery([GET_PROVIDERS, params], () => getAllProviders(params), {
    keepPreviousData: true,
    onSuccess,
  });
};

export const useGetProviderById = (options) => {
  const mutation = useMutation(async (params) => {
    return await getProviderById(params.id);
  }, options);
  return mutation;
};

export const useCreateProvider = (options) => {
  const mutation = useMutation(async (payload) => {
    return await createProvider(payload);
  }, options);
  return mutation;
};

export const useUpdateProvider = (options) => {
  const mutation = useMutation(async (payload) => {
    delete payload?.physician_clinic;
    return await updateProvider(payload.id, payload);
  }, options);
  return mutation;
};
