import React from "react";
import Typography from "@mui/material/Typography";

import InsuranceDetailsEdit from "./InsuranceDetailsEdit";
import { dateWithoutTimezone } from "utils/dateWithoutTimezone";
import { booleanToDisplay } from "utils/displayUtils";
import InsuranceDetailsView from "./InsuranceDetailsView";

const getInitialState = () => {
  return {
    id: 0,
    insuranceName: "",
    planName: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    expirationDate: "",
    isPrimary: false,
    isMedicaid: false,
    // Keep them as long as they are in the database ---------------->
    insuranceHolderName: "",
    insuranceHolderDOB: "",
    insurance_holder_relationship: "",
    // <-----------------------------------------------------
  };
};

const getInsuranceValues = (insurance, isViewMode = false) => {
  return {
    id: insurance.id,
    insuranceName: insurance.insurance_name,
    planName: insurance.insurance_plan_name,
    memberId: insurance.member_id,
    groupNumber: insurance.group_number,
    // Keep them as long as they are in the database ---------------->
    insuranceHolderName: insurance.insurance_holder_name,
    insuranceHolderDOB: insurance.insurance_holder_dob,
    insurance_holder_relationship: insurance.insurance_holder_relationship,
    // <-----------------------------------------------------
    effectiveDate: insurance.effective_date && dateWithoutTimezone(insurance.effective_date),
    expirationDate: insurance.expiration_date && dateWithoutTimezone(insurance.expiration_date),
    isPrimary: isViewMode ? booleanToDisplay(insurance.is_primary) : insurance.is_primary,
    isMedicaid: isViewMode ? booleanToDisplay(insurance.is_medicaid) : insurance.is_medicaid,
    // Subscriber fields
    subscriberFirstName: insurance?.subscriber_first_name,
    subscriberLastName: insurance?.subscriber_last_name,
    subscriberEmail: insurance?.subscriber_email,
    subscriberDOB: insurance.subscriber_dob,
    subscriberGender: insurance?.subscriber_gender || "",
    subscriberRelationship: insurance?.subscriber_relationship,
    subscriberPhone: insurance?.subscriber_phone,
    subscriberCity: insurance?.subscriber_city,
    subscriberCountry: insurance?.subscriber_country,
    subscriberState: insurance?.subscriber_state,
    subscriberZip: insurance?.subscriber_zip,
    subscriberAddress: insurance?.subscriber_address,
    subscriberAddress2: insurance?.subscriber_address2,
  };
};

const InsuranceDetails = (props) => {
  const {
    insurance,
    privatePayClient,
    insuranceProvidersList,
    classes,
    clientId,
    userId,
    primaryInsuranceId,
    setPrimaryInsuranceId,
    getUserInsurance,
    isEditing,
    handleUpdateInsurance,
    formRef,
  } = props;

  const insuranceValues = insurance ? getInsuranceValues(insurance) : getInitialState();
  const formId = `insurance-${insuranceValues.id}`;

  return isEditing ? (
    <InsuranceDetailsEdit
      formId={formId}
      insurance={insuranceValues}
      clientId={clientId}
      userId={userId}
      privatePayClient={privatePayClient}
      insuranceProvidersList={insuranceProvidersList}
      classes={classes}
      primaryInsuranceId={primaryInsuranceId}
      setPrimaryInsuranceId={setPrimaryInsuranceId}
      getUserInsurance={getUserInsurance}
      onSubmit={handleUpdateInsurance}
      ref={(ref) => (formRef.current[formId] = ref)}
    />
  ) : privatePayClient ? (
    <Typography component="p" className={classes.noInfoText}>
      Private-Pay client
    </Typography>
  ) : (
    <InsuranceDetailsView
      insurance={insurance ? getInsuranceValues(insurance, true) : getInitialState()}
    />
  );
};

export default InsuranceDetails;
