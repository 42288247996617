import { Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useIntake } from "components/SelfService/IntakeProvider";
import { STAGES } from "components/SelfService/utils";
import React from "react";
import InitialPage from "./InitialPage";
import DiagnosisUpload from "./DiagnosisUpload";

export default function DiagnosisReport(props) {
  const theme = useTheme();
  const { currentSubStage, setCurrentSubStage, setCurrentStage, saveProgress } = useIntake();
  const subStages = STAGES.diagnosisReport.subStages;

  const goToUploadDiagnosis = () => setCurrentSubStage(subStages.diagnosisReportUpload.name);
  const goToNextStage = () => setCurrentStage(STAGES.diagnosingDoctor.name);

  const subStageMap = {
    [subStages.haveDiagnosisReport.name]: (
      <InitialPage
        onSkip={goToUploadDiagnosis}
        onContinue={(data) => saveProgress({ subStageData: data, onSuccess: goToUploadDiagnosis })}
      />
    ),
    [subStages.diagnosisReportUpload.name]: (
      <DiagnosisUpload
        onSkip={goToNextStage}
        onContinue={(data) =>
          saveProgress({ subStageData: data, isStageCompleted: true, onSuccess: goToNextStage })
        }
      />
    ),
  };

  return (
    <Stack gap={theme.spacing(7)} {...props}>
      {subStageMap[currentSubStage] || subStageMap[subStages.haveDiagnosisReport.name]}
    </Stack>
  );
}
