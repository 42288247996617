export const ClinicConfig = [
  {
    id: "id",
    label: "Clinic Id",
    dataKey: "id",
    cols: 12,
    hide: true,
  },
  {
    id: "clinic_name",
    label: "Clinic Name",
    dataKey: "clinic_name",
    cols: 12,
    required: true,
    hide: true,
  },
  {
    id: "phone_number",
    label: "Phone Number",
    dataKey: "phone_number",
    cols: 12,
    required: true,
  },
  {
    id: "fax_number",
    label: "Fax Number",
    dataKey: "fax_number",
    catalog: true,
    cols: 12,
  },
  {
    id: "address",
    label: "Address",
    dataKey: "address",
    cols: 12,
    required: true,
  },
  {
    id: "address_2",
    label: "Address 2 (optional)",
    dataKey: "address_2",
    cols: 12,
  },
  {
    id: "city",
    label: "City",
    dataKey: "city",
    cols: 12,
    required: true,
  },
  {
    id: "state",
    label: "State",
    dataKey: "state",
    cols: 12,
    required: true,
  },
  {
    id: "zip_code",
    label: "ZIP Code",
    dataKey: "zip_code",
    cols: 12,
    required: true,
  },
  {
    id: "website",
    label: "Website (optional)",
    dataKey: "website",
    cols: 12,
  },
  {
    id: "clinics",
    label: "clinics",
    dataKey: "clinics",
    cols: 12,
  },
];
