import React, { createContext, useState, useContext, useEffect } from "react";

const ClientDetailsFormContext = createContext();

export const ClientDetailsFormStates = {
  initial: "initial",
  editing: "editing",
  canceling: "canceling",
  onsubmit: "onsubmit",
  loading: "loading",
  submitSuccess: "submitSuccess",
};

export const ClientDetailsFormProvider = ({ editMode, currentTab, children }) => {
  const [formState, setFormState] = useState("initial");

  useEffect(() => {
    if (editMode && formState === ClientDetailsFormStates.initial) {
      setFormState(ClientDetailsFormStates.editing);
    }
  }, [editMode]);

  useEffect(() => {
    if (currentTab) {
      setFormState(ClientDetailsFormStates.initial);
    }
  }, [currentTab]);

  // This is called by the save button, the child form will react to it and proceed to submit the data
  const onSubmit = () => {
    setFormState(ClientDetailsFormStates.onsubmit);
  };

  // Once the child form submits the data it calls this method to show loading icons on the save button for example
  const onSubmitting = () => {
    setFormState(ClientDetailsFormStates.loading);
  };

  // Once the child form submits the data with no error it calls this method
  const onSubmitSuccess = () => {
    // handle success globally
    // In case other parts of the tab/page need to react to this we can have a
    // onSubmitSuccess formState as well
    setFormState(ClientDetailsFormStates.submitSuccess);
  };

  // If the child form submits the data with error it calls this method
  const onSubmitError = () => {
    // handle error globally
    // In case other parts of the tab/page need to react to this we can have a
    // onSubmitError formState as well
    setFormState(ClientDetailsFormStates.editing);
  };

  const onEditForm = () => {
    setFormState(ClientDetailsFormStates.editing);
  };

  const onCancelEdit = () => {
    setFormState(ClientDetailsFormStates.canceling);
  };

  const resetState = () => {
    setFormState(ClientDetailsFormStates.initial);
  };

  return (
    <ClientDetailsFormContext.Provider
      value={{
        formState,
        loading: formState === "loading",
        onSubmitting,
        onSubmit,
        onSubmitSuccess,
        onSubmitError,
        onCancelEdit,
        onEditForm,
        resetState,
      }}
    >
      {children}
    </ClientDetailsFormContext.Provider>
  );
};

export const useClientDetailsForm = () => useContext(ClientDetailsFormContext);
