import React, { useEffect, useState, useCallback } from "react";
import withStyles from "@mui/styles/withStyles";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import SectionBox from "elements/SectionBox";
import styles from "../../styles";
import InsuranceDetails from "./InsuranceDetails";
import InsuranceDetailsEdit from "./InsuranceDetailsEdit";

const getInitialState = {
  id: 0,
  insuranceName: "",
  planName: "",
  memberId: "",
  groupNumber: "",
  effectiveDate: "",
  expirationDate: "",
  isPrimary: false,
  isMedicaid: false,
  // keep them as long as they are in the database ---------------->
  insuranceHolderName: "",
  insuranceHolderDOB: "",
  insurance_holder_relationship: "",
  // <---------------------
};

const Insurance = (props) => {
  const {
    userInsurance,
    getInsuranceProvidersList,
    getInsurancePlans,
    insuranceListProviderId,
    userPermissions,
    editDisabled,
    classes,
    editMode,
    clientId,
    userId,
    privatePayClient,
    insuranceProvidersList,
    getUserInsurance,
    setInsurancesId,
    handleUpdateInsurance,
    formRef,
  } = props;

  const [showInsuranceForm, setShowInsuranceForm] = useState(false);
  const [primaryInsuranceId, setPrimaryInsuranceId] = useState();
  const primaryInsurance = userInsurance.find(({ is_primary }) => is_primary);

  useEffect(() => {
    getInsuranceProvidersList();
  }, []);

  useEffect(() => {
    if (!editMode) {
      setShowInsuranceForm(false);
    }
  }, [editMode]);

  useEffect(() => {
    if (insuranceListProviderId) {
      getInsurancePlans();
    }
  }, [insuranceListProviderId]);

  useEffect(() => {
    if (primaryInsurance) {
      setPrimaryInsuranceId(primaryInsurance.id);
    }

    setInsurancesId(
      userInsurance.map((value) => ({
        name: value.insurance_plan_name,
        id: value.insurance_plan_id,
        medicaid: value.insurance_is_medicaid,
      }))
    );
  }, [userInsurance]);

  const insuranceCount = userInsurance.length;
  const showAddSecondaryInsuranceButton =
    insuranceCount === 1 && editMode && userPermissions?.add_client_insurance && !editDisabled;

  const handleAddInsurance = useCallback(() => {
    if (userInsurance.length < 2) {
      const newInsuranceEntry = { ...getInitialState };
      userInsurance.push(newInsuranceEntry);
    }
  }, [userInsurance, getInitialState]);

  const handleRemoveInsurance = () => {
    setShowInsuranceForm(false);
    if (userInsurance.length > 1) {
      userInsurance.pop();
    }
  };

  const handleSubmit = (insuranceData) => {
    handleUpdateInsurance(insuranceData);
    setShowInsuranceForm(false);
  };
  return (
    <SectionBox
      title="Insurance"
      description="Add and manage insurance coverage on the client account."
    >
      <div className={classes.insuranceContainer}>
        {userInsurance.map((insurance, idx) => (
          <React.Fragment key={insurance.id}>
            <InsuranceDetails
              formRef={formRef}
              isEditing={editMode}
              insurance={insurance}
              privatePayClient={privatePayClient}
              insuranceProvidersList={insuranceProvidersList}
              classes={classes}
              clientId={clientId}
              userId={userId}
              primaryInsuranceId={primaryInsuranceId}
              setPrimaryInsuranceId={setPrimaryInsuranceId}
              getUserInsurance={getUserInsurance}
              onCancelCreateInsurance={() => handleRemoveInsurance()}
              handleUpdateInsurance={handleUpdateInsurance}
            />
            {idx !== userInsurance.length - 1 && <Divider style={{ margin: "24px 0" }} />}
          </React.Fragment>
        ))}

        {insuranceCount === 0 && (
          <>
            {!editMode ? (
              <Typography
                component="p"
                className={classes.noInfoText}
                style={{ marginTop: "24px" }}
              >
                This client has no insurance information
              </Typography>
            ) : (
              <div className={classes.addInsuranceButtonContainer}>
                {userPermissions?.add_client_insurance && !editDisabled && (
                  <Button
                    color="secondary"
                    onClick={() => handleAddInsurance()}
                    startIcon={<AddIcon />}
                    style={{ marginTop: "24px" }}
                  >
                    Add Insurance
                  </Button>
                )}
              </div>
            )}
          </>
        )}

        {showAddSecondaryInsuranceButton && !showInsuranceForm && (
          <Button
            color="secondary"
            onClick={() => handleAddInsurance()}
            startIcon={<AddIcon />}
            style={{ marginTop: "24px" }}
          >
            Add Secondary Insurance
          </Button>
        )}

        {showInsuranceForm && userPermissions?.add_client_insurance && (
          <div style={{ marginTop: "24px" }}>
            <Divider style={{ margin: "24px 0" }} />

            <InsuranceDetailsEdit
              formId={userInsurance[userInsurance.length - 1].id}
              insurance={getInitialState}
              clientId={clientId}
              userId={userId}
              privatePayClient={privatePayClient}
              classes={classes}
              insuranceProvidersList={insuranceProvidersList}
              primaryInsuranceId={primaryInsuranceId}
              setPrimaryInsuranceId={setPrimaryInsuranceId}
              getUserInsurance={getUserInsurance}
              onCancelCreateInsurance={() => setShowInsuranceForm(false)}
              onSubmit={handleSubmit}
              ref={(ref) => (formRef.current[userInsurance[userInsurance.length - 1].id] = ref)}
            />
          </div>
        )}
      </div>
    </SectionBox>
  );
};

export default withStyles(styles)(Insurance);
