import { useState } from "react";

/**
 * Custom hook for ANUploader.
 *
 * @returns {Object} An object containing most of the necessary
 * functions and states for ANUploader component.
 */
const useANUploader = () => {
  const [dropzoneFiles, setDropzoneFiles] = useState([]);

  const handleFileDelete = (file) => {
    setDropzoneFiles(dropzoneFiles.filter((dropzoneFile) => dropzoneFile !== file));
  };

  const handleFileUpload = (file) => {
    setDropzoneFiles([
      { errors: !!file.fileRejections?.[0], file: file.acceptedFiles[0] || file.fileRejections[0] },
    ]);
  };

  return {
    dropzoneFiles,
    setDropzoneFiles,
    handleFileDelete,
    handleFileUpload,
  };
};

export default useANUploader;
