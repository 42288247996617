import React, { useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { ArrowArcRight } from "@phosphor-icons/react";
import { useIntake } from "components/SelfService/IntakeProvider";
import useANUploader from "hooks/utils/useANUploader";
import { LoadingButton } from "@mui/lab";
import { STAGES } from "components/SelfService/utils";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "selectors";
import actions from "actions";
import { postUploadFiles } from "hooks/postUploadFiles";
import UploadWrapper from "../UploadWrapper";

function DiagnosisUpload({ onSkip, onContinue }) {
  const dispatch = useDispatch();
  const customerDetails = useSelector(selectors.getCustomerDetails);
  const userId = useSelector(selectors.getUserId);
  const { details } = customerDetails;
  const { handleFileUpload, dropzoneFiles, handleFileDelete, setDropzoneFiles } = useANUploader();
  const { goBack, isSavingLoading, intakeFlowJson, currentSubStage, currentStage, clientId } =
    useIntake();
  const subStage = currentSubStage || Object.keys(STAGES[currentStage].subStages)[0];
  const subStageData = intakeFlowJson?.diagnosisReport?.[subStage];

  useEffect(() => {
    setDropzoneFiles(subStageData ? [{ file: subStageData.data }] : []);
  }, [subStage]);

  const submitDocumentUpload = async () => {
    const file = dropzoneFiles[0]?.file;
    if (!subStageData?.isComplete) {
      const { errors, success: filename } = await postUploadFiles(
        details.cognito_id,
        dropzoneFiles
      );
      if (errors) {
        console.error(errors);
        return;
      }
      const document = {
        filename: filename.split("/")[1],
        display_filename: file.name,
        clientId: clientId,
        type: "DIAGNOSIS_FORM",
        uploaded_by: userId,
        file_size: file.size,
        key: filename,
      };
      dispatch(actions.uploadCustomerDocument(document));
    }
    onContinue({ name: file.name, type: file.type, size: file.size });
  };

  return (
    <>
      <Stack gap={3}>
        <Typography variant="h2">Upload your child’s diagnosis report.</Typography>
        <Typography>
          {"Don't worry if you can't upload right now, we'll grab it a bit later."}
        </Typography>
      </Stack>

      <UploadWrapper
        title="Diagnosis Report"
        text="Upload of copy of your child’s diagnostic report provided by the diagnosing provider."
        isUploaded={dropzoneFiles.length}
        ANUploaderProps={{
          handleFileUpload: handleFileUpload,
          dropzoneFiles: dropzoneFiles,
          handleFileDelete: handleFileDelete,
        }}
      />

      <Stack gap={3}>
        <LoadingButton
          disabled={!dropzoneFiles.length}
          loading={isSavingLoading}
          size="large"
          onClick={submitDocumentUpload}
        >
          Continue
        </LoadingButton>
        <Button color="secondary" size="large" endIcon={<ArrowArcRight />} onClick={onSkip}>
          Skip
        </Button>
        <Button variant="text" size="large" onClick={goBack}>
          Back
        </Button>
      </Stack>
    </>
  );
}

export default DiagnosisUpload;
