import useSaveIntakeProgress from "hooks/api/useSaveIntakeProgress";
import React, { createContext, useContext, useState } from "react";
import { STAGES } from "./utils";
import { useGetIntakeFlowData } from "hooks/api/useGetIntakeFlowData";
/** * @typedef {import("api/typesDef").IntakeFlow} IntakeFlow */

/**
 * The IntakeContext is created using the createContext() method. The IntakeContext is used to
 * create a new context for the intake process.
 * @type {React.Context<{intakeFlowJson: IntakeFlow}|null>} The IntakeContext.
 */
const IntakeContext = createContext(null);

/**
 * A provider component for managing the intake process.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components or function to render.
 * @param {Object} props.initialValues - The initial values for the intake process.
 * @param {string} props.initialValues.currentStage - The initial stage.
 * @param {string} props.initialValues.currentSubStage - The initial subStage.
 * @param {number} props.initialValues.clientId - The client id.
 * @param {boolean} props.initialValues.isSpecialist - true indicates the intake is for IntakeSpecialistExperience.
 * @param {Array<string>} props.initialValues.stagesOrder - The order of the stages.
 * @returns {ReactNode} The IntakeProvider component.
 */
export const IntakeProvider = ({ children, initialValues }) => {
  const [currentStage, setCurrentStage] = useState(initialValues?.currentStage);
  const [currentSubStage, setCurrentSubStageState] = useState(initialValues?.currentSubStage);
  const [history, setHistory] = useState({});
  const { intakeFlow } = useGetIntakeFlowData(initialValues?.clientId);
  const intakeFlowJson = intakeFlow?.data;
  const { mutate: saveIntakeProgress, isLoading: isSavingLoading } = useSaveIntakeProgress(
    initialValues?.clientId
  );

  /**
   * Sets the current subStage and updates the history and progress.
   *
   * @param {string} subStage - The subStage to set as the current subStage.
   * @param {boolean} [updateHistoryAndSaveProgress=true] - Flag indicating whether to update the history and save progress. Default is true.
   */
  const setCurrentSubStage = (subStage, updateHistoryAndSaveProgress = true) => {
    setCurrentSubStageState(subStage);
    if (updateHistoryAndSaveProgress) {
      // Adds the subStage to the history (only first subStage is not added)
      setHistory((prev) => ({
        ...prev,
        [currentStage]: [...(prev[currentStage] || []), subStage],
      }));
      saveProgress({ subStage });
    }
  };

  const goBack = () => {
    // Updates the currentStage to the previous stage if there is no subStage in the history
    const shouldMoveToPrevStage = !history[currentStage]?.slice(-1)[0];
    if (shouldMoveToPrevStage && initialValues?.stagesOrder) {
      const currentIndex = initialValues.stagesOrder.indexOf(currentStage);
      const newStage = initialValues.stagesOrder[currentIndex - 1];
      setCurrentStage(newStage);
    } else {
      const currentStageHistory = history[currentStage] || [];
      // Removes the last subStage from the history
      const newHistory = {
        ...history,
        [currentStage]: currentStageHistory.slice(0, -1),
      };
      setHistory(newHistory);
      // Updates the currentSubStage to the last subStage in the history
      const newSubStage = newHistory[currentStage].slice(-1)[0];
      setCurrentSubStageState(newSubStage);
    }
  };

  // BE has the logic to determine what to update, if no subStageData it will update just lastVisited
  const saveProgress = ({
    stage = currentStage,
    subStage = currentSubStage,
    subStageData,
    isStageCompleted,
    isSubStageCompleted,
    clearData,
    onSuccess = () => {},
  }) => {
    return saveIntakeProgress(
      {
        stage,
        // if subStage is not set, that means it's the first subStage
        subStage: subStage || Object.keys(STAGES[currentStage].subStages)[0],
        isSpecialist: initialValues?.isSpecialist,
        subStageData,
        isSubStageCompleted: isSubStageCompleted ?? !!subStageData,
        isStageCompleted,
        // `clearData` prop is used to clear the subStageData
        clearData: clearData ?? null,
      },
      { onSuccess }
    );
  };

  const value = {
    clientId: initialValues?.clientId,
    currentStage,
    currentSubStage,
    goBack,
    history,
    saveProgress,
    setCurrentStage,
    setCurrentSubStage,
    isSavingLoading,
    intakeFlowJson,
    intakeFlow,
  };

  return (
    <IntakeContext.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </IntakeContext.Provider>
  );
};

export const useIntake = () => {
  const context = useContext(IntakeContext);
  if (!context) {
    throw new Error("useIntake must be used within an IntakeContext");
  }
  return context;
};
