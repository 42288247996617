const insuranceDetailConfig = [
  { label: "Insurance Company", dataKey: "insuranceName", cols: 6 },
  { label: "Insurance Plan", dataKey: "planName", cols: 6 },
  { label: "Member ID", dataKey: "memberId", cols: 6 },
  { label: "Group Number", dataKey: "groupNumber", cols: 6 },
  // Keep them as long as they are in the database ---------------->
  { label: "Insurance Holder Name", dataKey: "insuranceHolderName", cols: 6 },
  {
    label: "Insurance Holder Date of Birth",
    dataKey: "insuranceHolderDOB",
    cols: 6,
    format: "jsonDate",
  },
  {
    label: "Insurance Holder Relationship",
    dataKey: "insurance_holder_relationship",
    cols: 12,
  },
  // <-----------------------------------------------------
  { label: "Effective Date", dataKey: "effectiveDate", cols: 6, format: "jsonDate" },
  { label: "Expiration Date", dataKey: "expirationDate", cols: 6, format: "jsonDate" },
  { label: "Primary Insurance", dataKey: "isPrimary", cols: 6 },
  { label: "Medicaid", dataKey: "isMedicaid", cols: 6 },
  // Subscriber fields
  { label: "Subscriber First Name", dataKey: "subscriberFirstName", cols: 6 },
  { label: "Subscriber Last Name", dataKey: "subscriberLastName", cols: 6 },
  { label: "Subscriber Date of Birth", dataKey: "subscriberDOB", cols: 6, format: "jsonDate" },
  { label: "Subscriber's Gender", dataKey: "subscriberGender", cols: 6 },
  { label: "Subscriber's Email Address", dataKey: "subscriberEmail", cols: 6 },
  { label: "Subscriber's Phone Number", dataKey: "subscriberPhone", cols: 6, format: "phone" },
  {
    label: "Relationship to Client",
    dataKey: "subscriberRelationship",
    cols: 6,
    type: "mapRelationshipToDisplay",
  },
  { label: "Country", dataKey: "subscriberCountry", cols: 6, type: "country" },
  { label: "State", dataKey: "subscriberState", cols: 4, type: "state" },
  { label: "City", dataKey: "subscriberCity", cols: 4 },
  { label: "Zip Code", dataKey: "subscriberZip", cols: 4 },
  { label: "Subscriber's Address", dataKey: "subscriberAddress", cols: 12 },
  { label: "Subscriber's Address 2", dataKey: "subscriberAddress2", cols: 12 },
];

export default insuranceDetailConfig;
