import React from "react";
import { Avatar, Box, Divider, FormControl, InputLabel, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

const ANEditItem = ({ headerTitle,headerDescription ,title, input, xs, sm = 12, sx, panelMode, divider, classes, paddingTop= 16 }) => {
  return (
    <>
      <Grid item xs={xs} sm={sm} sx={sx} style={{ paddingLeft: 0, paddingTop: paddingTop  }}>
        {headerTitle && <Typography variant="subtitle1"  sx={{ marginBottom: 2 }}>{headerTitle}</Typography>}
        {headerDescription && (
          <Typography variant="body2" sx={{ color: "text.secondary", marginBottom: 5 }}>
            {headerDescription}
          </Typography>
        )}
        {title && (
          <FormControl className="select" sx={{ width: "100%" }}>
            <InputLabel>{title}</InputLabel>
            {input && input}
          </FormControl>
        )}
        {!title && input}
      </Grid>
      {divider && (
          <Divider style={{ width: "98%", marginTop: "18px" }} />
      )}
    </>
  );
};

export default ANEditItem;
