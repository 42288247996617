import React, { Component } from "react";
import PageContainer from "elements/PageContainer";
import SectionContainer from "elements/SectionContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getCustomersCount,
  getCustomersPage,
  getCustomersPerPage,
  getCustomersSort,
  getCustomersSortDirection,
  getSavedCustomersLoading,
  getSavedForLaterCustomers,
} from "../../selectors";
import actions from "../../actions";
import { CustomTable } from "elements/DEPRECATED_elements";
import { CustomHeaderCell } from "elements/DEPRECATED_elements";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styles from "../../theme/globalStyles";
import withStyles from "@mui/styles/withStyles";
import { format } from "date-fns";
import Heading from "elements/Heading";

const mapStateToProps = (state) => ({
  savedCustomers: getSavedForLaterCustomers(state),
  loading: getSavedCustomersLoading(state),
  page: getCustomersPage(state),
  rowsPerPage: getCustomersPerPage(state),
  sort: getCustomersSort(state),
  direction: getCustomersSortDirection(state),
  count: getCustomersCount(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      getSavedCustomers: actions.getSavedForLaterCustomers,
      setCustomersPage: actions.setCustomersPage,
      setCustomersPerPage: actions.setCustomersPerPage,
      setCustomersSort: actions.setCustomersSort,
    },
    dispatch
  );

class SavedForLater extends Component {
  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Saved Clients",
      currentPage: "savedCustomers",
      menu: "entities",
    });
    this.props.getSavedCustomers();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.page !== this.props.page ||
      prevProps.sort !== this.props.sort ||
      prevProps.direction !== this.props.direction ||
      prevProps.rowsPerPage !== this.props.rowsPerPage
    ) {
      this.props.getSavedCustomers();
    }
  }

  componentWillUnmount() {
    this.props.setCustomersSort("");
  }

  handleChangePage = (event, page) => {
    this.props.setCustomersPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setCustomersPerPage(parseInt(event.target.value));
    this.props.setCustomersPage(0);
  };

  handleSortClick = (name) => {
    this.props.setCustomersSort({
      sort: name,
    });
  };

  render() {
    const { count, rowsPerPage, page, direction, sort, savedCustomers, loading, classes } =
      this.props;
    return (
      <PageContainer loading={loading}>
        <SectionContainer columnDirection noPadding>
          <div className={classes.header}>
            <div />
            <Heading>Client Intake / Leads</Heading>
            <div />
          </div>
          <CustomTable
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            noResultsMessage="No Saved Customers Found"
            headerCells={[
              <CustomHeaderCell
                label="Created"
                sortable={true}
                sortDirection={sort === "u.created_date" ? direction : false}
                active={sort === "u.created_date"}
                onClick={() => this.handleSortClick("u.created_date")}
              />,
              <CustomHeaderCell
                label="Guardian First Name"
                sortable={true}
                sortDirection={sort === "u.first_name" ? direction : false}
                active={sort === "u.first_name"}
                onClick={() => this.handleSortClick("u.first_name")}
              />,
              <CustomHeaderCell
                label="Guardian Last Name"
                sortable={true}
                sortDirection={sort === "u.last_name" ? direction : false}
                active={sort === "u.last_name"}
                onClick={() => this.handleSortClick("u.last_name")}
              />,
              <CustomHeaderCell
                label="Child Name"
                sortable={true}
                sortDirection={sort === "cl.name" ? direction : false}
                active={sort === "cl.name"}
                onClick={() => this.handleSortClick("cl.name")}
              />,
              <CustomHeaderCell label="Open Intake Form" />,
            ]}
          >
            {savedCustomers?.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {format(new Date(row.created_date), "PP", {
                    awareOfUnicodeTokens: true,
                  })}
                </TableCell>
                <TableCell align="left">{row.first_name}</TableCell>
                <TableCell align="left">{row.last_name}</TableCell>
                <TableCell align="left">{row.child_first_name}</TableCell>
              </TableRow>
            ))}
          </CustomTable>
        </SectionContainer>
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SavedForLater));
