import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { getInitialState } from "./InitialState";
import { mapStateToProps } from "./mapStateToProps";
import { mapDispatchToProps } from "./mapDispatchToProps";
import withStyles from "@mui/styles/withStyles";
import styles from "../ClientDetails/styles";
import AnLibrariesPageContents from "./AnLibrariesPageContents";
import AnLibrariesTabs from "./AnLibrariesTabs";
import { useHistory } from "react-router-dom";

const AnLibraries = (props) => {
  const [state, setState] = useState(getInitialState(props));
  const assignBlockRef = useRef(null);
  const smsPolling = useRef(null);
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [dataPanel, setDataPanel] = React.useState({});
  const [configPanel, setConfigPanel] = React.useState([]);
  const history = useHistory();

  useEffect(() => {
    props.setPageDetails({
      pageName: "Libraries",
      hideDivider: true,
    });
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const navigate = (url) => {
    props.history.push(url);
  };

  const changeTab = (tab, params = new URLSearchParams()) => {
    history.push(`#${tab}`);
    setState((prevState) => ({ ...prevState, currentTab: tab, nextTab: null, params }));
  };

  const changeTabFromLoad = (tab, params = new URLSearchParams()) => {
    setState((prevState) => ({ ...prevState, currentTab: tab, nextTab: null, params }));
  };

  const handleHashChange = () => {
    const hash = window.location.hash.substring(1);
    let tabLink = hash.split('?')[0].split('&')[0];

    if (tabLink) {
      const tab = getTabs().find((tab) => tab.link === tabLink);
      if (tab) {
        changeTabFromLoad(tab.link);
        return;
      }
    }
    changeTabFromLoad("insurance");
  };

  const handleTabChange = (event, newValue) => {
    if (props.currentTab !== newValue && state.unsavedChanges) {
      setState((prevState) => ({ ...prevState, openWarning: true, nextTab: newValue }));
    } else {
      resetDataPanel();
      setConfigPanel([]);
      setIsPanelOpen(false);
      setState((prevState) => ({ ...prevState, nextTab: null }));
      changeTab(newValue);
    }
  };

  const getTabs = () => {
    const { userPermissions } = props;

    return [
      { label: "Insurance", link: "insurance", hide: !userPermissions?.view_insurance_utility },
      { label: "Providers", link: "providers", hide: !userPermissions?.view_physician_utility },
      { label: "Clinics", link: "clinics", hide: !userPermissions?.view_clinic_utility },
      {
        label: "Assessments",
        link: "assessments",
        hide: !userPermissions?.view_assessment_library,
      },
    ];
  };

  const setUnsavedChanges = (value) => {
    setState((prevState) => ({ ...prevState, unsavedChanges: value }));
  };

  const setOpenWarning = (value) => {
    setState((prevState) => ({ ...prevState, openWarning: value }));
  };

  const resetDataPanel = () => {
    setDataPanel({});
  };

  const copyToDataPanel = (newData) => {
    setDataPanel(newData);
  };

  const updateDataPanel = (key, value) => {
    setDataPanel((prevDataPanel) => ({
      ...prevDataPanel,
      [key]: value,
    }));
  };

  const { classes, userPermissions } = props;
  const { snackBarOpen, snackBarMessage, currentTab } = state;

  return (
    userPermissions && (
      <div>
        <AnLibrariesTabs
          currentTab={currentTab}
          handleTabChange={handleTabChange}
          userPermissions={userPermissions}
          classes={classes}
          tabs={getTabs()}
        />
        <div className={classes.root}>
          <AnLibrariesPageContents
            {...props}
            {...state}
            visibleTabs={getTabs().filter(({ hide }) => !hide)}
            userPermissions={userPermissions}
            setEditMode={(newState) => setState((prevState) => ({ ...prevState, editMode: newState }))}
            navigate={navigate}
            assignBlockRef={(ref) => (assignBlockRef.current = ref)}
            setUnsavedChanges={setUnsavedChanges}
            setOpenWarning={setOpenWarning}
            changeTab={changeTab}
            dataPanel={dataPanel}
            updateDataPanel={updateDataPanel}
            resetDataPanel={resetDataPanel}
            copyToDataPanel={copyToDataPanel}
            isPanelOpen={isPanelOpen}
            setIsPanelOpen={setIsPanelOpen}
            configPanel={configPanel}
            setConfigPanel={setConfigPanel}
          />
        </div>
        <Snackbar
          autoHideDuration={10000}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              snackBarOpen: false,
              snackBarError: false,
              snackBarMessage: "",
            }))
          }
          open={snackBarOpen}
        >
          <Typography>{snackBarMessage}</Typography>
        </Snackbar>
      </div>
    )
  );
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AnLibraries));