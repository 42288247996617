import React from "react";
import StatusControl from "../Widgets/StatusControl";
import ClientCalls from "../Widgets/ClientCalls";
import PatientProgress from "../Widgets/PatientProgress";
import { AssignedClinicians } from "../Widgets/AssignedClinicians";

const Dashboard = (props) => {
  const {
    classes,
    customerDetails,
    workflowStatus,
    verified,
    submitEligibility,
    isClinician,
    onToggleTreatmentPlanDialog,
    resendLoginSuccess,
    onResendLoginEmail,
    onToggleScheduleClientVideo,
    insuranceProviders,
    onClickChatWithClinician,
    isCustomerCare,
    isSupervisor,
    isDirector,
    navigate,
    currentUser,
    cliniciansLoading,
    videoCallsLoading,
    onOpenUpdateTreatmentPlan,
    submitApproval,
    markApprovedSuccess,
    markingApproved,
    onToggleAssessmentComplete,
    billableTime,
    billableTimeLoading,
    setBillableTimePeriod,
    isActive,
    history,
    hasScheduledCall,
    userPermissions,
    assignClinicianOpen,
    onOpenAssignClinician,
    onCloseAssignClinician,
    onOpenAssignClinicianConfirm,
    onOpenRemoveClinicianConfirm,
    submitAssignClinician,
    assignedRole,
    clinicianList,
    assignableClinicians,
    assignableCliniciansLoading,
    assignBlockRef,
    onToggleStartNewVideoDialog,
    clinicianUserId,
    editDisabled,
    isAssignedClinician,
    scrollToInsuranceAuthorization,
    markingEligible,
    onClickCancelSession,
    treatmentPlanSuccess,
    treatmentPlanUploading,
    childLastName,
    childName,
  } = props;

  return (
    <div className={classes.dashboardContainer}>
      {(isActive === false || workflowStatus === "SELF_SERVICE_INTAKE") && (
        <StatusControl
          workflowStatus={workflowStatus}
          verified={verified}
          customerDetails={customerDetails}
          isClinician={isClinician}
          isCustomerCare={isCustomerCare}
          isDirector={isDirector}
          insuranceProviders={insuranceProviders}
          onToggleScheduleClientVideo={onToggleScheduleClientVideo}
          onToggleAssignClinician={() => onOpenAssignClinician("primary", true)}
          onToggleTreatmentPlanDialog={onToggleTreatmentPlanDialog}
          onResendLoginEmail={onResendLoginEmail}
          navigate={navigate}
          submitEligibility={submitEligibility}
          history={props.history}
          resendLoginSuccess={resendLoginSuccess}
          onOpenUpdateTreatmentPlan={onOpenUpdateTreatmentPlan}
          submitApproval={submitApproval}
          markApprovedSuccess={markApprovedSuccess}
          markingApproved={markingApproved}
          onToggleAssessmentComplete={onToggleAssessmentComplete}
          isActive={isActive}
          isSavedForLater={customerDetails?.details?.save_for_later}
          userPermissions={userPermissions}
          currentUser={currentUser}
          clinicianUserId={clinicianUserId}
          editDisabled={editDisabled}
          videoCallsLoading={videoCallsLoading}
          markingEligible={markingEligible}
          treatmentPlanSuccess={treatmentPlanSuccess}
          treatmentPlanUploading={treatmentPlanUploading}
          userId={customerDetails?.details?.userid}
        />
      )}
      {userPermissions?.view_client_call_schedule && (
        <ClientCalls
          videoCalls={customerDetails.upcomingVideoCalls}
          onToggleScheduleClientVideo={onToggleScheduleClientVideo}
          loading={videoCallsLoading}
          navigate={navigate}
          userId={customerDetails?.details?.userid}
          clientId={customerDetails?.details?.client_id}
          clinician={customerDetails.clinician}
          editDisabled={editDisabled}
          userPermissions={userPermissions}
          history={history}
          onToggleStartNewVideoDialog={onToggleStartNewVideoDialog}
          isAssignedClinician={isAssignedClinician}
          onClickCancelSession={onClickCancelSession}
          currentUserId={currentUser?.id}
          isSecondaryClinician={
            customerDetails?.secondaryClinician?.clinician_user_id === currentUser?.id
          }
          isDirector={isDirector}
          isSupervisor={isSupervisor}
          clientName={`${childName} ${childLastName}`}
        />
      )}
      {(billableTime.length > 0 || billableTimeLoading) && (
        <PatientProgress
          setBillableTimePeriod={setBillableTimePeriod}
          billableTime={billableTime}
          billableTimeLoading={billableTimeLoading}
          workflowStatus={workflowStatus}
          history={props.history}
          scrollToInsuranceAuthorization={scrollToInsuranceAuthorization}
        />
      )}
      <AssignedClinicians
        clinician={customerDetails.clinician}
        secondaryClinician={customerDetails.secondaryClinician}
        navigate={navigate}
        isCustomerCare={isCustomerCare}
        isSupervisor={isSupervisor}
        loading={cliniciansLoading}
        onClickChatWithClinician={onClickChatWithClinician}
        chatId={customerDetails.details.chat_conversation_id}
        editDisabled={editDisabled}
        history={history}
        hasScheduledCall={hasScheduledCall}
        userPermissions={userPermissions}
        onCloseAssignClinician={onCloseAssignClinician}
        onOpenAssignClinician={onOpenAssignClinician}
        clinicianList={clinicianList}
        submitAssignClinician={submitAssignClinician}
        assignedRole={assignedRole}
        assignClinicianOpen={assignClinicianOpen}
        onOpenAssignClinicianConfirm={onOpenAssignClinicianConfirm}
        onOpenRemoveClinicianConfirm={onOpenRemoveClinicianConfirm}
        assignableClinicians={assignableClinicians}
        assignableCliniciansLoading={assignableCliniciansLoading}
        assignBlockRef={assignBlockRef}
      />
    </div>
  );
};

export default Dashboard;
