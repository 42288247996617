import { useMutation, useQueryClient } from "react-query";
import { saveIntakeProgress } from "api/customers";
import { INTAKE_FLOW } from "constants/reactQueryKeys";

const useSaveIntakeProgress = (clientId, options = {}) => {
  const { onSuccess = () => {}, ...otherOptions } = options;
  const queryClient = useQueryClient();
  return useMutation((payload) => saveIntakeProgress(clientId, payload), {
    onSuccess: () => {
      // This will refetch the INTAKE_FLOW query
      queryClient.invalidateQueries([INTAKE_FLOW, clientId]);
      onSuccess();
    },
    ...otherOptions,
  });
};

export default useSaveIntakeProgress;
