import SecureRequest from "../utils/securerequest";
import { buildUrl } from "../utils/buildUrl";

/**
 * @param {String} clinicianId - The clinician id
 * @returns {Promise<{data: LiabilityInsurance[]}>} The promise object of the API request
 */
export const getAllClinics = async (params = {}) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return await Request.get(buildUrl(`/v3/clinic`, params), {});
};

/**
 * Gets a clinic by its ID.
 *
 * @param {String} id - The ID of the clinic to retrieve.
 * @returns {Promise<Object>} The promise object of the API request.
 */
export const getClinicById = async (id) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const url = `/v3/clinic/${id}`;
  return await Request.get(url);
};

/**
 * Creates a new clinic.
 *
 * @param {Object} payload - The data for the new clinic.
 * @returns {Promise<Object>} The promise object of the API request.
 */
export const createClinic = async (payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.post(`/v3/clinic`, payload);
};

/**
 * Updates an existing clinic.
 *
 * @param {String} clinicId - The ID of the clinic to update.
 * @param {Object} payload - The data to update the clinic with.
 * @returns {Promise<Object>} The promise object of the API request.
 */
export const updateClinic = async (clinicId, payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.patch(`/v3/clinic/${clinicId}`, payload);
};
