import SecureRequest from "../utils/securerequest";
import { buildUrl } from "../utils/buildUrl";

/**
 * @param {String} clinicianId - The clinician id
 * @returns {Promise<{data: LiabilityInsurance[]}>} The promise object of the API request
 */
export const getAllProviders = async (params = {}) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();

  return await Request.get(buildUrl(`/v3/provider`, params), {});
};

/**
 * Gets a provider by its ID.
 *
 * @param {String} id - The ID of the provider to retrieve.
 * @returns {Promise<Object>} The promise object of the API request.
 */
export const getProviderById = async (id) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  const url = `/v3/provider/${id}`;
  return await Request.get(url);
};

/**
 * Creates a new provider.
 *
 * @param {Object} payload - The data for the new provider.
 * @returns {Promise<Object>} The promise object of the API request.
 */
export const createProvider = async (payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.post(`/v3/provider`, payload);
};

/**
 * Updates an existing provider.
 *
 * @param {String} providerId - The ID of the provider to update.
 * @param {Object} payload - The data to update the provider with.
 * @returns {Promise<Object>} The promise object of the API request.
 */
export const updateProvider = async (providerId, payload) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return Request.patch(`/v3/provider/${providerId}`, payload);
};

export const searchProviders = async (query) => {
  const sr = new SecureRequest();
  const Request = sr.getRequest();
  return await Request.get(buildUrl(`/v3/provider/search`, query));
};
