import mockData from "./apiResponses/mergedMockedRoutes";

class MockRequestHandler {
  constructor(mockData) {
    this.mockData = mockData;
  }

  /**
   * Resolves the appropriate mock data based on the URL, method, and request data.
   * @param {string} url - The full API URL (e.g., `/v2/physicians/123`).
   * @param {string} method - The HTTP method (GET, POST, PUT, DELETE).
   * @param {object} requestData - Data sent with the request (query, body, urlParams).
   * @property {object} [requestData.body={}] - The request body (e.g., JSON payload for POST or PUT requests).
   * @property {object} [requestData.query={}] - Query parameters included in the URL (e.g., `?key=value`).
   * @property {object} [requestData.urlParams={}] - Dynamic URL parameters extracted from the endpoint path.
   * @returns {{status: number, response: object}} - The matching mock response with status and response body.
   * @throws {Error} - Throws error if no mock is found or mocking is inactive for the endpoint or method.
   */
  resolve(url, method, requestData = {}) {
    const { body = {}, query = {}, urlParams = {} } = requestData;

    // Step 1: Match the endpoint
    const matchedEndpoint = Object.entries(this.mockData).find(([key]) => {
      const params = this.matchUrl(url, key); // Match the URL with endpoint keys
      if (params) {
        requestData.urlParams = { ...urlParams, ...params }; // Merge extracted URL params
        return true;
      }
      return false;
    });

    if (!matchedEndpoint) {
      throw new Error(`No mock data found for URL: ${url}`);
    }

    const [endpointKey, endpoint] = matchedEndpoint;
    if (!endpoint.active) {
      throw new Error(`Mocking disabled for endpoint: ${endpointKey}`);
    }

    // Step 2: Match the HTTP method
    const methodData = endpoint[method];
    if (!methodData || !methodData.active) {
      throw new Error(`Mocking disabled for method: ${method} ${url}`);
    }

    // Step 3: Check scenarios
    const { scenarios = {}, default: defaultResponse } = methodData;

    console.log(`Starting scenario evaluation for method: ${method} on endpoint: ${endpointKey}`);
    console.log(`Total scenarios available: ${Object.keys(scenarios).length}`);

    for (const [scenarioName, { active = true, condition, responseValues }] of Object.entries(
      scenarios
    )) {
      // Skip inactive scenarios
      if (!active) {
        console.log(`Skipping scenario "${scenarioName}" because it is inactive.`);
        continue;
      }

      console.log(`\nEvaluating scenario: "${scenarioName}"`);
      console.log(`Expected conditions:`, JSON.stringify(condition, null, 2));
      console.log(
        `Request data:`,
        JSON.stringify({ urlParams: requestData.urlParams, query, body }, null, 2)
      );

      const urlParamsMatch = this.matchCondition(condition.urlParams, requestData.urlParams);
      const queryMatch = this.matchCondition(condition.query, query);
      const bodyMatch = this.matchCondition(condition.body, body);

      console.log(`Match results for "${scenarioName}":`);
      console.log(`  - URL Params Match: ${urlParamsMatch}`);
      console.log(`  - Query Match: ${queryMatch}`);
      console.log(`  - Body Match: ${bodyMatch}`);

      if (urlParamsMatch && queryMatch && bodyMatch) {
        console.log(`Scenario "${scenarioName}" matched successfully.`);
        console.log(`Returning response:`, JSON.stringify(responseValues, null, 2));
        return { status: responseValues.status, response: responseValues.response };
      } else {
        console.log(`Scenario "${scenarioName}" did not match. Proceeding to next scenario.`);
      }
    }

    console.log("\nNo matching scenario found. Returning default response.");
    console.log(`Default response:`, JSON.stringify(defaultResponse, null, 2));
    return {
      status: defaultResponse.responseValues.status,
      response: defaultResponse.responseValues.response,
    };
  }

  /**
   * Matches the actual URL against the endpoint key.
   * Supports dynamic URL parameters (e.g., `/v2/physicians/:physicianId`).
   * @param {string} url - The actual URL of the request.
   * @param {string} key - The endpoint key, potentially with dynamic segments (e.g., `/v2/:id`).
   * @returns {object|null} - Returns extracted parameters if matched, otherwise null.
   */
  matchUrl(url, key) {
    const cleanUrl = url.split("?")[0]; // Remove query parameters
    const urlParts = cleanUrl.split("/");
    const keyParts = key.split("/");

    if (urlParts.length !== keyParts.length) {
      return null; // Not a match
    }

    const params = {}; // Store extracted dynamic parameters
    const isMatch = keyParts.every((part, index) => {
      if (part.startsWith(":")) {
        const paramName = part.slice(1); // Remove `:`
        params[paramName] = urlParts[index]; // Extract parameter value
        return true;
      }
      return part === urlParts[index]; // Match static segments
    });

    return isMatch ? params : null; // Return parameters if matched
  }

  /**
   * Matches a set of conditions (e.g., body, query, or params).
   * Verifies that all expected key-value pairs exist in the actual data.
   * @param {object} expected - The expected values to match (e.g., { key: "value" }).
   * @param {object} actual - The actual data to check against (e.g., request query or body).
   * @returns {boolean} - True if all expected values match, otherwise false.
   */
  matchCondition(expected = {}, actual = {}) {
    return Object.entries(expected).every(([key, value]) => actual[key] === value);
  }
}

// Instantiate and export the mock request handler
export const mockRequestHandler = new MockRequestHandler(mockData);
