import * as yup from "yup";

const validationSchema = yup.object().shape({
  insuranceid: yup.string().required("Please select an insurer"),
  insurancePlanId: yup.string().required("Please select an insurance plan"),
  memberId: yup.string().required("Please enter the Member ID"),
  groupNumber: yup.string().nullable(),
  insuranceHolderName: yup.string().required("Insurance Holder Name is required"),
  insurance_holder_relationship: yup.string().required("Please select an option"),
  insuranceHolderDOB: yup.date().required("Please enter the date of birth"),
  effectiveDate: yup.date().nullable(),
  expirationDate: yup.date().nullable(),
  isPrimary: yup.boolean(),
  subscriberEmail: yup.string().email("Invalid email").nullable(),
  subscriberDOB: yup.date().nullable(),
  subscriberPhone: yup.string().nullable(),
  subscriberRelationship: yup.string().nullable(),
  subscriberCountry: yup.string().nullable(),
  subscriberState: yup.string().nullable(),
  subscriberCity: yup.string().nullable(),
  subscriberZip: yup.number().nullable(),
  subscriberAddress: yup.string().nullable(),
  subscriberAddress2: yup.string().nullable(),
});

export default validationSchema;
