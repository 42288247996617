import React from "react";
import { Link, Paper, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { CheckCircle } from "@phosphor-icons/react";
import ANUploader from "elements/ANUploader";

export default function UploadWrapper({
  title,
  text,
  textLink,
  onClickLink = () => {},
  isUploaded,
  ANUploaderProps,
}) {
  const theme = useTheme();
  return (
    <Stack bgcolor="#F6F7F8" borderRadius={"16px"} padding={1} gap={theme.spacing(5)}>
      <Stack
        padding={theme.spacing(5)}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        </Stack>
        <Stack ml={5}>
          <CheckCircle
            weight={isUploaded ? "fill" : "duotone"}
            color={isUploaded ? theme.palette.success.main : theme.palette.text.disabled}
            size={24}
          />
        </Stack>
      </Stack>
      <Paper
        style={{
          padding: theme.spacing(7),
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "16px",
        }}
      >
        <ANUploader
          dropZoneStyle={{ margin: 0 }}
          filePreviewStyle={{ margin: 0 }}
          hideDropzone={isUploaded}
          useDropzoneOptions={{ multiple: false }}
          {...ANUploaderProps}
        />
      </Paper>
      {textLink && (
        <Stack padding={theme.spacing(0, 5, 4, 5)}>
          <Link underline="hover" onClick={onClickLink}>
            {textLink}
          </Link>
        </Stack>
      )}
    </Stack>
  );
}
