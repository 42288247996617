import { IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ArrowRight, Check, CircleDashed } from "@phosphor-icons/react";
import { format } from "date-fns";
import React from "react";

const STATUS = {
  COMPLETED: "completed",
  IN_PROGRESS: "in-progress",
};

/**
 * SummaryTaskItem component
 * @param {Object} props
 * @param {string} props.title - The title of the task.
 * @param {Function} props.onClick - Click handler for the component.
 * @param {('completed'|'in-progress')} [props.status=STATUS.COMPLETED] - The status of the task, defaults to 'completed'.
 * @param {string} [props.completedDate] - The date the task was completed.
 */
export default function SummaryTaskItem({
  title,
  onClick,
  status = STATUS.COMPLETED,
  completedDate,
}) {
  const theme = useTheme();
  const statuses = {
    [STATUS.COMPLETED]: {
      iconColor: theme.palette.success.main,
      Icon: Check,
      iconWeight: "regular",
    },
    [STATUS.IN_PROGRESS]: {
      iconColor: theme.palette.warning.main,
      Icon: CircleDashed,
      iconWeight: "duotone",
    },
  };
  const { Icon, iconColor, iconWeight } = statuses[status];

  return (
    <Stack padding={theme.spacing(3, 7)} direction="row" justifyContent="space-between">
      <Stack direction="row" gap={theme.spacing(3)} alignItems="center">
        <Icon color={iconColor} size={20} weight={iconWeight} />
        <Typography variant="subtitle1">{title}</Typography>
        {status === STATUS.COMPLETED && completedDate && (
          <Typography variant="body2" color="text.secondary">
            Completed {format(new Date(completedDate), "MMMM do h:mm a")}
          </Typography>
        )}
      </Stack>

      {onClick && (
        <IconButton fontSize="small" size="small" color="primary" onClick={onClick}>
          <ArrowRight />
        </IconButton>
      )}
    </Stack>
  );
}
