import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { GET_INSURANCE_PLAN, STALE_TIME_5_MINUTES } from "constants/reactQueryKeys";
import { getInsurancePlansAsync } from "api/insurance";
import {
  getInsuranceListState,
  getInsurancePlansPage,
  getInsurancePlansRowsPerPage,
  getInsurancePlansSort,
  getInsurancePlansSortDirection,
} from "selectors";

const useGetInsurancePlans = ({ insuranceid }) => {
  const state = useSelector(getInsuranceListState);
  const page = useSelector(getInsurancePlansPage);
  const size = useSelector(getInsurancePlansRowsPerPage);
  const sort = useSelector(getInsurancePlansSort);
  const direction = useSelector(getInsurancePlansSortDirection);
  const planList = "";
  const companyList = "";
  const coverageList = "";

  const { data, isLoading, isSuccess, status, ...rest } = useQuery({
    queryKey: [GET_INSURANCE_PLAN, insuranceid],
    queryFn: () =>
      getInsurancePlansAsync({
        insuranceid,
        state,
        page,
        size,
        sort,
        direction,
        planList,
        companyList,
        coverageList,
      }),
    placeholderData: [],
    enabled: !!insuranceid,
    staleTime: STALE_TIME_5_MINUTES,
  });

  return {
    insurancePlans: data?.data?.data || [],
    insurancePlansLoading: isLoading,
    fetchStatus: status,
    isSuccess,
    ...rest,
  };
};

export default useGetInsurancePlans;
