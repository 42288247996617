import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Checkbox, FormControlLabel, Link, Stack, TextField } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ArrowUpRight, Buildings, Stethoscope, UserCirclePlus } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import ANNoResultsOverlay from "elements/ANNoResultsOverlay";
import * as selectors from "selectors";
import ANTableFilters from "elements/ANTableFilters";
import { useGlobalToast } from "components/GlobalToastProvider";
import { ProviderConfig } from "./ProviderConfig";
import { ProviderForm } from "./ProviderForm";
import { GET_CLINICS, GET_PROVIDERS } from "../../constants/reactQueryKeys";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import {
  useCreateProvider,
  useGetProviderById,
  useGetProviders,
  useUpdateProvider,
} from "../../hooks/useProvider";
import { useGetClinics } from "../../hooks/useClinic";
import AnChipPopper from "../../elements/AnPopper";
import {
  useCreateProvidersClinics,
  useDeleteProvidersClinics,
} from "../../hooks/useProvidersClinics";
import AnDetailsPanel from "../../elements/Forms/AnDetailsPanel";
import { ProviderFormPrivatePractice } from "./ProviderPrivatePracticeForm";
import { useHistory } from "react-router-dom";
import useHashParams from "../../hooks/useHashParams";
import { ProviderPrivatePracticeConfig } from "./ProviderPrivatePracticeConfig";
import { formatToPhoneNumber } from "../../utils/formatFormFields";

const Providers = (props) => {
  const {
    classes,
    editMode,
    dataPanel,
    resetDataPanel,
    copyToDataPanel,
    configPanel,
    setOpenWarning,
    unsavedChanges,
    setEditMode,
    updateActivePanel,
    updateDataPanel,
    setUnsavedChanges,
    activePanel,
    isDirty,
    updateIsDirty,
    setIsCancelClicked,
    panelRef,
    clearData,
  } = props;
  const theme = useTheme();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const orderByMap = {
    first_name: { sort: "first_name", direction: "asc" },
    last_name: { sort: "last_name", direction: "asc" },
  };
  const params = useHashParams();
  const [orderBy, setOrderBy] = useState(orderByMap.first_name.sort);
  const [byFilterAcceptedInsurance, setByFilterAcceptedInsurance] = useState([]);
  const [byFilterClinics, setByFilterClinics] = useState([]);
  const [byFilterClients, setByFilterClients] = useState([]);
  const [uploadedDateFilter, setUploadedDateFilter] = useState([null, null]);
  const [selectedClinics, setSelectedClinics] = useState([]);
  const userId = useSelector(selectors.getUserId);
  const { showToast } = useGlobalToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [inputsForm, setInputsForms] = React.useState([]);
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);

  const { isFetching, data: response } = useGetProviders(
    {
      sort: orderByMap[orderBy]?.sort,
      direction: orderByMap[orderBy]?.direction,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      accept_insurance: byFilterAcceptedInsurance.join(","),
      clinics: byFilterClinics.join(","),
    },
    {
      onSuccess: (data) => {
        if ((activePanel === "deleteClinic" || activePanel === "createClinic") && dataPanel.id) {
          const row = data?.data?.providers.filter((value) => value.id === dataPanel.id);
          if (row) {
            setUnsavedChanges(false);
            copyToDataPanel(row[0]);
            setInputsForms(inputForms(row[0], []));
          } else {
            if (params.size > 0 && params.get("id_provider") === dataPanel?.id) {
              getProviderByIdMutate({ id: params.get("id_provider") });
            }
          }
        }
      },
    }
  );

  const { isFetching: isFetchingClinics, data: clinics } = useGetClinics(
    {
      sort: "clinic_name",
      direction: "asc",
      page: 0,
      size: 1000,
      clinic_name: byFilterClients,
    },
    {
      onSuccess: () => {},
    }
  );

  const fetchedData = response?.data.providers;
  const rowCount = response?.data.count || 0;
  const acceptsInsuranceFilter = response?.data.accepted_insurance;
  const clinicsFilter = response?.data.clinics;

  const generateRows = () => {
    if (!fetchedData) return [];
    return fetchedData.map((data) => ({
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      fax_number: formatToPhoneNumber(data.fax_number),
      npi_number: data.npi_number,
      phone: formatToPhoneNumber(data.phone),
      specialty: data.specialty,
      accepts_insurance: data.accepts_insurance,
      accepts_insurance_text: data.accepts_insurance === true ? "Yes" : "No",
      physical_address: data.physical_address.split(" | ")[0] || "",
      physical_address_2: data.physical_address.split(" | ")[1] || "",
      city: data.city || "",
      state: data.state || "",
      zip_code: data.zip_code || "",
      website: data.website || "",
      physician_clinic: data.physician_clinic,
    }));
  };

  useEffect(() => {
    if (params.size > 0) {
      getProviderByIdMutate({ id: params.get("id_provider") });
    }
  }, [params]);

  const rows = generateRows();

  const getColumnDefinitions = () => {
    const defaultColAttrs = {
      sortable: false,
      flex: 1,
    };
    const columns = [
      {
        field: "first_name",
        headerName: "First Name",
        ...defaultColAttrs,
      },
      {
        field: "last_name",
        headerName: "Last Name",
        ...defaultColAttrs,
      },
      {
        field: "physician_clinic",
        headerName: "Clinics",
        ...defaultColAttrs,
        renderCell: (params) => {
          const physicianClinic = params.row.physician_clinic;
          const providerCount = physicianClinic.length;
          let labelText = `${providerCount} Clinics`;
          if (providerCount === 1) {
            labelText = `${physicianClinic[0].clinic.clinic_name}}`;
          }
          return (
            <AnChipPopper
              list={physicianClinic}
              chipIcon={
                <Buildings
                  size={16}
                  color={theme.palette.primary.main}
                  style={{ flex: "none" }}
                  weight="duotone"
                />
              }
              linkIcon={
                <ArrowUpRight weight="regular" color={theme.palette.primary.main} size={16} />
              }
              label={labelText}
              getLink={(item) => {
                return `libraries#clinics?id_clinic=${item.clinic.id}`;
              }}
              getLabel={(item) => {
                return `${item.clinic.clinic_name}`;
              }}
            />
          );
        },
      },
      { field: "email", headerName: "Email Address", ...defaultColAttrs },
      {
        field: "fax_number",
        headerName: "Fax Number",
        ...defaultColAttrs,
      },
      {
        field: "phone",
        headerName: "Phone  Number",
        ...defaultColAttrs,
      },
      {
        field: "npi_number",
        headerName: "NPI  Number",
        ...defaultColAttrs,
      },
      {
        field: "specialty",
        headerName: "Specialty",
        ...defaultColAttrs,
      },
      {
        field: "accepts_insurance_text",
        headerName: "Accepts Insurance",
        ...defaultColAttrs,
      },
      {
        field: "action",
        headerName: "Action",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <Link
              color="primary"
              variant="body2"
              underline="hover"
              onClick={() => handleEditItem(params.row)}
              sx={{ "&:hover": { cursor: "pointer" } }}
            >
              Edit
            </Link>
          );
        },
      },
    ];
    return columns;
  };

  const orderingMenuItems = [
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
  ];

  const filtersMenuItems = [
    {
      label: "Accepts Insurance",
      value: "state",
      countLabel: "provider",
      icon: <Stethoscope weight="duotone" />,
      countObject: acceptsInsuranceFilter,
      state: byFilterAcceptedInsurance,
      setState: setByFilterAcceptedInsurance,
    },
    {
      label: "Clinics",
      value: "clinics",
      countLabel: "provider",
      icon: <UserCirclePlus weight="duotone" />,
      countObject: clinicsFilter,
      state: byFilterClinics,
      setState: setByFilterClinics,
    },
  ];

  const handleClearFilters = () => {
    setByFilterClinics([]);
    setByFilterAcceptedInsurance([]);
    setUploadedDateFilter([null, null]);
  };

  const customNoRowsOverlay = {
    NoResults: {
      component: ANNoResultsOverlay,
      height: "236px",
      props: { handleClearFilters },
    },
  };

  const overlayName = "NoResults";

  const validateRequired = (data, type) => {
    const errors = {};
    updateIsDirty(false);
    const config = data["private_practice"] ? ProviderPrivatePracticeConfig : ProviderConfig;
    const requiredIds = config.filter((item) => item.required).map((item) => item.id);
    requiredIds.forEach((id) => {
      if (type === "zipCode" && id.includes("zip")) {
        if (data[id]?.length < 5) {
          updateIsDirty(true);
          errors[id + "_format_error"] = true;
        } else if (data[id] === undefined || data[id] === null || data[id].length === 0) {
          updateIsDirty(true);
          errors[id] = true;
        } else {
          updateIsDirty(false);
          errors[id + "_format_error"] = false;
          errors[id] = false;
        }
      } else if (data[id] === undefined || data[id] === null || data[id].length === 0) {
        updateIsDirty(true);
        errors[id] = true;
      } else {
        errors[id] = false;
      }
    });
    if (!data?.physician_clinic?.length && !data.private_practice) {
      updateIsDirty(true);
      errors["physician_clinic"] = true;
      errors["private_practice"] = true;
    }
    return errors;
  };

  const onChange = (name, onChangeType, data) => (e) => {
    const { checked, type, value } = e.target;
    let needValidateRequired = true;
    if (!onChangeType) {
      let val = type === "checkbox" ? checked : value;
      data[name] = val;
      updateDataPanel(name, val);
    } else if (onChangeType === "radioButtonChange") {
      let parsedValue;
      if (typeof value === "boolean") {
        parsedValue = value;
      } else {
        parsedValue = value === "true";
      }
      data[name] = parsedValue;
      updateDataPanel(name, parsedValue);
    } else if (onChangeType === "phoneNumberchange") {
      let phone = value.replace(/[^\d]/g, "");
      data[name] = phone;
      updateDataPanel(name, phone);
    } else if (onChangeType === "numberChange") {
      data[name] = value.replace(/[^0-9]/g, "");
      updateDataPanel(name, value.replace(/[^0-9]/g, ""));
    } else if (onChangeType === "zipCode") {
      data[name] = value;
      updateDataPanel(name, value);
    } else if (onChangeType === "physician_clinic") {
      if (!data["physician_clinic"]) {
        data["physician_clinic"] = [];
      }
      const clinic = clinics.data.clinics.find((clinic) => clinic.id === value);
      if (clinic) {
        data["physician_clinic"].push({
          id: clinic.id,
          clinic_id: clinic.id,
          clinic: { ...clinic },
        });
      }
      needValidateRequired = false;
    }
    setUnsavedChanges(true);
    setInputsForms(
      inputForms(data, needValidateRequired ? validateRequired(data, onChangeType) : data)
    );
  };

  const onCheckboxSelected = (clinicId) => {
    setSelectedClinics((prevSelected) => {
      return prevSelected.includes(clinicId)
        ? prevSelected.filter((id) => id !== clinicId)
        : [...prevSelected, clinicId];
    });
  };

  const onSubmitClinics = async () => {
    if (dataPanel?.id) {
      updateActivePanel("createClinic");
      const payloads = selectedClinics.map((value) => ({
        clinic_id: value,
        physician_id: dataPanel.id,
        created_by: userId,
      }));
      useCreateProvidersClinicsMutate(payloads);
      updateIsDirty(false);
    } else {
      selectedClinics.forEach((value) => {
        const clinicExists = dataPanel.physician_clinic.some(
          (clinic) => clinic.clinic_id === value
        );
        if (!clinicExists) {
          onChange("physician_clinic", "physician_clinic", dataPanel)({ target: { value } });
        }
      });
    }
  };

  const removeClinic = async (clinicId, data) => {
    if (data?.id) {
      updateActivePanel("deleteClinic");
      useDeleteProvidersClinicsMutate(clinicId);
      updateIsDirty(false);
    } else {
      if (data["physician_clinic"]) {
        const clinicIndex = data["physician_clinic"].findIndex((item) => item.id === clinicId);
        if (clinicIndex !== -1) {
          data["physician_clinic"].splice(clinicIndex, 1);
          setInputsForms(inputForms(data, validateRequired(data, "physician_clinic")));
        }
      }
    }
  };

  const inputForms = (data, errors) => {
    const inputs = [
      {
        id: "first_name",
        input: (
          <TextField
            id="first_name"
            label="First Name"
            onChange={onChange("first_name", null, data)}
            value={data.first_name ?? ""}
            fullWidth
          />
        ),
        cols: 12,
        headerTitle: "Provider Details",
      },
      {
        id: "last_name",
        input: (
          <TextField
            id="last_name"
            label="Last Name"
            onChange={onChange("last_name", null, data)}
            value={data.last_name ?? ""}
            fullWidth
          />
        ),
        cols: 12,
      },
      {
        id: "npi_number",
        input: (
          <TextField
            id="npi_number"
            label="NPI Number (optional)"
            onChange={onChange("npi_number", null, data)}
            value={data.npi_number ?? ""}
            fullWidth
          />
        ),
        cols: 12,
      },
      {
        id: "specialty",
        input: (
          <TextField
            id="specialty"
            label="Specialty (optional)"
            onChange={onChange("specialty", null, data)}
            value={data.specialty ?? ""}
            fullWidth
          />
        ),
        cols: 12,
      },
      {
        id: "accepts_insurance",
        tab: 0,
        input: (
          <FormControlLabel
            control={
              <Checkbox
                checked={data.accepts_insurance}
                onChange={onChange("accepts_insurance", null, data)}
              />
            }
            label="Accepts Insurance"
          />
        ),
        cols: 12,
        divider: true,
      },
      {
        id: "email",
        input: (
          <TextField
            id="email"
            label="Email Address"
            onChange={onChange("email", null, data)}
            value={data.email ?? ""}
            fullWidth
          />
        ),
        cols: 12,
      },
      {
        id: "phone",
        tab: 0,
        input: (
          <TextField
            label="Providers's Phone Number"
            id="phone"
            onChange={onChange("phone", "phoneNumberchange", data)}
            value={formatToPhoneNumber(data.phone ?? "")}
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ maxLength: 14 }}
          />
        ),
      },
      {
        id: "fax_number",
        tab: 0,
        input: (
          <TextField
            label="Providers's Fax Number (optional)"
            id="fax_number"
            onChange={onChange("fax_number", "phoneNumberchange", data)}
            value={formatToPhoneNumber(data.fax_number ?? "")}
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ maxLength: 14 }}
          />
        ),
        divider: true,
      },
    ];
    const isEditing = data?.id;
    if (!isEditing) {
      inputs.push({
        id: "private_practice",
        tab: 0,
        headerTitle: "Clinics",
        headerDescription: "Connect the provider to clinics from the clinic library",
        input: (
          <FormControlLabel
            control={
              <Checkbox
                checked={data.private_practice}
                onChange={onChange("private_practice", null, data)}
              />
            }
            label="Set the new Provider as Private Practice"
          />
        ),
        cols: 12,
      });
    }
    if (data["private_practice"]) {
      inputs.push(...ProviderFormPrivatePractice({ data, errors, onChange }));
    } else {
      inputs.push(
        ...ProviderForm({
          classes,
          data,
          onDeleteItem: (clinicId) => removeClinic(clinicId, data),
          theme,
          isDeletingItem,
          clinics: clinics?.data?.clinics,
          onSearch: setByFilterClients,
          onClinicSelected: onCheckboxSelected,
          onSubmitClinics,
          isCreatingItem,
        })
      );
    }

    return inputs;
  };

  const { mutate: useCreateMutate, isLoading: isCreating } = useCreateProvider({
    onSuccess: () => {
      clearData();
      setIsPanelOpen(false);
      setSelectedClinics([]);
      showToast({ message: "Provider Created Successfully!" });
      queryClient.invalidateQueries([GET_CLINICS]);
      queryClient.invalidateQueries([GET_PROVIDERS]);
    },
    onError: () => {
      showToast({
        message: "Error Creating Provider.",
        errorState: true,
      });
    },
  });

  const { mutate: useUpdateMutate, isLoading: isUpdating } = useUpdateProvider({
    onSuccess: () => {
      clearData();
      setIsPanelOpen(false);
      setSelectedClinics([]);
      showToast({ message: "Provider Updated Successfully!" });
      queryClient.invalidateQueries([GET_PROVIDERS]);
    },
    onError: () => {
      showToast({
        message: "Error Updating Provider.",
        errorState: true,
      });
    },
  });

  const { mutate: useDeleteProvidersClinicsMutate, isLoading: isDeletingItem } =
    useDeleteProvidersClinics({
      onSuccess: () => {
        setSelectedClinics([]);
        queryClient.invalidateQueries([GET_PROVIDERS]);
        if (params.size > 0 && params.get("id_provider") === dataPanel?.id) {
          getProviderByIdMutate({ id: params.get("id_provider") });
        }
        showToast({ message: "Clinic Removed Successfully!" });
      },
      onError: () => {
        showToast({
          message: "Error Deleting Clinic of Provider..",
          errorState: true,
        });
      },
    });

  const { mutate: useCreateProvidersClinicsMutate, isLoading: isCreatingItem } =
    useCreateProvidersClinics({
      onSuccess: () => {
        setSelectedClinics([]);
        queryClient.invalidateQueries([GET_PROVIDERS]);
        if (params.size > 0 && params.get("id_provider") === dataPanel?.id) {
          getProviderByIdMutate({ id: params.get("id_provider") });
        }
        showToast({ message: "Clinics Connected Successfully!" });
      },
      onError: () => {
        showToast({
          message: "Error Connecting Clinics on Provider.",
          errorState: true,
        });
      },
    });

  const onSave = async () => {
    if (!isDirty) {
      if (dataPanel?.private_practice) {
        dataPanel.physical_address =
          `${dataPanel?.physical_address} ${dataPanel?.physical_address_2 ? dataPanel?.physical_address_2 : ""}`.trim();
      } else {
        dataPanel.physical_address = "";
        dataPanel.city = "";
        dataPanel.state = "";
        dataPanel.zip_code = "";
        dataPanel.website = "";
      }
      delete dataPanel?.physical_address_2;
      delete dataPanel?.accepts_insurance_text;
      dataPanel.phone = dataPanel?.phone.replace(/[^\d]/g, "");
      dataPanel.fax_number = dataPanel?.fax_number?.replace(/[^\d]/g, "");
      if (dataPanel?.id) {
        useUpdateMutate({ id: dataPanel?.id, ...dataPanel });
      } else {
        useCreateMutate({ ...dataPanel });
      }
    }
  };

  useEffect(() => {
    if (!isFetchingClinics) {
      setInputsForms(inputForms(dataPanel, validateRequired(dataPanel, "")));
    }
  }, [clinics, dataPanel]);

  useEffect(() => {
    if (selectedClinics && selectedClinics.length > 0) {
      onChange(
        "clinic_id",
        "physician_clinic",
        dataPanel
      )({ target: { value: selectedClinics[selectedClinics.length] } });
    }
  }, [selectedClinics]);

  const handleEditItem = (row) => {
    setIsPanelOpen(true);
    setEditMode(true);
    updateIsDirty(true);
    setUnsavedChanges(false);
    updateActivePanel("createProvider");
    copyToDataPanel(row);
    setInputsForms(inputForms(row, []));
  };

  const { mutate: getProviderByIdMutate } = useGetProviderById({
    onSuccess: (response) => {
      handleEditItem(response.data);
    },
  });

  return (
    <Stack
      direction="row"
      sx={{
        height: "calc(100vh - 163px)",
        [theme.breakpoints.down("md")]: {
          height: "calc(100vh - 171px)",
        },
        width: "100%",
      }}
    >
      <Stack
        flex={isPanelOpen ? 2 : 1}
        sx={{
          transition: "all 0.3s ease",
          overflow: "auto",
        }}
      >
        <div className={classes.header} style={{ padding: "16px" }}>
          <div className={classes.headerButtonContainer}>
            <ANTableFilters
              orderingMenuItems={orderingMenuItems}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              filtersMenuItems={filtersMenuItems}
              filtersButtonProps={{ disabled: fetchedData?.length === 0 }}
              searchInput
            />
          </div>
          <div className={classes.headerButtonContainer}>
            <Button
              color="secondary"
              startIcon={<UserCirclePlus />}
              size="small"
              onClick={() => {
                setSelectedClinics([]);
                setIsPanelOpen(true);
                setEditMode(true);
                updateIsDirty(true);
                setUnsavedChanges(false);
                updateActivePanel("createProvider");
                setInputsForms(inputForms({}, []));
              }}
              sx={{ minWidth: "fit-content" }}
            >
              Add Provider
            </Button>
          </div>
        </div>
        <div>
          <DataGrid
            rows={rows}
            loading={isFetching && !fetchedData}
            pageSizeOptions={[5, 10, 20]}
            columns={getColumnDefinitions()}
            disableRowSelectionOnClick
            autoHeight
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            slots={{
              noRowsOverlay: customNoRowsOverlay[overlayName].component,
            }}
            slotProps={{ noRowsOverlay: { ...customNoRowsOverlay[overlayName].props } }}
            sx={{ "--DataGrid-overlayHeight": customNoRowsOverlay[overlayName].height }}
            hideFooter={!rowCount}
            paginationMode="server"
            rowCount={rowCount}
          />
        </div>
      </Stack>

      <Stack
        flex={isPanelOpen ? 2 : 0}
        sx={{
          borderLeft: `1px solid ${theme.palette.divider}`,
          transition: "all 0.3s ease",
          alignItems: "flex-end",
          overflow: "auto",
          display: "flow",
        }}
        maxWidth="400px"
      >
        <AnDetailsPanel
          ref={panelRef}
          classes={classes}
          open={isPanelOpen}
          setOpen={setIsPanelOpen}
          editMode={editMode}
          titlePanel={dataPanel?.id ? "Edit Provider" : "Add Provider"}
          data={dataPanel}
          resetData={resetDataPanel}
          config={configPanel}
          inputsForm={inputsForm}
          isLoading={isCreating || isUpdating || isDeletingItem || isCreatingItem}
          disableSaveButton={isDirty}
          onSave={onSave}
          onCancel={() => {
            if (unsavedChanges) {
              setIsCancelClicked(true);
              setOpenWarning(true);
            } else {
              clearData();
              setIsPanelOpen(false);
              setSelectedClinics([]);
              updateActivePanel(null);
              history.push("#providers");
            }
          }}
          saveTitle={"Save Changes"}
          icon={
            <UserCirclePlus
              fontSize={"20px"}
              weight="duotone"
              color={theme.palette.primary.main}
            ></UserCirclePlus>
          }
          topEditEnable={false}
        />
      </Stack>
    </Stack>
  );
};
export default withStyles(styles)(Providers);
