export const STAGES = {
  summary: { name: "summary", displayName: "Summary" },
  insuranceCard: {
    name: "insuranceCard",
    displayName: "Insurance Card",
    subStages: {
      frontCard1: { name: "frontCard1" },
      backCard1: { name: "backCard1" },
      manualInsurance1: { name: "manualInsurance1" },
      haveSecondaryInsurance: { name: "haveSecondaryInsurance" },
      frontCard2: { name: "frontCard2" },
      backCard2: { name: "backCard2" },
      manualInsurance2: { name: "manualInsurance2" },
    },
  },
  pediatrician: {
    name: "pediatrician",
    displayName: "Pediatrician",
    subStages: {
      search: { name: "searchForm" },
      manual: { name: "manualForm" },
    },
  },
  diagnosisReport: {
    name: "diagnosisReport",
    displayName: "Diagnosis Report",
    subStages: {
      haveDiagnosisReport: { name: "haveDiagnosisReport" },
      diagnosisReportUpload: { name: "diagnosisReportUpload" },
    },
  },
  diagnosingDoctor: {
    name: "diagnosingDoctor",
    displayName: "Diagnosing Doctor",
    subStages: {
      search: { name: "searchForm" },
      manual: { name: "manualForm" },
    },
  },
};
