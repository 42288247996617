import React from "react";
import ProvidersFormDialogProvidersForm from "./ProvidersFormDialogProvidersForm";
import MultiStepFormDialog from "../MultiStepFormDialog/MultiStepFormDialog";
import ProvidersFormDialogROIForm from "./ProvidersFormDialogROIForm";
import useProvidersFormDialogMutations from "./useProvidersFormDialogMutations";

export const ProviderFormDialogType = {
  newProvider: "newProvider",
  roi: "roi",
  updateProvider: "updateProvider",
};
const ProvidersFormDialog = (props) => {
  const {
    open,
    closeDialog,
    formDialogType,
    clientId,
    defaultValues,
    onProviderChanges,
    getAllDocumentRequests,
    userPermissions,
    providerFormValues,
  } = props;

  const { createUnsignedRequestMutation, linkPhysicianMutation, updatePhysicianMutation } =
    useProvidersFormDialogMutations();

  const handleSendReleaseForm = async (values) => {
    const result = await createUnsignedRequestMutation.mutateAsync({
      ...values,
      provider: providerFormValues,
      clientId,
    });

    getAllDocumentRequests(props.match.params.id);
    onProviderChanges(true);

    return result;
  };

  const handleAddProvider = async (values) => {
    const result = await linkPhysicianMutation.mutateAsync({
      ...values,
      clientId,
    });

    onProviderChanges();

    return result;
  };

  const handleUpdateProvider = async (values) => {
    const result = await updatePhysicianMutation.mutateAsync({
      provider: values.provider,
      previousProviderType: providerFormValues.type,
      clientId,
    });

    onProviderChanges();

    return result;
  };

  const formTypeOptions = {
    [ProviderFormDialogType.newProvider]: {
      steps: [ProvidersFormDialogProvidersForm, ProvidersFormDialogROIForm],
      submitLabel: "Finish and Add Provider",
      onSubmit: handleAddProvider,
      title: "Add Provider",
    },
    [ProviderFormDialogType.roi]: {
      steps: [ProvidersFormDialogROIForm],
      submitLabel: "Create Release Form",
      onSubmit: handleSendReleaseForm,
      title: "Create Release of Information Form",
    },
    [ProviderFormDialogType.updateProvider]: {
      steps: [ProvidersFormDialogProvidersForm],
      submitLabel: "Save Changes",
      onSubmit: handleUpdateProvider,
      title: "Edit Provider",
    },
  };

  // If user doesn't have permission to create a ROI doc then the flow is the same as updating a provider
  if (
    formDialogType === ProviderFormDialogType.newProvider &&
    !userPermissions.create_phi_release_request
  ) {
    formTypeOptions[ProviderFormDialogType.newProvider] = {
      ...formTypeOptions[ProviderFormDialogType.updateProvider],
      title: formTypeOptions[ProviderFormDialogType.newProvider].title,
    };
  }

  const { title, steps, submitLabel, onSubmit, onCloseAction, closeLabel } =
    formTypeOptions[formDialogType];

  return (
      <MultiStepFormDialog
      title={title}
      steps={steps}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      open={open}
      onClose={closeDialog}
      submitLabel={submitLabel}
      onCloseAction={onCloseAction}
      closeButtonLabel={closeLabel}
      successTexts={
        formDialogType === ProviderFormDialogType.updateProvider
          ? {
              title: "Success",
              subtitle: "The provider has been successfully updated.",
            }
          : {
              title: "Release of Information successfully sent!",
              subtitle: "The form will be available once the client has signed the release.",
            }
      }
    />
  );
};

export default ProvidersFormDialog;
