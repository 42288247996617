import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import actions from "../../actions";
import styles from "./styles";

import {
  getCreatePlanLoading,
  getCreatePlanSuccess,
  getCreatePlanError,
  getCreateProviderError,
  getCreateProviderLoading,
  getCreateProviderSuccess,
  getInsuranceListProviderId,
  getInsuranceListState,
  getInsuranceLoading,
  getInsurancePlans,
  getInsurancePlansCount,
  getInsurancePlansPage,
  getInsurancePlansRowsPerPage,
  getInsurancePlansSort,
  getInsurancePlansSortDirection,
  getInsuranceProvidersList,
  getInsuranceSuccess,
  getUpdatePlanLoading,
  getUpdatePlanSuccess,
  getUpdatePlanError,
} from "selectors";
import InsuranceUtilityTable from "./InsuranceUtilityTable";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AnDetailsPanel from "../../elements/Forms/AnDetailsPanel";
import { Stack } from "@mui/material";
import { InsuranceCompanyConfig } from "../AnLibraries/company/InsuranceCompanyConfig";
import { InsuranceCompanyForm } from "../AnLibraries/company/InsuranceCompanyForm";
import { InsurancePlanForm } from "../AnLibraries/plan/InsurancePlanForm";
import { InsurancePlanConfig } from "../AnLibraries/plan/InsurancePlanConfig";
import ANTableFilters from "../../elements/ANTableFilters";
import { BookOpenText, PencilSimpleLine } from "@phosphor-icons/react";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { Prompt } from "react-router-dom";
import SaveBeforeNavDialog from "../ClientDetails/Dialogs/SaveBeforeNavDialog";

const mapStateToProps = (state) => ({
  insurancePlans: getInsurancePlans(state),
  count: getInsurancePlansCount(state),
  page: getInsurancePlansPage(state),
  rowsPerPage: getInsurancePlansRowsPerPage(state),
  sort: getInsurancePlansSort(state),
  sortDirection: getInsurancePlansSortDirection(state),
  loading: getInsuranceLoading(state),
  success: getInsuranceSuccess(state),
  insuranceListState: getInsuranceListState(state),
  insuranceListProviderId: getInsuranceListProviderId(state),
  providers: getInsuranceProvidersList(state),
  createPlanLoading: getCreatePlanLoading(state),
  createPlanSuccess: getCreatePlanSuccess(state),
  createPlanError: getCreatePlanError(state),
  createProviderLoading: getCreateProviderLoading(state),
  createProviderSuccess: getCreateProviderSuccess(state),
  createProviderError: getCreateProviderError(state),
  updatePlanLoading: getUpdatePlanLoading(state),
  updatePlanSuccess: getUpdatePlanSuccess(state),
  updatePlanError: getUpdatePlanError(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInsuranceProviders: actions.getInsuranceProvidersList,
      createInsurancePlan: actions.createInsurancePlan,
      updateInsurancePlan: actions.updateInsurancePlan,
      createInsuranceProvider: actions.createInsuranceProvider,
      updateInsuranceProvider: actions.updateInsuranceProvider,
      setInsuranceListState: actions.setInsuranceListState,
      setInsuranceListProviderId: actions.setInsuranceListProviderId,
      clearInsuranceListFilters: actions.clearInsuranceListFilters,
      setPage: actions.setInsurancePlansPage,
      setRowsPerPage: actions.setInsurancePlansRowsPerPage,
      setSort: actions.setInsurancePlansSort,
    },
    dispatch
  );

class InsuranceUtility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
    };
  }

  componentDidMount() {
    // this.props.getInsurancePlans();
    this.props.getInsuranceProviders();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.createProviderLoading && !this.props.createProviderLoading) {
      if (this.props.createProviderSuccess) {
        this.resetPage();
        this.props.finishSubmit("Insurance company successfully added to the library", false);
        this.props.getInsurancePlans();
      } else if (this.props.createProviderError) {
        this.props.finishSubmit("Error creating Insurance company", true);
      }
    }
    if (prevProps.createPlanLoading && !this.props.createPlanLoading) {
      if (this.props.createPlanSuccess) {
        this.resetPage();
        this.props.finishSubmit("Insurance plan successfully added to the library", false);
        this.props.getInsurancePlans();
      } else if (this.props.createPlanError) {
        this.props.finishSubmit("Error creating Insurance plan", true);
      }
    }
    if (prevProps.updatePlanLoading && !this.props.updatePlanLoading) {
      if (this.props.updatePlanSuccess) {
        this.resetPage();
        this.props.finishSubmit("Insurance plan successfully updated", false);
        this.props.getInsurancePlans();
      } else if (this.props.updatePlanError) {
        this.props.finishSubmit("Error updating Insurance plan", true);
      }
    }
    if (
      prevProps.sort !== this.props.sort ||
      prevProps.page !== this.props.page ||
      prevProps.sortDirection !== this.props.sortDirection ||
      prevProps.rowsPerPage !== this.props.rowsPerPage ||
      prevProps.insuranceListState !== this.props.insuranceListState ||
      prevProps.insuranceListProviderId !== this.props.insuranceListProviderId
    ) {
      this.props.getInsurancePlans();
    }
    if (
      prevProps.insurancePlans?.length == 0 &&
      this.props.insurancePlans?.length > 0 &&
      this.state.states.length == 0
    ) {
      let allStates = this.props.insurancePlans[0]?.all_states?.split(",") ?? [];
      allStates.unshift("NATIONAL");

      const states = allStates
        ?.map((plan) => {
          return plan.trim();
        })
        ?.filter((x, i, a) => a.indexOf(x) == i);
      this.setState({ states });
    }
  }

  resetPage() {
    this.props.resetDataPanel();
    this.props.updateIsDirty(false);
    this.props.setConfigPanel([]);
    this.props.setIsPanelOpen(false);
    this.props.setUnsavedChanges(false);
  }

  componentWillUnmount() {
    this.props.clearInsuranceListFilters();
  }

  handleSortClick = (name) => {
    this.props.setSort({
      sort: name,
    });
  };

  handleChangePage = (event, page) => {
    this.props.setPage(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setRowsPerPage(parseInt(event.target.value));
    this.props.setPage(0);
  };

  onChange = (name, onChangeType, data) => (e) => {
    const { id, checked, type, value } = e.target;
    if (!onChangeType) {
      let val;
      switch (value) {
        case "Yes":
          val = true;
          break;
        case "No":
          val = false;
          break;
        default:
          val = type === "checkbox" ? checked : value;
      }
      data[name] = val;
      this.props.updateDataPanel(name, val);
    } else if (onChangeType === "radioButtonChange") {
      let parsedValue;
      if (typeof value === "boolean") {
        parsedValue = value;
      } else {
        parsedValue = value === "true";
      }
      data[name] = parsedValue;
      this.props.updateDataPanel(name, parsedValue);
    } else if (onChangeType === "phoneNumberchange") {
      let numberValue = value.replace(/[^0-9]/g, "");
      let phone = numberValue.replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
        "($1)-$2-$3"
      );
      data[name] = phone;
      this.props.updateDataPanel(name, phone);
    } else if (onChangeType === "numberChange") {
      data[name] = value.replace(/[^0-9]/g, "");
      this.props.updateDataPanel(name, value.replace(/[^0-9]/g, ""));
    } else if (onChangeType === "zipCode") {
      data[name] = value;
      this.props.updateDataPanel(name, value);
    }
    this.props.setUnsavedChanges(true);
    this.props.updateInputsForm(
      this.inputForms(data, this.validateRequired(data, onChangeType), this.props.activePanel)
    );
  };

  onToggleCreatePlanDialog = () => {
    if (this.props.isPanelOpen && this.props.unsavedChanges) {
      this.props.setOpenWarning(true);
    } else {
      const title = "Add Insurance Plan";
      this.props.setIsPanelOpen(true);
      this.props.updateTitlePanel(title);
      this.props.setEditMode(true);
      this.props.updateIsDirty(true);
      this.props.setUnsavedChanges(false);
      this.props.setSaveButtonTitle(title);
      this.props.updateActivePanel("createPlan");
      this.props.updateInputsForm(this.inputForms({}, [], "createPlan"));
    }
  };

  onToggleCreateProviderDialog = () => {
    if (this.props.isPanelOpen && this.props.unsavedChanges) {
      this.props.setOpenWarning(true);
    } else {
      const title = "Add Insurance Company";
      this.props.setIsPanelOpen(true);
      this.props.updateIsDirty(true);
      this.props.setUnsavedChanges(false);
      this.props.updateTitlePanel(title);
      this.props.setEditMode(true);
      this.props.setSaveButtonTitle(title);
      this.props.updateActivePanel("createCompany");
      this.props.updateInputsForm(this.inputForms({}, [], "createCompany"));
    }
  };

  addCoverageStateIfMissing(plan) {
    // Check if the coverageState from the plan is not included in the states array
    if (!this.state.states.includes(plan.coverageState)) {
      // Add coverageState to the states array
      this.setState({
        states: [...this.state.states, plan.coverageState].sort(),
      });
    }
  }

  inputForms = (data, errors, activePanel) => {
    if (activePanel === "createCompany" || activePanel === "editCompany") {
      return InsuranceCompanyForm(data, errors, this.onChange);
    } else if (activePanel === "createPlan" || activePanel === "editPlan") {
      return InsurancePlanForm(
        data,
        this.props.providers,
        errors,
        this.onChange,
        formatPhoneNumber
      );
    }
  };

  validateRequired = (data, type) => {
    const errors = {};
    this.props.updateIsDirty(false);
    const requiredIds =
      this.props.activePanel === "createCompany" || this.props.activePanel === "editCompany"
        ? InsuranceCompanyConfig.filter((item) => item.required).map((item) => item.id)
        : InsurancePlanConfig.filter((item) => item.required).map((item) => item.id);
    requiredIds.forEach((id) => {
      if (type === "zipCode" && id.includes("zip")) {
        if (data[id]?.length < 5) {
          this.props.updateIsDirty(true);
          errors[id + "_format_error"] = true;
        } else if (data[id] === undefined || data[id] === null || data[id].length === 0) {
          this.props.updateIsDirty(true);
          errors[id] = true;
        } else {
          this.props.updateIsDirty(false);
          errors[id + "_format_error"] = false;
          errors[id] = false;
        }
      } else if (data[id] === undefined || data[id] === null || data[id].length === 0) {
        this.props.updateIsDirty(true);
        errors[id] = true;
      } else {
        errors[id] = false;
      }
    });
    console.log("error", errors);
    return errors;
  };

  render() {
    const {
      loading,
      classes,
      providers,
      insuranceListState,
      insuranceListProviderId,
      insurancePlansData,
      isFetchingData,
      editMode,
      dataPanel,
      updateDataPanel,
      resetDataPanel,
      copyToDataPanel,
      configPanel,
      setConfigPanel,
      isPanelOpen,
      setIsPanelOpen,
      titlePanel,
      updateTitlePanel,
      inputsForm,
      updateInputsForm,
      isDirty,
      updateIsDirty,
      panelRef,
      setSaveButtonTitle,
      saveButtonTitle,
      activePanel,
      updateActivePanel,
      finishSubmit,
      createPlanLoading,
      createProviderLoading,
      setEditMode,
      topEdit,
      updatePlanLoading,
      orderBy,
      setOrderBy,
      orderByMap,
      filtersMenuItems,
      orderingMenuItems,
      paginationModel,
      setPaginationModel,
      setPlanFilter,
      setCompanyFilter,
      setCoverageStateFilter,
      uploadedDateFilter,
      setUploadedDateFilter,
      rowCount,
      theme,
      setOpenWarning,
      setIsCancelClicked,
      unsavedChanges,
    } = this.props;

    const onSave = async () => {
      if (!isDirty) {
        if (activePanel === "createCompany") {
          this.props.createInsuranceProvider(dataPanel);
        } else if (activePanel === "createPlan") {
          this.props.createInsurancePlan(dataPanel);
          this.addCoverageStateIfMissing(dataPanel);
        } else if (activePanel === "editPlan") {
          this.props.updateInsurancePlan(dataPanel);
          this.addCoverageStateIfMissing(dataPanel);
        } else if (activePanel === "editCompany") {
          this.props.updateInsuranceProvider(dataPanel);
          this.addCoverageStateIfMissing(dataPanel);
        }
      }
    };

    return (
      <>
        <Stack
          direction="row"
          sx={{
            height: "calc(100vh - 163px)",
            [theme.breakpoints.down("md")]: {
              height: "calc(100vh - 171px)",
            },
            width: "100%",
          }}
        >
          <Stack
            flex={isPanelOpen ? 2 : 1}
            sx={{
              transition: "all 0.3s ease",
              overflow: "auto",
            }}
          >
            <div className={classes.header} style={{ padding: "16px" }}>
              <div className={classes.headerButtonContainer}>
                <ANTableFilters
                  orderingMenuItems={orderingMenuItems}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  filtersMenuItems={filtersMenuItems}
                  filtersButtonProps={{ disabled: insurancePlansData?.length === 0 }}
                  searchInput
                />
              </div>
              <div className={classes.headerButtonContainer}>
                <Button
                  onClick={this.onToggleCreateProviderDialog}
                  color="secondary"
                  size="small"
                  style={{ marginRight: 10 }}
                  startIcon={<AddIcon />}
                >
                  Add Insurance Company
                </Button>
                <Button
                  onClick={this.onToggleCreatePlanDialog}
                  color="secondary"
                  size="small"
                  startIcon={<AddIcon />}
                >
                  Add Insurance Plan
                </Button>
              </div>
            </div>
            <div style={{ overflowY: "auto" }}>
              <InsuranceUtilityTable
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleSortClick={this.handleSortClick}
                copyToDataPanel={copyToDataPanel}
                updateTitlePanel={updateTitlePanel}
                setIsPanelOpen={setIsPanelOpen}
                setConfigPanel={setConfigPanel}
                setEditMode={setEditMode}
                isPanelOpen={isPanelOpen}
                dataPanel={dataPanel}
                insurancePlansData={insurancePlansData}
                isFetchingData={
                  isFetchingData || createProviderLoading || createPlanLoading || updatePlanLoading
                }
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                uploadedDateFilter={uploadedDateFilter}
                setUploadedDateFilter={setUploadedDateFilter}
                rowCount={rowCount}
                setPlanFilter={setPlanFilter}
                setCompanyFilter={setCompanyFilter}
                setCoverageStateFilter={setCoverageStateFilter}
                unsavedChanges={unsavedChanges}
                setOpenWarning={setOpenWarning}
                {...this.props}
                {...this.state}
              />
            </div>
          </Stack>
          <Stack
            flex={isPanelOpen ? 2 : 0}
            sx={{
              borderLeft: `1px solid ${theme.palette.divider}`,
              transition: "all 0.3s ease",
              alignItems: "flex-end",
              overflow: "auto",
              display: "flow",
            }}
            maxWidth="400px"
          >
            <AnDetailsPanel
              ref={panelRef}
              classes={classes}
              open={isPanelOpen}
              setOpen={setIsPanelOpen}
              editMode={editMode}
              titlePanel={titlePanel}
              data={dataPanel}
              resetData={resetDataPanel}
              config={configPanel}
              inputsForm={inputsForm}
              isLoading={createProviderLoading || createPlanLoading || updatePlanLoading}
              disableSaveButton={isDirty}
              onSave={onSave}
              onCancel={() => {
                if (unsavedChanges) {
                  setIsCancelClicked(true);
                  setOpenWarning(true);
                } else {
                  resetDataPanel();
                  updateIsDirty(true);
                  setConfigPanel([]);
                  setIsPanelOpen(false);
                  setOpenWarning(false);
                }
              }}
              saveTitle={saveButtonTitle}
              icon={
                <BookOpenText
                  fontSize={"20px"}
                  weight="duotone"
                  color={theme.palette.primary.main}
                ></BookOpenText>
              }
              topEditEnable={!editMode && topEdit}
              topEditActions={[
                {
                  icon: <PencilSimpleLine weight="duotone" />,
                  text: "Edit Plan",
                  action: () => {
                    setIsPanelOpen(true);
                    updateTitlePanel(dataPanel?.name ?? "Edit insurance plan");
                    setEditMode(true);
                    setSaveButtonTitle("Save Changes");
                    updateActivePanel("editPlan");
                    updateInputsForm(this.inputForms(dataPanel, [], "editPlan"));
                  },
                },
                {
                  icon: <PencilSimpleLine weight="duotone" />,
                  text: "Edit Company",
                  action: () => {
                    setIsPanelOpen(true);
                    updateTitlePanel(dataPanel?.insurance_name ?? "Edit insurance company");
                    setEditMode(true);
                    setSaveButtonTitle("Save Changes");
                    updateActivePanel("editCompany");
                    const companyData = {
                      name: dataPanel.insurance_name,
                      id: dataPanel.insurance_id,
                      contract_type: dataPanel.contract_type,
                    };
                    copyToDataPanel(companyData);
                    updateInputsForm(this.inputForms(companyData, [], "editCompany"));
                  },
                },
              ]}
            />
          </Stack>
        </Stack>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InsuranceUtility));
