import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "../../styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Alert, Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import AddIcon from "@mui/icons-material/Add";
import NewAuthorization from "./NewAuthorization";
import UpdateAuthorization from "./UpdateAuthorization";
import AuthorizationList from "./AuthorizationList";
import ViewAuthorization from "./ViewAuthorization";
import SectionBox from "elements/SectionBox";

class InsuranceAuthorizationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveButtonDisabled: true,
      updateAuthorizationOpen: false,
      updateAuthorizationId: null,
      updateAuthorizationCode: "",
      updateAuthorizationStartDate: "",
      updateAuthorizationEndDate: "",
      updateApprovedAuthorizationId: null,
      updateAuthorizationType: "",
      updateAuthorizationClinicianId: "",
      updatePendingAuth: false,
      updateApprovedUnits: [
        {
          code: "",
          units: 0,
          hours: 0,
          codeId: 0,
          unitId: 0,
        },
      ],
      updateNewApprovedUnits: [],
      overlapError: false,
      overlappingAuths: [],
      overlapDialogOpen: false,
      viewAuthorizationOpen: false,
      authorizationInViewMode: {},
    };
  }

  componentDidMount() {
    this.props.setCliniciansPerPage(999);
    this.props.setCliniciansSort({
      sort: "first_name",
    });
    this.props.loadCliniciansList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevState.updateAuthorizationOpen &&
      !prevProps.newAuthorizationOpen &&
      (this.state.updateAuthorizationOpen || this.props.newAuthorizationOpen) &&
      (!this.props.clinicianList || this.props.clinicianList?.length === 0)
    ) {
      this.props.setCliniciansPerPage(999);
      this.props.setCliniciansSort({
        sort: "first_name",
      });
      this.props.loadCliniciansList();
    }
    if (
      prevProps.newAuthorizationStartDate !== this.props.newAuthorizationStartDate ||
      (prevProps.newAuthorizationType !== this.props.newAuthorizationType &&
        this.props.newAuthorizationStartDate)
    ) {
      this.checkForOverlappingAuths(true);
    }
    if (
      prevState.updateAuthorizationType !== this.state.updateAuthorizationType &&
      this.state.updateAuthorizationStartDate
    ) {
      this.checkForOverlappingAuths(false);
    }
  }

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;
    this.setState({ [name]: val, saveButtonDisabled: false });
  };

  onChangeDate = (name, date) => {
    this.setState({ [name]: date, saveButtonDisabled: false }, () => {
      if (name == "updateAuthorizationStartDate") {
        this.checkForOverlappingAuths(false);
      }
    });
  };

  checkForOverlappingAuths = (isNewAuth) => {
    const date = isNewAuth
      ? this.props.newAuthorizationStartDate
      : this.state.updateAuthorizationStartDate;
    const authType = isNewAuth
      ? this.props.newAuthorizationType
      : this.state.updateAuthorizationType;

    let overlappingAuths = [];
    if (isNewAuth) {
      if (authType === "secondary_auth") {
        overlappingAuths = this.props.authorizationCodes.filter(
          (code) =>
            new Date(code.end_date) > new Date(date) && code.authorization_type === "secondary_auth"
        );
      } else if (authType === "re_auth") {
        overlappingAuths = this.props.authorizationCodes.filter(
          (code) =>
            new Date(code.end_date) > new Date(date) && code.authorization_type === "re_auth"
        );
      } else {
        overlappingAuths = this.props.authorizationCodes.filter(
          (code) =>
            new Date(code.end_date) > new Date(date) &&
            code.authorization_type !== "secondary_auth" &&
            code.authorization_type !== "re_auth"
        );
      }
    } else {
      let currentAuth = this.props.authorizationCodes
        ?.filter((code) => code.authorization_type !== "secondary_auth")
        .sort((a, b) => {
          return new Date(b.end_date) - new Date(a.end_date);
        })[0];

      let currentSecondaryAuth = this.props.authorizationCodes
        ?.filter((code) => code.authorization_type === "secondary_auth")
        .sort((a, b) => {
          return new Date(b.end_date) - new Date(a.end_date);
        })[0];
      let currentReAuth = this.props.authorizationCodes
        ?.filter((code) => code.authorization_type === "re_auth")
        .sort((a, b) => {
          return new Date(b.end_date) - new Date(a.end_date);
        })[0];
      if (
        authType === "secondary_auth" &&
        this.state.updateAuthorizationId === currentSecondaryAuth.id
      ) {
        overlappingAuths = this.props.authorizationCodes.filter(
          (code) =>
            new Date(code.end_date) >= new Date(date) &&
            code.id !== this.state.updateAuthorizationId &&
            code.authorization_type === "secondary_auth"
        );
      } else if (authType === "re_auth" && this.state.updateAuthorizationId === currentReAuth.id) {
        overlappingAuths = this.props.authorizationCodes.filter(
          (code) =>
            new Date(code.end_date) >= new Date(date) &&
            code.id !== this.state.updateAuthorizationId &&
            code.authorization_type !== "re_auth"
        );
      } else if (
        authType !== "secondary_auth" &&
        authType !== "re_auth" &&
        this.state.updateAuthorizationId === currentAuth.id
      ) {
        overlappingAuths = this.props.authorizationCodes.filter(
          (code) =>
            new Date(code.end_date) >= new Date(date) &&
            code.id !== this.state.updateAuthorizationId &&
            code.authorization_type !== "secondary_auth"
        );
      }
    }

    let overlapError = overlappingAuths.length > 0;
    this.setState({ overlapError, overlappingAuths });
  };

  onChangeNewAuthDate = (name, date) => {
    this.props.onChangeDate(name, date);
  };

  onToggleOverlapDialog = () => {
    this.setState({ overlapDialogOpen: !this.state.overlapDialogOpen });
  };

  onOpenViewAuthorization = (index) => {
    this.setState({ viewAuthorizationOpen: true });
    const selectedAuthorization = this.props.authorizationCodes[index];
    this.setState({ authorizationInViewMode: selectedAuthorization });
  };

  onCloseViewAuthorization = () => {
    this.setState({ viewAuthorizationOpen: false });
    this.setState({ authorizationInViewMode: {} });
  };

  onOpenUpdateAuthorization = (index) => {
    this.setState({ updateAuthorizationOpen: true });
    let selectedAuthorization = this.props.authorizationCodes[index];
    let updateAuthorizationId = selectedAuthorization.id;
    let updateAuthorizationCode = selectedAuthorization.authorization_code;
    let updateAuthorizationStartDate = selectedAuthorization.start_date;
    let updateAuthorizationEndDate = selectedAuthorization.end_date;
    let updateApprovedAuthorizationId = selectedAuthorization.id;
    let updateAuthorizationType = selectedAuthorization.authorization_type || "";
    let updateAuthorizationClinicianId = selectedAuthorization.clinician_id || "";
    let updatePendingAuth = selectedAuthorization.pending_auth;
    let updateApprovedUnits = selectedAuthorization.approvedUnits.map(
      ({
        approved_time: hours,
        approved_units: units,
        code,
        id: unitId,
        insurance_code_id: codeId,
        client_id: clientId,
      }) => ({
        hours,
        units,
        code,
        unitId,
        codeId: parseInt(codeId),
        clientId,
      })
    );
    this.setState({
      updateAuthorizationId,
      updateAuthorizationCode,
      updateAuthorizationStartDate,
      updateAuthorizationEndDate,
      updateApprovedUnits,
      updateAuthorizationType,
      updateApprovedAuthorizationId,
      updateAuthorizationClinicianId,
      updatePendingAuth,
    });
  };

  onCloseUpdateAuthorization = () => {
    this.setState({
      updateAuthorizationOpen: false,
      updateAuthorizationCode: "",
      updateAuthorizationStartDate: "",
      updateAuthorizationEndDate: "",
      updateAuthorizationType: "",
      updateApprovedAuthorizationId: null,
      updateAuthorizationClinicianId: "",
      updatePendingAuth: false,
      updateApprovedUnits: [
        {
          code: "",
          units: 0,
          hours: 0,
          codeId: "",
          unitId: 0,
        },
      ],
      saveButtonDisabled: true,
      updateNewApprovedUnits: [],
    });
  };

  onChangeUpdateApprovedUnitAmount = (index, event) => {
    let approvedUnits = [...this.state.updateApprovedUnits];
    approvedUnits[index].units = event.target.value;
    approvedUnits[index].hours = event.target.value / 4;
    this.setState({
      updateApprovedUnits: approvedUnits,
      saveButtonDisabled: false,
    });
  };

  onSubmitUpdateAuthorization = async (isSecondaryAuth) => {
    const { updateNewApprovedUnits } = this.state;
    const authObj = {
      isSecondaryAuth,
      authId: this.state.updateAuthorizationId,
      authorizationCode: this.state.updateAuthorizationCode,
      startDate: this.state.updateAuthorizationStartDate,
      endDate: this.state.updateAuthorizationEndDate,
      clientId: this.props.clientId,
      approvedUnits: isSecondaryAuth ? null : this.state.updateApprovedUnits,
      authorizationType: this.state.updateAuthorizationType,
      clinicianId: this.state.updateAuthorizationClinicianId,
      pendingAuth: this.state.updatePendingAuth,
    };
    await this.props.updateAuthorizationCode(authObj);
    if (updateNewApprovedUnits.length > 0) {
      for (let i = 0; i < updateNewApprovedUnits.length; i++) {
        let unit = updateNewApprovedUnits[i];
        unit.insuranceAuthorizationsId = this.state.updateAuthorizationId;
        unit.clientId = this.props.clientId;
        await this.props.createApprovedUnits(unit);
      }
    }
    this.setState({
      updateAuthorizationId: null,
      updateAuthorizationCode: "",
      updateAuthorizationEndDate: "",
      updateAuthorizationStartDate: "",
      updateAuthorizationOpen: false,
      updateAuthorizationType: "",
      updateAuthorizationClinicianId: "",
      updatePendingAuth: false,
      updateApprovedUnits: [
        {
          code: "",
          units: 0,
          hours: 0,
          codeId: "",
          unitId: 0,
        },
      ],
      updateNewApprovedUnits: [],
    });
  };

  onAddUpdateNewApprovedUnit = () => {
    let newApprovedUnit = {
      code: "",
      units: 0,
      hours: 0,
      codeId: 0,
    };
    let updateNewApprovedUnits = [...this.state.updateNewApprovedUnits, newApprovedUnit];
    this.setState({ updateNewApprovedUnits });
  };

  onRemoveUpdateNewApprovedUnit = (index) => {
    let updateNewApprovedUnits = this.state.updateNewApprovedUnits;
    updateNewApprovedUnits.splice(index, 1);
    this.setState({ updateNewApprovedUnits });
  };

  onChangeUpdateNewApprovedUnitCode = (index, event) => {
    let approvedUnits = [...this.state.updateNewApprovedUnits];
    approvedUnits[index].code = event.target.value;
    const codeId = this.props.availableInsuranceCodes.find(
      (code) => code.insurance_code == event.target.value
    ).id;
    approvedUnits[index].codeId = codeId;
    this.setState({
      updateNewApprovedUnits: approvedUnits,
      saveButtonDisabled: false,
    });
  };

  onChangeUpdateNewApprovedUnitAmount = (index, event) => {
    let approvedUnits = [...this.state.updateNewApprovedUnits];
    approvedUnits[index].units = event.target.value;
    approvedUnits[index].hours = event.target.value / 4;
    this.setState({
      updateNewApprovedUnits: approvedUnits,
      saveButtonDisabled: false,
    });
  };

  mapAuthTypeToDisplay = (authType) => {
    if (!authType) {
      return "----";
    } else if (authType === "pre_auth") {
      return "Pre-Authorization";
    } else if (authType === "auth") {
      return "Ongoing Authorization";
    } else if (authType === "re_auth") {
      return "Re-Authorization";
    } else {
      return "Secondary Authorization";
    }
  };

  toggleNewAuthorization = () => {
    this.setState({ overlapError: false, overlappingAuths: [] });
    this.props.onToggleNewAuthorization();
  };

  saveNewAuthorization = () => {
    const { newAuthorizationType } = this.props;
    if (this.state.overlapError) {
      this.onToggleOverlapDialog();
    } else {
      const isSecondaryAuth = newAuthorizationType === "secondary_auth";
      this.props.onSubmitNewAuthorization(isSecondaryAuth);
    }
  };

  saveUpdateAuthorization = () => {
    const { updateAuthorizationType } = this.state;
    if (this.state.overlapError) {
      this.onToggleOverlapDialog();
    } else {
      const isSecondaryAuth = updateAuthorizationType === "secondary_auth";
      this.onSubmitUpdateAuthorization(isSecondaryAuth);
    }
  };

  submitAuthWithOverlap = async () => {
    const { newAuthorizationStartDate, newAuthorizationOpen, newAuthorizationType } = this.props;
    const { overlappingAuths, updateAuthorizationStartDate, updateAuthorizationType } = this.state;
    this.setState({
      overlapDialogOpen: false,
    });
    const authType = newAuthorizationOpen ? newAuthorizationType : updateAuthorizationType;
    const isSecondaryAuth = authType === "secondary_auth";
    for (let i = 0; i < overlappingAuths.length; i++) {
      let auth = overlappingAuths[i];
      const authObj = {
        isSecondaryAuth,
        authId: auth.id,
        authorizationCode: auth.authorization_code,
        startDate: auth.start_date,
        endDate: subDays(
          new Date(newAuthorizationOpen ? newAuthorizationStartDate : updateAuthorizationStartDate),
          1
        ),
        clientId: auth.client_id,
        authorizationType: auth.authorization_type || "",
        clinicianId: auth.clinician_id,
        approvedUnits: isSecondaryAuth
          ? null
          : auth.approvedUnits?.map(
              ({
                approved_time: hours,
                approved_units: units,
                code,
                id: unitId,
                insurance_code_id: codeId,
                client_id: clientId,
              }) => ({
                hours,
                units,
                code,
                unitId,
                codeId,
                clientId,
              })
            ),
      };
      await this.props.updateAuthorizationCode(authObj);
    }

    if (newAuthorizationOpen) {
      this.props.onSubmitNewAuthorization(isSecondaryAuth);
    } else {
      this.onSubmitUpdateAuthorization(isSecondaryAuth);
    }
    this.setState({
      overlapError: false,
      overlappingAuths: [],
    });
  };

  render() {
    const {
      classes,
      onToggleNewAuthorization,
      authorizationCodesLoading,
      newAuthorizationOpen,
      authorizationCodes,
      availableInsuranceCodes,
      userPermissions,
      insuranceAuthorizationRef,
      editMode,
    } = this.props;
    const {
      saveButtonDisabled,
      updateAuthorizationOpen,
      authorizationInViewMode,
      viewAuthorizationOpen,
    } = this.state;
    return (
      <SectionBox
        columnDirection
        title={"Authorizations"}
        description={"List of approved ongoing and secondary insurance authorizations."}
      >
        <Box marginBottom={"48px"} width={"100%"}>
          <>
            {newAuthorizationOpen && (
              <NewAuthorization
                {...this.props}
                open={newAuthorizationOpen}
                onChangeDate={this.onChangeNewAuthDate}
                saveNewAuthorization={this.saveNewAuthorization}
              />
            )}
            {updateAuthorizationOpen && (
              <UpdateAuthorization
                {...this.state}
                open={updateAuthorizationOpen}
                classes={this.props.classes}
                onOpenUpdateAuthorization={this.onOpenUpdateAuthorization}
                onCloseUpdateAuthorization={this.onCloseUpdateAuthorization}
                onChangeUpdateApprovedUnitAmount={this.onChangeUpdateApprovedUnitAmount}
                onSubmitUpdateAuthorization={this.saveUpdateAuthorization}
                onChange={this.onChange}
                onChangeDate={this.onChangeDate}
                availableInsuranceCodes={this.props.availableInsuranceCodes}
                saveButtonDisabled={this.state.saveButtonDisabled}
                onAddUpdateNewApprovedUnit={this.onAddUpdateNewApprovedUnit}
                onRemoveUpdateNewApprovedUnit={this.onRemoveUpdateNewApprovedUnit}
                onChangeUpdateNewApprovedUnitCode={this.onChangeUpdateNewApprovedUnitCode}
                onChangeUpdateNewApprovedUnitAmount={this.onChangeUpdateNewApprovedUnitAmount}
                clinicianList={this.props.clinicianList}
              />
            )}
            {viewAuthorizationOpen && (
              <ViewAuthorization
                open={viewAuthorizationOpen}
                onClose={this.onCloseViewAuthorization}
                data={authorizationInViewMode}
              />
            )}
            {authorizationCodes.length > 0 ? (
              <AuthorizationList
                authorizationCodes={authorizationCodes}
                onOpenUpdateAuthorization={this.onOpenUpdateAuthorization}
                onOpenViewAuthorization={this.onOpenViewAuthorization}
                mapAuthTypeToDisplay={this.mapAuthTypeToDisplay}
                classes={classes}
                userPermissions={userPermissions}
                editMode={editMode}
              />
            ) : (
              <Typography
                component="p"
                variant="body2"
                color={"textSecondary"}
                align="center"
                sx={{ width: "100%", lineHeight: 6 }}
              >
                There are no authorization codes
              </Typography>
            )}
            {userPermissions?.add_client_insurance_authorization && editMode && (
              <Box marginTop="24px">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.toggleNewAuthorization}
                  startIcon={<AddIcon />}
                >
                  Add Authorization Period
                </Button>
              </Box>
            )}
          </>
          <Dialog open={this.state.overlapDialogOpen} secondaryActionText="Cancel" maxWidth="sm">
            <DialogTitle id="overlap-auth-warning">Overlapping Authorization Periods</DialogTitle>
            <DialogContent>
              <Alert severity="warning" style={{ margin: "24px 0" }}>
                {`The dates of this authorization overlap an existing
                  authorization period. Proceeding will update the current authorization
                  to end one day prior to the start of this authorization period.`}
              </Alert>
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                paddingTop="16px"
              >
                <Button
                  onClick={this.onToggleOverlapDialog}
                  style={{ marginRight: "8px" }}
                  variant="outlined"
                  color="default"
                >
                  Cancel
                </Button>
                <Button onClick={this.submitAuthWithOverlap}>
                  Confirm And Add Authorization Period
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </SectionBox>
    );
  }
}
export default withStyles(styles)(InsuranceAuthorizationContainer);
