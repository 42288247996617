import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { COUNTRIES, STATES, RELATIONSHIPS } from "constants";
import { formatToPhoneNumber } from "utils/formatFormFields";
import { formatJsonDate } from "utils/dateFromJSONDate";
import ViewRowCell from "./ViewRowCell";
import { Typography } from "@mui/material";
/** @typedef {import("api/typesDef").Member} Member */

/**
 * The FinancialGuarantorView component displays information about a Financial Guarantor in a structured grid
 * layout.
 * @param {Object} props - The component props.
 * @param {Member} props.guarantor - The Financial Guarantor data.
 * @returns {JSX.Element} The `FinancialGuarantorView` component is being returned. It displays information about a
 * Financial Guarantor including their first name, last name, date of birth, gender, email, phone number,
 * relationship to client, address details (country, state, city, zip code, address1, address2).
 */
const FinancialGuarantorView = ({ guarantor }) => {
  if (!guarantor) {
    return (
      <Typography
        component="p"
        variant="body2"
        color={"textSecondary"}
        sx={{ width: "100%", lineHeight: 6 }}
      >
        No Financial Guarantor on the account
      </Typography>
    );
  }

  let {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    email,
    phone,
    relationshipToClient,
    country,
    state,
    city,
    zipCode,
    address1,
    address2,
  } = guarantor;

  const formattedDate = formatJsonDate(dateOfBirth);
  const formattedPhone = phone ? formatToPhoneNumber(phone) : null;

  return (
    <Grid container columnSpacing={5} rowSpacing={7} width="100%">
      <ViewRowCell sm={6} title="Guarantor’s First Name" value={firstName} />
      <ViewRowCell sm={6} title="Guarantor’s Last Name" value={lastName} />
      <ViewRowCell sm={6} title="Guarantor’s Date of Birth" value={formattedDate} />
      <ViewRowCell sm={6} title="Guarantor’s Gender" value={gender} />
      <ViewRowCell sm={6} title="Guarantor’s Email Address" value={email} />
      <ViewRowCell sm={6} title="Guarantor’s Phone Number" value={formattedPhone} />
      <ViewRowCell
        sm={6}
        title="Relationship to Client"
        value={RELATIONSHIPS[relationshipToClient]}
      />
      <ViewRowCell sm={6} title="Country" value={COUNTRIES[country]} />
      <ViewRowCell sm={4} title="State" value={STATES[state]} />
      <ViewRowCell sm={4} title="City" value={city} />
      <ViewRowCell sm={4} title="ZIP code" value={zipCode} />
      <ViewRowCell sm={12} title="Guarantor’s Address" value={address1} />
      <ViewRowCell sm={12} title="Guarantor’s Address 2" value={address2} />
    </Grid>
  );
};

export default FinancialGuarantorView;
