import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { X } from "@phosphor-icons/react";

const ModalV2 = ({ open, onCancelDialog, onCloseDialog, titleDialog, children, actions, maxWidth = "xs", actionText, actionOnClick, cancelActionText, cancelOnClick, isSubmittingDialog}) => {
  return (
    <Dialog open={open} onClose={onCancelDialog} maxWidth={maxWidth}>
      <DialogTitle
        style={{
          borderBottom: "1pt solid #CDD3DA",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {titleDialog}
        {onCancelDialog && (
          <IconButton aria-label="close" onClick={onCloseDialog}>
            <X />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent style={{maxHeight: "430px", height: "430px"}} >{children}</DialogContent>
      <DialogActions style={{ borderTop: "1pt solid #CDD3DA" }}>
        {cancelActionText && cancelOnClick && (
          <Button
            onClick={cancelOnClick}
            color="secondary"
          >
            {cancelActionText}
          </Button>
        )}
        {actionText && actionOnClick && (
          <LoadingButton
            loading={isSubmittingDialog}
            onClick={actionOnClick}
          >
            {actionText}
          </LoadingButton>
        )}
        {actions}
      </DialogActions>
    </Dialog>
  );
};

ModalV2.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancelDialog: PropTypes.func.isRequired,
  titleDialog: PropTypes.string.isRequired,
  children: PropTypes.node,
  actions: PropTypes.node,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export default ModalV2;