import { useMutation } from "react-query";

import { completeIntakeFlow } from "api/customers";
import { useGlobalToast } from "components/GlobalToastProvider";

/**
 * The `useCompleteIntake` is a custom hook that handles a mutation for completing an intake flow
 * with error handling and toast notifications.
 * @param {Object} [options] - It accepts an `options` object as a parameter, which can contain custom
 * configurations for the mutation. The `onError` function is a callback that can be provided in the
 * `options`
 */
const useCompleteIntake = (options = {}) => {
  const { onError = () => {}, ...otherOptions } = options;
  const { showToast } = useGlobalToast();

  const { mutate, isIdle, isLoading, ...rest } = useMutation(
    (clientId) => completeIntakeFlow(clientId),
    {
      onError: (error) => {
        console.error("Error completing intake flow:", error);
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
        });
        onError();
      },
      ...otherOptions,
    }
  );

  return {
    completeIntakeMutation: mutate,
    isCompleteIntakeIdle: isIdle,
    isIntakeMutationLoading: isLoading,
    ...rest,
  };
};

export default useCompleteIntake;
